import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, Table, message, Steps } from 'antd';
import axios from 'axios';
import { renderField } from './FieldRender/_RenderField';
import dayjs from 'dayjs'; // Ensure dayjs is properly imported
import { DeleteOutlined } from '@ant-design/icons';

interface TransactionPageRenderProps {
  moduleData: any;
  existingRecord?: any;
  records: any[]; // Add this line
  setRecords: Function;
  closeModal: () => void;
}

// Define the Stage interface
interface Stage {
  id: string;
  name: string;
  required: boolean;
  allowPartial: boolean;
  fields: { fieldId: string; isCarryover: boolean }[];
}

const TransactionPageRender: React.FC<TransactionPageRenderProps> = ({
  moduleData,
  existingRecord,
  records,
  setRecords,
  closeModal,
}) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<any>({});
  const [referenceData, setReferenceData] = useState<any>({});
  const isEditing = !!existingRecord;
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const { Step } = Steps;
  const API_URL = process.env.REACT_APP_API_URL;

  // Initialize row counters per section
  const rowIdCounters = useRef<{ [sectionId: string]: number }>({});

  useEffect(() => {
    if (moduleData && !isInitialized) {
      // console.log('module: ', moduleData)
      if (existingRecord) {
        // **Editing an Existing Record**
        const stage = moduleData.stages.find((s: Stage) => s.name === existingRecord.stageId);

        setCurrentStage(stage || moduleData.stages[0]);
        // console.log('current stage: ', currentStage)
  
        const data = existingRecord.data || {};
  
        // Process main form fields
        const processedRecord = Object.keys(data).reduce((acc, key) => {
          const fieldValue = data[key];
          const fieldDefinition = moduleData.sections
            .flatMap((section: any) => section.fields)
            .find((field: any) => field.name === key);
  
          if (fieldDefinition?.type === 'DateField') {
            // Parse ISO string to dayjs object
            const dateValue = dayjs(fieldValue.value).isValid()
              ? dayjs(fieldValue.value)
              : null;
            acc[key] = dateValue;
          } else {
            acc[key] = fieldValue?.value ?? fieldValue;
          }
          return acc;
        }, {});
  
        form.setFieldsValue(processedRecord);
  
        // Process table data
        const tableDataFromRecord = data.tableData || {};
        const flattenedTableData: any = {};
  
        Object.keys(tableDataFromRecord).forEach((sectionId) => {
          const tableRows = tableDataFromRecord[sectionId] || [];
          flattenedTableData[sectionId] = tableRows.map((row: any, index: number) => {
            const flattenedRow: any = { id: `row_${sectionId}_${index}` }; // Unique ID per section and index
  
            Object.keys(row).forEach((fieldKey) => {
              const columnDefinition = moduleData.tableData[sectionId].columns.find(
                (col: any) => col.dataIndex === fieldKey
              );
 
              
              let fieldValue = row[fieldKey]?.value ?? row[fieldKey];

              // const fieldValue = row[fieldKey]?.value ?? row[fieldKey];
  
              if (columnDefinition?.type === 'DateField') {
                const dateValue = dayjs(fieldValue).isValid()
                  ? dayjs(fieldValue)
                  : null;
                flattenedRow[fieldKey] = dateValue;
              } else if (columnDefinition?.type === 'Dropdown') {
                // Ensure fieldValue is a string label
                if (typeof fieldValue === 'string') {
                  flattenedRow[fieldKey] = fieldValue;
                } else {
                  console.warn(`Expected string for Dropdown field ${fieldKey}, got: ${fieldValue}`);
                  flattenedRow[fieldKey] = ''; // Fallback to empty string
                }
              }
              else {
                flattenedRow[fieldKey] = fieldValue;
              }
            });
  
            return flattenedRow;
          });
  
          // Initialize row counter for this section
          rowIdCounters.current[sectionId] = tableRows.length;
        });
  
        setTableData(flattenedTableData);
      } else {
        // console.log('new record')
        // Set the first stage as the current stage
        const firstStage = moduleData.stages[0];
        setCurrentStage(firstStage);
  
        // Reset the form fields
        form.resetFields();

        console.log('form')

              // Initialize form fields with default values
        const initialFormValues = {};
        moduleData.sections.forEach((section: any) => {
          section.fields.forEach((field: any) => {
            if (field.type === 'DateField') {
              initialFormValues[field.name] = null; // Default date value
            } else {
              initialFormValues[field.name] = ''; // Default text value
            }
          });
        });
        form.setFieldsValue(initialFormValues);
  
        // Initialize table data as empty
        const emptyTableData: any = {};
        if (moduleData.tableData) {
          Object.keys(moduleData.tableData).forEach((sectionId) => {
            emptyTableData[sectionId] = [];
          });
        }
        setTableData(emptyTableData);
      }
  
      setIsInitialized(true);
    }
  }, [existingRecord, form, isInitialized, moduleData]);

  
  const processSectionData = (values: any) => {
    return Object.keys(values).reduce((acc, key) => {
      const field = moduleData.sections
        .filter((section: any) => section.type === 'section')
        .flatMap((section: any) => section.fields)
        .find((f: any) => f.name === key);

      const fieldValue = values[key];

      if (field) {
        acc[key] = {
          label: field?.label || key,
          value: field.type === 'DateField' && dayjs.isDayjs(fieldValue)
            ? fieldValue.toISOString() // Convert dayjs to ISO string
            : fieldValue,
        };
      }

      return acc;
    }, {});
  };

  const processTableData = () => {
    const processedTableData: any = {};
    Object.keys(tableData).forEach((sectionId) => {
      const tableSchema = moduleData.tableData?.[sectionId];

      if (tableSchema) {
        processedTableData[sectionId] = tableData[sectionId]?.map((row: any) => {
          const processedRow: any = {};
          tableSchema.columns.forEach((col: any) => {
            let fieldValue = row[col.dataIndex];
            if (col.type === 'DateField') {
              if (dayjs.isDayjs(fieldValue)) {
                fieldValue = fieldValue.toISOString(); // Convert dayjs to ISO string
              } else if (typeof fieldValue === 'string' && dayjs(fieldValue).isValid()) {
                fieldValue = dayjs(fieldValue).toISOString(); // Ensure valid date string is converted
              }
            }
            processedRow[col.dataIndex] = {
              label: col.name || col.type,
              value: fieldValue,
            };
          });
          return processedRow;
        });
      }
    });

    return processedTableData;
  };

  const handleAutoIncrementField = async (processedData: any) => {
    if (!existingRecord) {
      const autoIncrementField = moduleData.sections
        .flatMap((section: any) => section.fields)
        .find((f: any) => f.type === 'AutoIncrementId');

      if (autoIncrementField) {
        try {
          const response = await axios.get(`${API_URL}/auto-increment/next-auto-id`, {
            params: {
              tenantID: moduleData.tenantId,
              companyID: moduleData.companyId,
              moduleID: moduleData._id,
              fieldId: autoIncrementField.id,
            },
          });

          const nextValue = response.data.nextValue;
          if (nextValue) {
            processedData[autoIncrementField.name] = {
              label: autoIncrementField.label || autoIncrementField.name,
              value: nextValue,
            };
          }
        } catch (error) {
          console.error('Error fetching auto-increment value:', error);
        }
      }
    }
  };

  const submitRecord = async (record: any) => {
    const { tenantId, companyId } = moduleData;

    const payload = {
      data: record,
      moduleId: moduleData._id,
      tenantId,
      companyId,
      stageId: currentStage?.name
    };

    // console.log('payl: ', payload)

    try {
      if (existingRecord) {
        // console.log('existing record saving')
        await axios.put(`${API_URL}/transaction-data/${existingRecord._id}`, payload);
      } else {
        // console.log('new record saving')
        await axios.post(`${API_URL}/transaction-data`, payload);
      }

      const response = await axios.get(
        `${API_URL}/transaction-data?moduleId=${moduleData._id}&tenantId=${tenantId}&companyId=${companyId}`
      );

      setRecords(response.data);
    } catch (error) {
      console.error('Error submitting record:', error);
      message.error('Failed to submit the record.');  //11/4 this
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const processedData = processSectionData(values);
      const processedTableData = processTableData();

      if (!existingRecord) {
        await handleAutoIncrementField(processedData);
      }

      const record = {
        ...processedData,
        tableData: processedTableData,
      };

      await submitRecord(record);

      message.success('Record saved successfully'); //11/4 this
      closeModal();
    } catch (error) {
      console.error('Error during form submission:', error);
      message.error('Failed to save the record');
    }
  };

  const addTableRow = (sectionId: string) => {
    const tableSchema = moduleData.tableData?.[sectionId];
    if (!tableSchema || !tableSchema.columns) {
      console.error(`No columns found for table section with id ${sectionId}`);
      return;
    }

    // Initialize counter for this section if not already
    if (rowIdCounters.current[sectionId] === undefined) {
      rowIdCounters.current[sectionId] = 0;
    }

    const newRowId = `row_${sectionId}_${rowIdCounters.current[sectionId]++}`;
    const newRow: any = { id: newRowId };

    tableSchema.columns.forEach((col: any) => {
      newRow[col.dataIndex] = null;
    });

    setTableData((prevTableData: any) => ({
      ...prevTableData,
      [sectionId]: [...(prevTableData[sectionId] || []), newRow],
    }));
  };

  const removeTableRow = (sectionId: string, rowId: string) => {
    setTableData((prevTableData: any) => ({
      ...prevTableData,
      [sectionId]: prevTableData[sectionId].filter((row: any) => row.id !== rowId),
    }));
  };

  const handleFormFieldChange = (fieldName: string, value: any) => {
    form.setFieldsValue({ [fieldName]: value });
  };

  const handleTableCellChange = (
    sectionId: string,
    rowId: string,
    dataIndex: string,
    value: any
  ) => {
    const tableSection = moduleData.tableData?.[sectionId];
    if (!tableSection) {
      console.error(`No table schema found for sectionId: ${sectionId}`);
      return;
    }
  
    // Find the field definition within the table's columns
    const field = tableSection.columns.find((col: any) => col.dataIndex === dataIndex);
    const fieldType = field?.type;

    console.log('field: ', field)
    console.log('type: ', fieldType)

    // console.log(`Updating rowData for sectionId: ${sectionId}, rowId: ${rowId}, dataIndex: ${dataIndex}, value: ${value}`);
    setTableData((prevTableData: any) => {
    const updatedRows = prevTableData[sectionId]?.map((row: any) => {
      if (row.id === rowId) {
        // console.log('Row before update:', row);

        let updatedValue = value;

        if (fieldType === 'Dropdown') {
          if (typeof value === 'string') {
            updatedValue = value;
          } else {
            console.warn(`Expected string for Dropdown field "${dataIndex}", but received:`, value);
            updatedValue = ''; // Fallback to empty string or handle as needed
          }
        }

        const updatedRow = { ...row, [dataIndex]: updatedValue };
        // console.log('Row after update:', updatedRow);
        return updatedRow;
      }
      // console.log('row: ', row)
      return row;
    }) || [];

    return {
      ...prevTableData,
      [sectionId]: updatedRows,
    };
  });
};

  const moveToNextStage = async () => {
    if (!currentStage) return;
  
    const currentStageIndex = moduleData.stages.findIndex((s: Stage) => s.id === currentStage.id);
    const nextStage = moduleData.stages[currentStageIndex + 1];
  
    if (!nextStage) {
      message.info('You are already at the final stage.');
      return;
    }
  
    // Validate required fields in current stage
    const requiredFields = currentStage.fields
      // .filter((f) => f.required)
      .map((f) => {
        const field = moduleData.sections.flatMap((section) => section.fields).find((fld) => fld.id === f.fieldId);
        return field?.name;
      })
      .filter((name) => name);
  
    try {
      await form.validateFields(requiredFields);
    } catch {
      message.error('Please fill all required fields before moving to the next stage.');
      return;
    }
  
    // Process the form and table data for carryover fields
    const values = await form.validateFields();
    const processedData = processSectionData(values);
    const processedTableData = processTableData();
  
    // Gather carryover data for new record
    const carryoverData: any = {};
    currentStage.fields.forEach((f) => {
      if (f.isCarryover) {
        const field = moduleData.sections.flatMap((section) => section.fields).find((fld) => fld.id === f.fieldId);
        if (field && processedData[field.name]) {
          carryoverData[field.name] = processedData[field.name];
        }
      }
    });
  
    // Mark the existing record as moved
    if (existingRecord) {
      try {
        await axios.put(`${API_URL}/transaction-data/${existingRecord._id}`, {
          movedToNextStage: true,
        });
      } catch (error) {
        console.error('Error marking record as moved:', error);
        message.error('Failed to update the previous stage record.');
        return;
      }
    }
  
    // Create new record for the next stage
    const newRecord = {
      ...carryoverData,
      tableData: processedTableData,
    };
  
    const payload = {
      data: newRecord,
      moduleId: moduleData._id,
      tenantId: moduleData.tenantId,
      companyId: moduleData.companyId,
      stageId: nextStage.name,
    };
  
    try {
      const response = await axios.post(`${API_URL}/transaction-data`, payload);
      setRecords([...records, response.data]);
      setCurrentStage(nextStage);
      message.success('Moved to next stage successfully.');
      closeModal();
    } catch (error) {
      console.error('Error moving to next stage:', error);
      message.error('Failed to move to the next stage.');
    }

    const {tenantId,  companyId} = moduleData;
    const response = await axios.get(
      `${API_URL}/transaction-data?moduleId=${moduleData._id}&tenantId=${tenantId}&companyId=${companyId}`
    );

    setRecords(response.data);
  };


  useEffect(() => {
    const moduleIds: string[] = [];
    moduleData.sections.forEach((section: any) => {
      if (section.type === 'section') {
        section.fields.forEach((field: any) => {
          if (field.type === 'ReferenceLinkField' && field.fieldOptions?.modules) {
            field.fieldOptions.modules.forEach((mod: any) => {
              if (mod.moduleId && !moduleIds.includes(mod.moduleId)) {
                moduleIds.push(mod.moduleId);
              }
            });
          }
        });
      } else if (section.type === 'table') {
        const tableSchema = moduleData.tableData?.[section.id];
        if (tableSchema && tableSchema.columns) {
          tableSchema.columns.forEach((col: any) => {
            if (col.type === 'ReferenceLinkField' && col.fieldOptions?.modules) {
              col.fieldOptions.modules.forEach((mod: any) => {
                if (mod.moduleId && !moduleIds.includes(mod.moduleId)) {
                  moduleIds.push(mod.moduleId);
                }
              });
            }
          });
        }
      }
    });

    moduleIds.forEach((moduleId) => {
      if (!referenceData[moduleId]) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${API_URL}/reference-data?moduleId=${moduleId}`
            );
            const data = response.data.map((item: any) => ({
              ...item.data,
              _id: item._id,
            }));
            setReferenceData((prevData) => ({
              ...prevData,
              [moduleId]: data,
            }));
          } catch (error) {
            console.error('Error fetching reference data:', error);
          }
        };
        fetchData();
      }
    });
  }, [moduleData, referenceData]);

  return (
    <Modal
      title={`${existingRecord ? 'Edit' : 'New'} ${moduleData?.moduleName}`}
      open={true}
      onCancel={closeModal}
      onOk={handleSubmit}
      width={1000}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  <h2>Current Stage: {currentStage?.name}</h2>
      <Button type="primary" onClick={moveToNextStage} disabled={!currentStage}>
        Move to Next Stage
      </Button>
    </div>

    <Steps current={moduleData.stages.findIndex((s: Stage) => s.id === currentStage?.id)}>
      {moduleData.stages.map((stage: Stage) => (
        <Step key={stage.id} title={stage.name} />
      ))}
    </Steps>
      <Form form={form} layout="vertical">
        {moduleData.sections?.map((section: any) => {
          const visibleFields = section.fields.filter((field: any) =>
            currentStage?.fields.some((sField: any) => sField.fieldId === field.id)
          
          );
          // console.log('visible', visibleFields);
          if (section?.type === 'section') {
            return (
              <div key={section.id}>
                <h3>{section.title}</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                  {visibleFields?.map((field: any) => (
                    <React.Fragment key={field.id || field.name}>
                      {renderField(
                        field,
                        section.id,
                        form,
                        referenceData,
                        handleFormFieldChange,
                        moduleData,
                        tableData,
                        isEditing
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          } else if (section?.type === 'table') {
            const tableSchema = moduleData.tableData?.[section.id];
            if (!tableSchema || !tableSchema.columns) return null;

            const stageFields = new Set(currentStage?.fields.map((f: any) => f.fieldId));
            const tableColumns = tableSchema.columns
            .filter((col: any) => stageFields.has(col.dataIndex)) // Filter columns allowed in the current stage
            .map((col: any) => ({
              title: col.name || col.type,
              dataIndex: col.dataIndex,
              key: col.dataIndex,
              width: col.type === 'ReferenceLinkField' ? 150 : col.type === 'DateField' ? 130 : 100,
        
              render: (text: any, record: any, rowIndex: number) =>
                renderField(
                  { ...col, renderType: 'table', rowIndex },
                  section.id,
                  form,
                  referenceData,
                  (value: any) => handleTableCellChange(section.id, record.id, col.dataIndex, value),
                  moduleData,
                  tableData,
                  record,
                  tableSchema.columns,
                  isEditing
                ),
            }));

            return (
              <div key={section.id}>
                <h3>{section.title}</h3>
                <Button
                  type="dashed"
                  onClick={() => addTableRow(section.id)}
                  style={{ marginBottom: 16 }}
                >
                  Add Row
                </Button>
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    columns={[
                      ...tableColumns,
                      {
                        title: 'Actions',
                        key: 'actions',
                        width: 80,
                        render: (_: any, record: any) => (
                          <Button
                            icon={<DeleteOutlined />}
                            // type="link"
                            onClick={() => removeTableRow(section.id, record.id)}
                          >
                          </Button>
                        ),
                      },
                    ]}
                    dataSource={tableData[section.id] || []}
                    pagination={false}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </Form>
    </Modal>
  );
};

export default TransactionPageRender;
