import React from 'react';
import { Select, Form } from 'antd';

const { Option } = Select;

interface DropdownRenderProps {
  fieldId: string;
  name: string; // Adjusted to take `name` instead of `fieldName`
  fieldOptions: { options: string[] }; // Array of options
  value?: string;
  onChange: (value: string) => void; // Add onChange prop
  required: boolean;
}

const DropdownRender: React.FC<DropdownRenderProps> = ({
  fieldId,
  name, 
  fieldOptions,
  value,
  onChange, // Pass the handleFieldChange function here
  required, // Default to false if not provided

}) => {
  return (
    <Form.Item
      name={fieldId} // This is crucial for linking the dropdown to the form
      label={name}
      required={required}
    >
      <Select 
        placeholder={`Select ${name}`} 
        value={value} 
        style={{ width: '100%' }}

        onChange={(selectedValue: string) => {
          // console.log(`Selected value: ${selectedValue}, text: ${selectedValue}`);
          onChange(selectedValue);
        }}
        >
        {fieldOptions?.options?.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DropdownRender;