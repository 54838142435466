import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import { evaluate } from 'mathjs';
// Import AceEditor themes and modes
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';


interface CalculationFieldProps {
  id?: string;
  formula?: string;
  currentFieldNames?: { id: string; name: string }[]; // Accept available fields
  value?: string;
  onFormulaChange?: (formula: string) => void;
}


const CalculationFieldConfig: React.FC<CalculationFieldProps> = ({
  id,
  formula = '',
  currentFieldNames = [],
  value = '',
  onFormulaChange,
}) => {
  // console.log('Available Fields in CalculationFieldConfig:', currentFieldNames); // Debugging log
  const [calculationFormula, setCalculationFormula] = useState(formula || '');

  useEffect(() => {
    setCalculationFormula(formula || '');
  }, [formula]);

  const handleFormulaChange = (newFormula: string) => {
    setCalculationFormula(newFormula);
    if (onFormulaChange) onFormulaChange(newFormula); // Notify parent of formula change
  };

  const handleInsert = (syntax: string) => {
    const newFormula = calculationFormula + syntax;
    handleFormulaChange(newFormula);
  };

  // Predefined functions
  const functions = [
    // { name: 'SUM', syntax: 'SUM([Field1], [Field2])', description: 'Adds two or more fields' },
    { name: 'IF', syntax: 'if([Condition], [ValueIfTrue], [ValueIfFalse])', description: 'Conditional logic' },
    { name: 'CONCAT', syntax: 'concat([Field1], [Field2])', description: 'Concatenates two fields' },
    // { name: 'AVERAGE', syntax: 'AVERAGE([Field1], [Field2])', description: 'Calculates the average of fields' },
    // { name: 'NOW', syntax: 'NOW()', description: 'Returns the current date and time' },
  ];

  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar for Available Fields */}
      <div style={{ width: '250px', marginRight: '10px', borderRight: '1px solid #ddd', padding: '10px' }}>
        <h4>Fields</h4>
        {currentFieldNames.map((field) => (
          <div
            key={field.id}
            style={{ cursor: 'pointer', padding: '5px', background: '#f9f9f9', marginBottom: '5px', borderRadius: '4px' }}
            onClick={() => handleInsert(`[${field.name}]`)} // Insert field syntax on click
          >
            {field.name}
          </div>
        ))}
        <h4>Functions</h4>
        {functions.map((func) => (
          <div
            key={func.name}
            style={{
              cursor: 'pointer',
              padding: '5px',
              background: '#e6f7ff',
              marginBottom: '5px',
              borderRadius: '4px',
            }}
            title={func.description}
            onClick={() => handleInsert(func.syntax)} // Insert function syntax on click
          >
            {func.name} - {func.syntax}
          </div>
        ))}
      </div>

      {/* AceEditor */}
      <div style={{ flex: 1 }}>
        <label htmlFor={`${id}-formula`} style={{ display: 'block', marginBottom: '8px' }}>
          Enter your formula:
        </label>
        <AceEditor
          mode="javascript"
          theme="github"
          value={calculationFormula}
          onChange={handleFormulaChange}
          name="calculation-editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false }}
          placeholder="Enter your formula here (e.g., [Action] === 'Increase' ? [Qty] : [Qty] * -1)"
          style={{
            width: '100%',
            height: '200px',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
          }}
        />
      </div>
    </div>
  );
};

export default CalculationFieldConfig;













// import React, { useState, useEffect } from 'react';
// import { Input } from 'antd';
// import AceEditor from 'react-ace';
// import { evaluate } from 'mathjs';

// interface CalculationFieldProps {
//   id?: string;
//   formula?: string;
//   value?: string;
//   onFormulaChange?: (formula: string) => void;
// }

// // Import AceEditor themes and modes
// import 'ace-builds/src-noconflict/mode-javascript';
// import 'ace-builds/src-noconflict/theme-github';

// const CalculationFieldConfig: React.FC<CalculationFieldProps> = ({
//   id,
//   formula = '',
//   value = '',
//   onFormulaChange,
// }) => {
//   const [calculationFormula, setCalculationFormula] = useState(formula || '');

//   useEffect(() => {
//     setCalculationFormula(formula || '');
//   }, [formula]);

//   const handleFormulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newFormula = e.target.value;
//     setCalculationFormula(newFormula);
//     if (onFormulaChange) onFormulaChange(newFormula); // Notify parent of formula change
//   };

//   return (
//     <div>
//       <Input
//         id={`${id}-formula`}
//         value={calculationFormula}
//         onChange={handleFormulaChange}
//         placeholder="Enter Calculation Formula with field names in brackets (e.g., [Quantity] * [Price])"
//         style={{ marginBottom: '8px' }}
//       />
//     </div>
//   );
// };

// export default CalculationFieldConfig;