import React, { useState } from 'react';
import { Table, Input, Button, DatePicker, InputNumber, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useTenant } from '../../contexts/TenantContext'
import JournalEntryForm from './JournalEntryForm';

const GeneralLedgerPage: React.FC = () => {
  const { tenantId, companyId } = useTenant();
  const [journalEntries, setJournalEntries] = useState([]);
  const [documentNumber, setDocumentNumber] = useState('');
  const [postingDate, setPostingDate] = useState(null);
  const [debitMin, setDebitMin] = useState(null);
  const [debitMax, setDebitMax] = useState(null);
  const [creditMin, setCreditMin] = useState(null);
  const [creditMax, setCreditMax] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // useEffect(() => {
  //   fetchJournalEntries();
  // }, [tenantId, companyId]);

  const API_URL = process.env.REACT_APP_API_URL

  const fetchJournalEntries = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/journal-entries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantId,
          companyId,
          documentNumber,
          postingDate: postingDate ? postingDate.format('YYYY-MM-DD') : null,
          debitMin,
          debitMax,
          creditMin,
          creditMax,
        },
      });
      setJournalEntries(response.data);
    } catch (err) {
      console.error('Error fetching journal entries:', err);
    }
  };

  const columns = [
    { title: 'Account', dataIndex: ['account', 'accountName'], key: 'account' }, // Assumes 'account' has a relation to 'accountName'
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Debit', dataIndex: 'debit', key: 'debit' },
    { title: 'Credit', dataIndex: 'credit', key: 'credit' },
    { title: 'Posting Date', dataIndex: 'postingDate', key: 'postingDate' },
    { title: 'Document Number', dataIndex: 'documentNumber', key: 'documentNumber' },
  ];

  // Toggle modal visibility for JournalEntryForm
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h1>General Ledger</h1>
      <div style={{ marginBottom: '16px' }}>
        <Input
          placeholder="Document Number"
          value={documentNumber}
          onChange={(e) => setDocumentNumber(e.target.value)}
          style={{ width: 200, marginRight: 8 }}
        />
        <DatePicker
          placeholder="Posting Date"
          value={postingDate ? moment(postingDate) : null}
          onChange={(date) => setPostingDate(date)}
        />
        <InputNumber
          placeholder="Debit Min"
          value={debitMin}
          onChange={(value) => setDebitMin(value)}
          style={{ marginLeft: 8 }}
        />
        <InputNumber
          placeholder="Debit Max"
          value={debitMax}
          onChange={(value) => setDebitMax(value)}
          style={{ marginLeft: 8 }}
        />
        <InputNumber
          placeholder="Credit Min"
          value={creditMin}
          onChange={(value) => setCreditMin(value)}
          style={{ marginLeft: 8 }}
        />
        <InputNumber
          placeholder="Credit Max"
          value={creditMax}
          onChange={(value) => setCreditMax(value)}
          style={{ marginLeft: 8 }}
        />
        <Button type="primary" onClick={fetchJournalEntries} style={{ marginLeft: 8 }}>
          Search
        </Button>
        <Button type="primary" onClick={showModal} style={{ marginLeft: 8 }}>
          Add Entries
        </Button>
      </div>
      <Table columns={columns} dataSource={journalEntries} rowKey="id" />

      {/* Modal for Journal Entry Form */}
      <Modal
        title="Journal Entry Form"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}  // No default footer buttons
      >
        <JournalEntryForm />  {/* Render the JournalEntryForm inside the modal */}
      </Modal>
    </div>
  );
};

export default GeneralLedgerPage;