// AccountForm.tsx
import React from 'react';
import { Form, Input, Select, Button } from 'antd';
import { createAccount } from '../../services/accountApiService';

const AccountForm: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await createAccount(values);
    // Handle success (e.g., refresh accounts list)
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="accountNumber" label="Account Number" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="accountName" label="Account Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="accountType" label="Account Type" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="Category">Category</Select.Option>
          <Select.Option value="SubCategory">SubCategory</Select.Option>
          <Select.Option value="Posting">Posting</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="parentAccountId" label="Parent Account">
        <Input />
      </Form.Item>
      <Form.Item name="isPosting" label="Is Posting Account">
        <Select>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create Account
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AccountForm;