// AutoIncrementIDRender.tsx
import React from 'react';
import { Form, Input } from 'antd';

interface AutoIncrementIDRenderProps {
  field: any;
  form: any;
  isEditing: boolean;
}

const AutoIncrementIDRender: React.FC<AutoIncrementIDRenderProps> = ({
  field,
  form,
  isEditing,
}) => {
  return (
    <Form.Item name={field.name} label={field.label || field.name}>
      {isEditing ? (
        <Input disabled />
      ) : (
        <Input disabled placeholder="Will Populate On Save" />
      )}
    </Form.Item>
  );
};

export default AutoIncrementIDRender;