// SubFieldOrganizer.tsx
import React, { useState } from 'react';
import { Button } from 'antd';
import FieldModals from '../Fields/_FieldModals';

interface SubFieldOrganizerProps {
  subFields: any[];
  setSubFields: (fields: any[]) => void;
}

const SubFieldOrganizer: React.FC<SubFieldOrganizerProps> = ({ subFields, setSubFields }) => {
  const [selectedField, setSelectedField] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFieldClick = (field) => {
    setSelectedField(field);
    setIsModalVisible(true);
  };

  const onFieldUpdate = (fieldId, updatedField) => {
    const updatedSubFields = subFields.map((field) =>
      field.id === fieldId ? { ...field, ...updatedField } : field
    );
    setSubFields(updatedSubFields);
  };

  const onFieldDelete = (fieldId) => {
    const updatedSubFields = subFields.filter((field) => field.id !== fieldId);
    setSubFields(updatedSubFields);
  };

  return (
    <div>
      <h4>SubFields</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {subFields.map((field) => (
          <div
            key={field.id}
            style={{
              border: '1px solid gray',
              padding: '10px',
              margin: '5px',
              cursor: 'pointer',
              width: '45%',
            }}
            onClick={() => handleFieldClick(field)}
          >
            <div>{field.name || field.type}</div>
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                onFieldDelete(field.id);
              }}
            >
              Delete
            </Button>
          </div>
        ))}
      </div>

      {/* Field Modal for Editing SubFields */}
      {selectedField && (
        <FieldModals
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            setSelectedField(null);
          }}
          onOk={(updatedField) => {
            setIsModalVisible(false);
            onFieldUpdate(updatedField.id, updatedField);
            setSelectedField(null);
          }}
          currentField={selectedField}
        />
      )}
    </div>
  );
};

export default SubFieldOrganizer;