import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Button, List, Table } from 'antd';  // Ant Design components
import axios from 'axios';
import { renderField } from '../Render/FieldRender/_RenderField';
import { useTenant } from 'contexts/TenantContext';


const { TextArea } = Input;

interface ReferencePageRenderProps {
  moduleData: any;
  setRecords: Function;
  closeModal: () => void;
  existingRecord?: any;  // Accept `existingRecord` as an optional prop
}

const ReferencePageRender: React.FC<ReferencePageRenderProps> = ({ moduleData, setRecords, closeModal, existingRecord }) => {
  const [form] = Form.useForm();  // Form instance for the modal
  const [oneToManyRecords, setOneToManyRecords] = useState<any>({});
  const [tableData, setTableData] = useState<any>({});
  const isEditing = Boolean(existingRecord)
  const [referenceData, setReferenceData] = useState({});
  const [modalKey, setModalKey] = useState(0); // Added modalKey for resetting
  const itemId = existingRecord?.data?.['Item Number']?.value?.uuid || existingRecord?._id;
  const API_URL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    // Set form fields with existingRecord data when the component mounts or existingRecord changes
    if (existingRecord) {
      form.setFieldsValue(existingRecord.data); // Use existingRecord.data
    } else {
      // console.log('new record ref page render');
      form.resetFields(); // Reset form fields
      setOneToManyRecords({}); // Clear any one-to-many records
      setTableData({}); // Clear any table data
      setModalKey((prevKey) => prevKey + 1); // Change modalKey to force re-render
    }
  }, [existingRecord, form]);

  //Extract data for Reference Link Fields: 
  useEffect(() => {
    // Extract moduleIds from moduleData
    const moduleIds = [];
  
    moduleData.sections.forEach((section) => {
      if (section.type === 'section') {
        section.fields.forEach((field) => {
          if (field.type === 'ReferenceLinkField' && field.fieldOptions?.modules) {
            field.fieldOptions.modules.forEach((mod) => {
              if (mod.moduleId && !moduleIds.includes(mod.moduleId)) {
                moduleIds.push(mod.moduleId);
              }
            });
          }
        });
      } else if (section.type === 'table') {
        // Handle table sections if necessary
      }
    });
  
    // Fetch reference data for each moduleId
    const fetchReferenceData = async () => {
      const data = {};
      for (const moduleId of moduleIds) {
        try {
          const response = await axios.get(
            `${API_URL}/reference-data?moduleId=${moduleId}`
          );
          // Map the response data to a format usable by ReferenceLinkFieldRender
          data[moduleId] = response.data.map((record) => ({
            _id: record._id,
            ...record.data,
          }));
        } catch (error) {
          console.error(`Failed to fetch reference data for moduleId: ${moduleId}`, error);
        }
      }
      setReferenceData(data);
    };
  
    fetchReferenceData();
  }, [moduleData]);

  // Handle modal save
  const handleSave = async () => {
    const values = form.getFieldsValue();
    const missingRequiredFields: string[] = [];
  
    // Check required fields
    moduleData.sections.forEach((section: any) => {
      section.fields.forEach((field: any) => {
        if (field.fieldOptions?.required) {
          const value = values[field.name];
          if (!value) {
            missingRequiredFields.push(field.name); // Collect missing field names
          }
        }
      });
    });

    if (missingRequiredFields.length > 0) {
      Modal.error({
        title: 'Missing Required Fields',
        content: `The following fields are required and must be filled: ${missingRequiredFields.join(', ')}`,
      });
      return; // Prevent save if required fields are missing
    }

    // If not editing, fetch the next auto-increment value(s)
    if (!isEditing) {
      // Collect all AutoIncrementId fields
      const autoIncrementFields = [];
      moduleData.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      });
  
      // Fetch and populate next auto-increment values
      for (const field of autoIncrementFields) {
        try {
          const response = await axios.get(`${API_URL}/auto-increment/next-auto-id`, {
            params: {
              tenantID: moduleData.tenantId,
              companyID: moduleData.companyId,
              moduleID: moduleData._id,
              fieldId: field.id,
            },
          });
          const nextValue = response.data.nextValue;
          values[field.name] = nextValue; // Set the value into the form values
        } catch (error) {
          console.error('Error fetching auto-increment value:', error);
          // Handle error (optional): You might want to show an error message or prevent saving
          return;
        }
      }
    }
  
    // Prepare the new record data
    const newRecord = {
      tenantId: moduleData.tenantId,
      companyId: moduleData.companyId,
      moduleId: moduleData._id,
      data: { ...values, ...oneToManyRecords },
    };
  
    try {
      if (existingRecord?._id) {
        // Update existing record
        await axios.put(`${API_URL}/reference-data/${existingRecord._id}`, newRecord);
        setRecords((prevRecords: any[]) =>
          prevRecords.map((record) => (record._id === existingRecord._id ? newRecord : record))
        );
      } else {
        // Create new record
        const response = await axios.post(`${API_URL}/reference-data`, newRecord);
        const savedRecord = response.data;
  
        // Refresh records to include the newly saved record with its auto-incremented ID
        setRecords((prevRecords: any[]) => [
          ...prevRecords,
          { ...newRecord, _id: savedRecord._id, data: { ...savedRecord.data } },
        ]);
      }
  
      closeModal(); // Close modal after save
    } catch (error) {
      console.error('Failed to save record:', error);
      // Handle error (optional): You might want to show an error message
    }
  };

  const addTableRow = (sectionId: string) => {
    const newRow = { id: Date.now().toString() };
    setTableData((prevData: any) => ({
      ...prevData,
      [sectionId]: [...(prevData[sectionId] || []), newRow],
    }));
  };

  const removeTableRow = (sectionId: string, rowId: string) => {
    setTableData((prevData: any) => ({
      ...prevData,
      [sectionId]: prevData[sectionId].filter((row: any) => row.id !== rowId),
    }));
  };

  const renderTableSection = (section: any) => {
    const tableColumns = section.fields.map((col: any) => ({
      title: col.name,
      dataIndex: col.name,
      key: col.name,
      render: (text: any, record: any, rowIndex: number) => (
        renderField(
          { ...col, renderType: 'table', rowIndex },
          section.id,
          form,
          referenceData,
          (value: any) => {
            const newData = [...(tableData[section.id] || [])];
            newData[rowIndex][col.name] = value;
            setTableData({ ...tableData, [section.id]: newData });
          },
          moduleData,
          tableData,
          record,
          section.fields,
          isEditing,
          moduleData.tenantId,
          moduleData.companyId, 
          moduleData._id,
          col.fieldOptions?.required || false, // Ensure required is passed correctly
        )
      ),
    }));

    return (
      <div key={section.id}>
        <h3>{section.title}</h3>
        <Button
          type="dashed"
          onClick={() => addTableRow(section.id)}
          style={{ marginBottom: 16 }}
        >
          Add Row
        </Button>
        <Table
          columns={[
            ...tableColumns,
            {
              title: 'Actions',
              key: 'actions',
              render: (_: any, record: any) => (
                <Button type="link" onClick={() => removeTableRow(section.id, record.id)}>
                  Delete
                </Button>
              ),
            },
          ]}
          dataSource={tableData[section.id] || []}
          pagination={false}
          rowKey="id"
        />
      </div>
    );
  };

  return (
    <Modal
      title={`${existingRecord ? 'Edit' : 'New'} ${moduleData?.moduleName}`}
      open={true}
      onCancel={closeModal}
      onOk={handleSave}
      width={800}
      key={modalKey}
    >
      <Form form={form} layout="vertical">
        {moduleData.sections?.map((section: any) => {
          if (section?.type === 'section') {
            return (
              <div key={section.id}>
                <h3>{section.title}</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                  {section.fields?.map((field: any) =>
                  renderField(
                    field,                                  // field
                    section.id,                             // sectionId
                    form,                                   // form
                    referenceData,                          // referenceData
                    (value: any) => form.setFieldsValue({ [field.name]: value }), // handleFieldChange
                    moduleData,                             // moduleData
                    tableData,                              // tableData
                    undefined,                              // rowData
                    undefined,                              // columns
                    isEditing,                              // isEditing
                    moduleData.tenantId,                    // tenantID
                    moduleData.companyId,                   // companyID
                    moduleData._id,                         // moduleID
                    itemId,                                 // itemId
                    field.fieldOptions?.required || false, // Ensure required is passed correctly
                  )
                  )}

                </div>
              </div>
            );
          } else if (section?.type === 'table') {
            return renderTableSection(section);
          }
          return null;
        })}
      </Form>
    </Modal>
  );
};

export default ReferencePageRender;