import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

interface TextFieldProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (fieldOptionKey: string, value: string) => void;
}

const TextField: React.FC<TextFieldProps> = ({ id, name, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || ''); // Sync with the parent component's value
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange && onChange('name', e.target.value); // Pass the updated name back
  };

  return (
    <Input
      id={id}
      name={name}
      value={value}
      onChange= {handleInputChange} //{(e) => onChange && onChange('name', e.target.value)}
      placeholder="Enter text"
    />
  );
};

export const TextFieldConfig: React.FC<{
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>Placeholder:</label>
        <Input
          value={fieldOptions.placeholder || ''}
          onChange={(e) =>
            setFieldOptions({ ...fieldOptions, placeholder: e.target.value })
          }
          placeholder="Enter placeholder text"
        />
      </div>
    </div>
  );
};

export default TextField;