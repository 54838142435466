// src/pages/Unauthorized.tsx
import React from 'react';

const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/login';
  };

  const goHome = () => {
    window.location.href = '/quickbuild-home';
  };

const Unauthorized: React.FC = () => {
  return (
    <div style={{ padding: '50px' }}>
      <h1>Unauthorized Access</h1>
      <p>You do not have permission to view this page.</p>
      <button 
        className="unauthorized-logout-btn"
        onClick={handleLogout}>
        Logout</button>
      <button 
        className="unauthorized-logout-btn"
        onClick={goHome}>
        Go Home</button>
    </div>
  );
};

export default Unauthorized;