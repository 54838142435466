// OneToManyField.tsx
import React, { useState, useEffect } from 'react';
import SubFieldPalette from './Z_SubFieldPalette';
import SubFieldOrganizer from './Z_SubFieldOrganizer';

interface OneToManyFieldProps {
  subFields: any[];
  setSubFields: React.Dispatch<React.SetStateAction<any[]>>;
}

const OneToManyField: React.FC<OneToManyFieldProps> = ({ subFields, setSubFields }) => {
  const onAddSubField = (fieldType) => {
    const newSubField = {
      id: `sub-field-${Date.now()}`,
      type: fieldType,
      name: `${fieldType}_${Date.now()}`,
      fieldOptions: {},
    };
    setSubFields((prevSubFields) => [...prevSubFields, newSubField]);
  };

  return (
    <div>
      <h3>Configure SubFields</h3>
      <SubFieldPalette
        onAddSubField={onAddSubField}
        availableFields={['TextField', 'EmailField', 'PhoneNumber', 'Dropdown']}
      />
      <SubFieldOrganizer subFields={subFields} setSubFields={setSubFields} />
    </div>
  );
};

// Configuration component for OneToManyField
export const OneToManyFieldConfig: React.FC<{
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
  const [subFields, setSubFields] = useState(fieldOptions.subFields || []);

  useEffect(() => {
    setFieldOptions({ ...fieldOptions, subFields });
  }, [subFields]);

  return <OneToManyField subFields={subFields} setSubFields={setSubFields} />;
};

export default OneToManyField;