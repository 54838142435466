// src/contexts/UserContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';

interface User {
  id: number;
  email: string;
  roles: string[];
  defaultTenantId: number; // Added defaultTenantId
}

interface UserContextType {
  user: User | null;
  loading: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextType | null>(null);

function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert base64url to base64
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          // Decode base64 and URI-encode the result to handle special characters
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload); // Parse the JSON result
  } catch (e) {
    return null;
  }
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // New loading state

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = parseJwt(token);
      if (decoded) {
        // Optional: Validate token expiration
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp && decoded.exp > currentTime) {
          setUser({
            id: decoded.sub,
            email: decoded.email,
            roles: decoded.roles || [],
            defaultTenantId: decoded.defaultTenantId, // Set defaultTenantId
          });
        } else {
          // Token has expired
          localStorage.removeItem('token');
        }
      } else {
        // Invalid token
        localStorage.removeItem('token');
      }
    }
    setLoading(false); // Set loading to false after checking the token
  }, []);

  const login = (token: string) => {
    localStorage.setItem('token', token);
    const decoded = parseJwt(token);
    if (decoded) {
      setUser({
        id: decoded.sub,
        email: decoded.email,
        roles: decoded.roles || [],
        defaultTenantId: decoded.defaultTenantId, // Set defaultTenantId
      });
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};