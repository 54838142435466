import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { TenantProvider } from '../contexts/TenantContext';  // Keep the context provider
import ProfileSelector from '../components/ProfileSelector';  // Import the ProfileSelector component

const { Content, Sider } = Layout;

const TenantLayout: React.FC = () => {

  return (
    <TenantProvider>
      <Layout style={{ minHeight: '100vh' }}>
        {/* Sidebar for navigation */}
        <Sider>
            <ProfileSelector />  {/* Dropdown for Tenant and Company */}    
        </Sider>

        {/* Main content area */}
        <Layout>
          <Content style={{ margin: '20px', padding: '20px', background: '#fff' }}>
            <Outlet />  {/* Render the child routes' content */}
          </Content>
        </Layout>
      </Layout>
    </TenantProvider>
  );
};

export default TenantLayout;