// TransactionPageBuilder.tsx

import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, notification, Select, Table } from 'antd';
import FieldPalette from '../Fields/_FieldPalette';
import ProcessBuilder from '../Process/ProcessBuilder';
import FieldTable from '../PageOrganizer/TableOrganizer';
import PageOrganizer from '../PageOrganizer/PageOrganizer';
import { useTenant } from '../../../contexts/TenantContext';
import { useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import StageBuilder from './StageBuilder';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '../../../contexts/UserContext';

const { Option } = Select;

interface TransactionPageBuilderProps {
  module?: any;  // Optional module prop
}

interface Field {
    id: string;
    name: string;
  }

type Section = FormSection | TableSection;

interface FormSection {
  id: string;
  title: string;
  type: 'section';
  fields: any[];
}

interface TableSection {
  id: string;
  title: string;
  type: 'table';
  columns: any[];
}





const TransactionPageBuilder: React.FC<TransactionPageBuilderProps> = () => {
  const location = useLocation();
  const module = location.state?.module; // Retrieve the passed module from location state
  const [pageTitle, setPageTitle] = useState('New Transaction Page');
  const [moduleName, setModuleName] = useState(pageTitle);
  const [version, setVersion] = useState('1.0.0');
  const [status, setStatus] = useState('draft');
  const [functions, setFunctions] = useState([]);
  const [isProcessModalVisible, setIsProcessModalVisible] = useState(false);
  const { tenantId, companyId } = useTenant();
  const [functionToEdit, setFunctionToEdit] = useState(null);
  // const [sections, setSections] = useState<Section[]>([]);
  // const [selectedSection, setSelectedSection] = useState<string | null>(sections.length > 0 ? sections[0].id : 'section-1');
  const [sections, setSections] = useState<Section[]>([
    { id: `section-${Date.now()}`, title: 'Section 1', type: 'section', fields: [] },
    { id: `table-${Date.now()}`, title: 'Table 1', type: 'table', columns: [] },
  ]);
  
  const [selectedSection, setSelectedSection] = useState<string>(
    `section-${Date.now()}` // Default to the first section
  );

  const [isStageModalVisible, setIsStageModalVisible] = useState(false);
  const [stages, setStages] = useState([]);
  const [stageFieldAdded, setStageFieldAdded] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const { user } = useUser();
  const { logout: userLogout } = useUser();
  const [currentFields, setCurrentFields] = useState<Field[]>([]); // State to store current fields
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Reference to store table data from multiple tables
  const tableRefs = useRef<any>({});

  const userPermissions = (() => {
    if (user?.roles.includes('quickbuildadmin')) {
      return { canViewFunctions: true };
    }
    return { canViewFunctions: false };
  })();

  const initialSectionsRef = useRef([]);


  useEffect(() => {
    if (module) {
      setPageTitle(module.moduleName || 'Editable Page Title');
      setModuleName(module.moduleName || 'Module Name');
      setFunctions(module.functions || []);
      setVersion(module.version || '1.0.0');
      setStatus(module.status || 'draft');
      setStages(module.stages || []);
      setStageFieldAdded(module.stageFieldAdded || false);
  
      // Map over sections to load fields and table columns correctly
      const updatedSections = (module.sections || []).map((section) => {
        if (section.type === 'table') {
          const tableColumns = module.tableData?.[section.id]?.columns || [];
          return { ...section, columns: tableColumns };
        } else if (section.type === 'section') {
          const fields = section.fields || [];
          return { ...section, fields };
        }
        return section;
      });
  
      setSections(updatedSections);
      setSelectedSection(updatedSections[0]?.id || '');
      initialSectionsRef.current = updatedSections;
      setHasUnsavedChanges(false)
    }
  }, [module]);

  const hasChanges = () => {
    // Compare sections
    if (sections.length !== initialSectionsRef.current.length) return true;
  
    for (let i = 0; i < sections.length; i++) {
      const currentSection = sections[i];
      const initialSection = initialSectionsRef.current[i];
  
      // Check section-level properties
      if (
        currentSection.id !== initialSection.id ||
        currentSection.title !== initialSection.title ||
        currentSection.type !== initialSection.type
      ) {
        return true;
      }
  
      // Compare fields for 'section' type
      if (currentSection.type === 'section') {
        if (currentSection.fields.length !== initialSection.fields.length) return true;
  
        for (let j = 0; j < currentSection.fields.length; j++) {
          if (
            JSON.stringify(currentSection.fields[j]) !==
            JSON.stringify(initialSection.fields[j])
          ) {
            return true;
          }
        }
      }
  
      // Compare columns for 'table' type
      if (currentSection.type === 'table') {
        if (currentSection.columns.length !== initialSection.columns.length) return true;
  
        for (let j = 0; j < currentSection.columns.length; j++) {
          if (
            JSON.stringify(currentSection.columns[j]) !==
            JSON.stringify(initialSection.columns[j])
          ) {
            return true;
          }
        }
      }
    }
  
    return false;
  };

  

  const onAddField = (fieldType, sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId && section.type === 'section') {
        const newField = {
          id: `field-${Date.now()}`,
          type: fieldType,
          name: `${fieldType}_${Date.now()}`,
          fieldOptions: {}
        };
        return { ...section, fields: [...section.fields, newField] };
    }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldUpdate = (sectionId, fieldId, updatedField) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId && section.type === 'section') {
        let updatedFields;
        if (fieldId === 'all') {
          // Replace the entire fields array
          updatedFields = updatedField;
        } else {
          // Update a single field
          updatedFields = section.fields.map((field) =>
            field.id === fieldId ? { ...field, ...updatedField } : field
          );
        }
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldDelete = (sectionId, fieldId) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId && section.type === 'section') {
        const updatedFields = section.fields.filter(field => field.id !== fieldId);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const addSection = () => {
    const newSection: FormSection = {
      id: `section-${Date.now()}`,
      title: `Section ${sections.length + 1}`,
      type: 'section',
      fields: [],
    };
    setSections([...sections, newSection]);
  };
  

  const addTable = () => {
    const newTable: TableSection = {
      id: uuidv4(), // Generate a unique UUID
      title: `Table ${sections.length + 1}`,
      type: 'table',
      columns: [],
    };
    setSections([...sections, newTable]);
  };

  // const handleSectionSelect = (sectionId) => {
  //   setSelectedSection(sectionId);
  // };

  const onDragEnd = (result) => {
    // Handle drag and drop within sections
    // Implement drag-and-drop logic as needed
  };

  const onSaveFunction = (newFunction) => {
    setFunctions((prevFunctions) => [...prevFunctions, newFunction]);
    setIsProcessModalVisible(false);
  };

  const handleDeleteFunction = (index) => {
    const updatedFunctions = functions.filter((_, funcIndex) => funcIndex !== index);
    setFunctions(updatedFunctions);
  };

  const handleEditFunction = (index) => {
    const funcToEdit = functions[index];
    setFunctionToEdit(funcToEdit);
    setIsProcessModalVisible(true);
  };

  const handleStageSave = (newStages) => {
    setStages(newStages);
    setIsStageModalVisible(false);
  
    // Add stage field to the schema if not already added
    if (!stageFieldAdded) {
      // const updatedSections = sections.map((section) => {
      //   if (section.type === 'section') {
      //     // Now TypeScript knows section is FormSection
      //     const stageField = {
      //       id: `stage-field-${Date.now()}`,
      //       type: 'TextField', // assuming you want a text field for stages
      //       name: 'Stage',
      //     };
      //     return {
      //       ...section,
      //       fields: [...section.fields, stageField],
      //     };
      //   } else if (section.type === 'table') {
      //   return section
      //   }
      //   else {
      //       return section
      //   }
      // });
    //   setSections(updatedSections);
      setStageFieldAdded(true);
    }
  };
  

  const saveSchema = async () => {

    // Collect table data from all tables
    const tableData = {};
    for (let section of sections) {
      if (section.type === 'table') {
        const tableRef = tableRefs.current[section.id];
        if (tableRef) {
          tableData[section.id] = tableRef.getTableData();
        }
        // console.log(section.type)
      }
    }

    // console.log(addTypeToSections)
    const schema = {
      pageTitle: moduleName,
      moduleName,
      version,
      status,
      sections, 
      tableData, 
      tenantId,
      companyId,
      pageType: 'Transaction',
      functions: functions.map((func) => ({
        functionId: func.functionId,
        name: func.name,
        steps: func.steps.map((step) => ({
          stepId: step.stepId,
          type: step.type,
          typeDetails: step.typeDetails,
        })),
      })),
      stages,
    };

    // console.log('saving schema: ', schema)

    const autoIncrementFields = [];
    sections.forEach((section) => {
      if (section.type === 'section') {  // Only FormSection has 'fields'
        section.fields.forEach((field) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      }
    });

    if (autoIncrementFields.length > 0) {
            

        // console.log('more than 1 autoincrement')
        for (const field of autoIncrementFields) {
            const { start, end } = field.fieldOptions || {};
            const match = start.match(/^([A-Za-z]*)(\d+)$/);
            const prefix = match ? match[1] : '';
            // Update company-global-records for each AutoIncrementId field
          try {

            // console.log('details: ', tenantId,  companyId, module?._id, field.id, start, end)
            const response = await fetch(`${API_URL}/auto-increment/update-auto-id`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                tenantID: tenantId,
                companyID: companyId,
                moduleID: module?._id || 'new-module',
                fieldId: field.id,
                start: start,
                end: end,
                prefix: prefix
              }),
            });

            // console.log(response)
    
            if (response.ok) {
              // console.log('')
              // const result = await response.json();
            //   console.log('AutoIncrementId updated successfully:', result);
            } else {
              console.error('Failed to update AutoIncrementId');
            }
          } catch (error) {
            console.error('Error updating AutoIncrementId:', error);
          }
        }
      }

      // console.log('schema: ', schema)


    try {
      const url = module?._id
        ? `${API_URL}/quickbuild-prod/page-modules/${module._id}`
        : `${API_URL}/quickbuild-prod/page-modules/save`;

        // console.log('url: ', url)
      const method = module?._id ? 'PUT' : 'POST';

      // console.log('method: ', method)
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(schema),
      });

      // console.log('response: ', response)


    //   console.log('url response: ', JSON.stringify(schema))

      if (response.ok) {
        console.log('Schema saved successfully!');
        notification.success({
          message: 'Schema saved',
          description: 'Click the "Builder" link to view in library',
          placement: 'top',
          duration: 3
        })
      } else {
        console.error('Failed to save schema.');
      }
    } catch (error) {
      console.error('Error saving schema:', error);
    }
  };

  let availableFields = [
    'TextField',
    'ParagraphTextField',
    'AutoIncrementId',
    'PhoneNumber',
    'EmailField',
    'Dropdown',
    'ReferenceLinkField',
    'NumberField', // New field type
    'DateField'
  ];

  let availableFieldsForTable = [
    'TextField',
    'ParagraphTextField',
    'PhoneNumber',
    'EmailField',
    'Dropdown',
    'ReferenceLinkField',
    'NumberField', // New field type
    'LineNumber',
    'DateField'
  ];

  if (userPermissions.canViewFunctions) {
    availableFields.push('CalculationField')
    availableFieldsForTable.push('CalculationField')
  }

  const functionColumns = [
    {
      title: 'Function ID',
      dataIndex: 'functionId',
      key: 'functionId',
    },
    {
      title: 'Function Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => handleEditFunction(index)}>Edit</Button>
          <Button type="link" onClick={() => handleDeleteFunction(index)}>Delete</Button>
        </>
      ),
    },
  ];

  // Function to update columns in the sections state
  const onColumnsChange = (sectionId, newColumns) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId && section.type === 'table') {
          return { ...section, columns: newColumns };
        }
        return section;
      })
    );
  };

  // Function to get all fields for Stages
  const getAllFields = () => {
    // console.log('getting all fields')
    const allFields: Field[] = [];
  
    sections.forEach((section) => {
      if (section.type === 'section') {
        // Collect fields directly from form sections
        if (section.fields && Array.isArray(section.fields)) {
          allFields.push(
            ...section.fields
              .filter((field: any) => field.id && field.name) // Ensure valid fields
              .map((field: any) => ({
                id: field.id,
                name: field.name,
              }))
          );
        }
      } else if (section.type === 'table') {
        // Retrieve table columns from tableRefs using getTableData
        const tableRef = tableRefs.current[section.id];
        if (tableRef && typeof tableRef.getTableData === 'function') {
          const tableDataEntry = tableRef.getTableData();
          if (tableDataEntry && Array.isArray(tableDataEntry.columns)) {
            const tableColumns = tableDataEntry.columns
              .filter((col: any) => col.dataIndex && col.name) // Ensure valid columns
              .map((col: any) => ({
                id: col.dataIndex, // Assuming dataIndex is unique and serves as ID
                name: col.name,
              }));
            allFields.push(...tableColumns);
          }
        }
      }
    });
  
    return allFields;
  };

  const updateCurrentFields = () => {
    const updatedFields = getAllFields();
    setCurrentFields(updatedFields);
  };


  const deleteSection = (sectionId: string) => {
    const updatedSections = sections.filter((section) => section.id !== sectionId);
    setSections(updatedSections);
  };


  useEffect(() => {
    updateCurrentFields();
  }, [sections, tableRefs]);

  useEffect(() => {
    if (hasChanges()) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [sections, functions, moduleName, version, status]);

  return (
    <div>
      <h1>Transaction Page Builder</h1>


      {hasUnsavedChanges && (
      <div className='unsaved-changes-banner'>
        <strong>Unsaved Changes:</strong> Don't forget to click "Save Schema" or your changes will be lost
      </div>
      )}

      <Input
        value={pageTitle}
        onChange={(e) => {
            // console.log('Title: ', pageTitle)
          setPageTitle(e.target.value);
          setModuleName(e.target.value);
        }}
        placeholder="Editable Page Title"
        style={{ marginBottom: '10px', width: '50%' }}
      />

      <Select 
        value={selectedSection} 
        onChange={(value) => setSelectedSection(value)} 
        style={{ marginBottom: '20px', width: '200px' }}
        >
          {sections
            .filter((section) => section.type === 'section')
            .map((section) => (
            <Option key={section.id} value={section.id}>
              {section.title}
            </Option>
          ))}
      </Select>

      <Button type="primary" onClick={addSection} style={{ marginBottom: '10px', marginRight: '10px' }}>
        Add Section
      </Button>
      <Button onClick={addTable} style={{ marginBottom: '10px' }}>
        Add Table
      </Button>

      <div>
      <h3>Field Palette</h3>
      <FieldPalette
        onAddField={(fieldType) => {
          if (selectedSection) {
            onAddField(fieldType, selectedSection);
          } else {
            alert('Please select a section.');
          }
        }}
        availableFields={availableFields}
      />
      </div>

      <div>
      {sections.map((section) => {
        if (section.type === 'section') {
          return (
            <div key={section.id}>
              <DragDropContext onDragEnd={onDragEnd}>
                <PageOrganizer
                  sections={[section]}
                  setSections={setSections}
                  onFieldUpdate={onFieldUpdate}
                  onFieldDelete={onFieldDelete}
                  pageType='Transaction'
                />
              </DragDropContext>
              <Button onClick={() => deleteSection(section.id)}>Delete Section</Button>
            </div>
          );
        } else if (section.type === 'table') {
          return (
            <div key={section.id}>
              <h3>{section.title}</h3>
              <FieldTable
                ref={(ref) => (tableRefs.current[section.id] = ref)}
                availableFields={availableFieldsForTable}
                currentModuleId={module?._id || 'new-module'}
                currentModuleName={module?.moduleName || moduleName}
                initialColumns={section.columns || []}
                onColumnsChange={(newColumns) => onColumnsChange(section.id, newColumns)}
                currentFieldNames={getAllFields()} // Add new variable
              />
            </div>
          );
        }
        return null;
      })}
    </div>

      <Button type="primary" onClick={saveSchema} style={{ marginTop: '10px' }}>
        Save Schema
      </Button>

      <Button onClick={() => setIsStageModalVisible(true)} style={{ marginLeft: '10px' }}>
        Configure Stages
      </Button>

      {userPermissions.canViewFunctions && (
      <div>
        <Button onClick={() => setIsProcessModalVisible(true)} style={{ marginLeft: '10px' }}>
          Add Function
        </Button>
        <h3>Functions</h3>
        <Table dataSource={functions} columns={functionColumns} rowKey="functionId" />
      

      {/* Modal for adding new functions using ProcessBuilder */}
      <Modal
        title="Process Builder"
        open={isProcessModalVisible}
        onCancel={() => setIsProcessModalVisible(false)}
        footer={null}
        width={800}
      >
        <ProcessBuilder
          existingFunction={functionToEdit}
          onSaveFunction={(newFunction) => {
            onSaveFunction(newFunction);
            setIsProcessModalVisible(false);
          }}
          stages={stages} // Pass stages to ProcessBuilder
          fields={getAllFields()} // Pass fields to ProcessBuilder
        />
      </Modal>
      </div>
      )}

      {/* Modal for configuring stages */}
      <Modal
        title="Stage Builder"
        open={isStageModalVisible}
        onCancel={() => setIsStageModalVisible(false)}
        footer={null}
      >
        <StageBuilder
            existingStages={stages}
            allFields={getAllFields()} // Pass all fields for field selection
            onSaveStages={handleStageSave}
        />
      </Modal>
    </div>
  );
};

export default TransactionPageBuilder;