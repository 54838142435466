// BasePageRender.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table, Modal, message, Input, Radio } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import ReferencePageRender from './ReferencePageRender';
import NestedPageRender from './NestedPageRender';
import TransactionPageRender from './TransactionPageRender';
import Highlighter from 'react-highlight-words';
import ImportDataModal from './ImportDataModal';

const BasePageRender: React.FC = () => {
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState<any>(null);
  const [records, setRecords] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Added loading state
  const [isDetailView, setIsDetailView] = useState(false);
  const [stageFilter, setStageFilter] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Record<string, any>>({});
  const [isScrollEnabled, setIsScrollEnabled] = useState(true); // State to control scrolling
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAggregateValues = async (records: any[], moduleData: any) => {
    const aggregateFields = moduleData.sections
      .filter((section: any) => section.type === 'section')
      .flatMap((section: any) => section.fields)
      .filter((field: any) => field.type === 'AggregateField');
  
    if (aggregateFields.length === 0) {
      return records;
    }
  
    const tenantId = moduleData.tenantId;
    const companyId = moduleData.companyId;
    const moduleId = moduleData._id;
  
    const updatedRecords = await Promise.all(
      records.map(async (record) => {
        const itemId = record._id;
  
        for (const field of aggregateFields) {
          try {
            const response = await axios.get(`${API_URL}/aggregate/value`, {
              params: {
                tenantId,
                companyId,
                moduleId,
                fieldId: field.id,
                itemId,
              },
            });
            const value = response.data.value;
            record.data = record.data || {};
            record.data[field.name] = value;
          } catch (error) {
            console.error('Failed to fetch aggregate value', error);
            record.data[field.name] = null;
          }
        }
  
        return record;
      })
    );
  
    return updatedRecords;
  };


  const fetchModuleData = async () => {
    setLoading(true); 
    try {
      const moduleResponse = await axios.get(
        `${API_URL}/quickbuild-prod/page-modules/${moduleId}`
      );
      setModuleData(moduleResponse.data);

      let recordsResponse;
      const { tenantId, companyId } = moduleResponse.data;

      // Fetch records based on page type
      if (moduleResponse.data.pageType === 'Reference') {
        recordsResponse = await axios.get(
          `${API_URL}/reference-data?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
        );
      } else if (moduleResponse.data.pageType === 'Nested') {
        recordsResponse = await axios.get(
          `${API_URL}/nested-data?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
        );
      } else if (moduleResponse.data.pageType === 'Transaction') {
        recordsResponse = await axios.get(
          `${API_URL}/transaction-data?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
        );
      }

      // Exclude records with movedToNextStage = true for transactions
      let fetchedRecords = recordsResponse.data;
      if (moduleResponse.data.pageType === 'Transaction') {
        fetchedRecords = fetchedRecords.filter((record: any) => !record.movedToNextStage);
      }

      // Fetch aggregate values for each record
      const recordsWithAggregates = await fetchAggregateValues(fetchedRecords, moduleResponse.data);

      setRecords(recordsWithAggregates);
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        setRecords([]);
      } else {
        setError('Failed to fetch data.');
      }
    }
    finally {
      setLoading(false); // End loading
    }
  };


  useEffect(() => {
    fetchModuleData();
  }, [moduleId]);

  useEffect(() => {
    if (moduleData?.pageType !== 'Transaction') {
      setStageFilter(null);
      setIsDetailView(false);
    }
  }, [moduleData?.pageType]);

  const toggleView = () => {
    if (moduleData.pageType === 'Transaction') {
      setIsDetailView(prev => !prev);
    } else {
      setIsDetailView(false);
    }
  };

  const filterRecordsByStage = (stageId: string | null) => {
    setStageFilter(stageId);
  };

  const resetAllFilters = () => {
    setColumnFilters({}); // Resets all column filters
    setStageFilter(null); // Resets the stage filter if used
    // Add any other state resets related to filtering here
  };

  // Helper function to extract value based on dataIndex and pageType
  const extractValue = (record, dataIndex, pageType) => {
    // Check if record is a flattened line item
    if (record.hasOwnProperty('lineItemId')) {
      // For flattened line items, data is directly on the record
      let value = record[dataIndex];
      if (value && typeof value === 'object') {
        if ('label' in value) {
          return value.label;
        } else if ('value' in value) {
          if (
            typeof value.value === 'object' &&
            'label' in value.value
          ) {
            return value.value.label;
          }
          return value.value;
        }
      }
      return value;
    } else {
      // For header records
      let value = record.data ? record.data[dataIndex] : record[dataIndex];
  
      if (value) {
        if (typeof value === 'string') {
          return value;
        } else if (typeof value === 'object') {
          if ('label' in value && typeof value.value === 'string') {
            return value.value; // For simple string values
          } else if (
            'value' in value &&
            typeof value.value === 'object' &&
            'label' in value.value
          ) {
            return value.value.label; // For nested objects with a label
          } else if ('label' in value) {
            return value.label; // Fallback to label if available
          }
        }
      }
  
      // If not found in top-level data and pageType is 'Transaction', check tableData
      if (
        pageType === 'Transaction' &&
        record.data &&
        record.data.tableData
      ) {
        const tableData = record.data.tableData;
  
        for (const tableKey in tableData) {
          const tableRecords = tableData[tableKey];
          if (Array.isArray(tableRecords)) {
            for (const lineItem of tableRecords) {
              for (const fieldKey in lineItem) {
                const field = lineItem[fieldKey];
                if (field && field.label === dataIndex) {
                  if (
                    typeof field.value === 'object' &&
                    'label' in field.value
                  ) {
                    return field.value.label; // Nested label
                  }
                  return field.value; // Simple value
                }
              }
            }
          }
        }
      }
  
      // Return undefined if no matching value is found
      return undefined;
    }
  };

  const getColumnUniqueValues = (dataIndex: string, data: any[], pageType: string) => {
    const values = data.map(record => extractValue(record, dataIndex, pageType));
  
    // Filter out undefined values and return unique values
    return Array.from(new Set(values.filter(value => value !== undefined)));
  };

  // Function to generate filter properties for columns
  const getColumnFilterProps = (dataIndex, fieldType) => {
    const uniqueValues = getColumnUniqueValues(dataIndex, records, moduleData.pageType);
  
    const onFilter = (value, record) => {
      // Function to check value match in nested objects
      const checkMatch = (fieldValue, filterValue) => {
        if (typeof fieldValue === 'object' && fieldValue !== null) {
          if ('label' in fieldValue) {
            return fieldValue.label === filterValue;
          } else if ('value' in fieldValue) {
            if (typeof fieldValue.value === 'string') {
              return fieldValue.value === filterValue;
            } else if ('label' in fieldValue.value) {
              return fieldValue.value.label === filterValue;
            }
          }
        }
        return fieldValue === filterValue;
      };
  
      // First, check top-level data fields
      let recordValue = record.data[dataIndex];
      if (recordValue && checkMatch(recordValue, value)) {
        return true;
      }
  
      // Second, handle nested tableData fields for transaction pages
      if (moduleData.pageType === 'Transaction' && record.data.tableData) {
        for (const tableKey in record.data.tableData) {
          const tableRecords = record.data.tableData[tableKey];
          for (const lineItem of tableRecords) {
            for (const key in lineItem) {
              const field = lineItem[key];
              if (field && field.label === dataIndex && checkMatch(field, value)) {
                return true;
              }
            }
          }
        }
      }
  
      return false; // Default to false if no matches found
    };

    const showImportModal = () => {
      setIsImportModalVisible(true);
    };
    
    const handleImportCancel = () => {
      setIsImportModalVisible(false);
    };

    const handleImportSuccess = (importResult) => {
      message.success(`Successfully imported ${importResult.insertedCount} records.`);
      setIsImportModalVisible(false);
      // Optionally, refresh records
      fetchModuleData();
    };

  
    return {
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={columnFilters[dataIndex]?.searchText || ''}
            onChange={e => {
              const currValue = e.target.value;
              setColumnFilters(prev => ({
                ...prev,
                [dataIndex]: {
                  ...prev[dataIndex],
                  searchText: currValue,
                },
              }));
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <div style={{ maxHeight: 200, overflowY: 'auto' }}>
            <Radio.Group
              onChange={e => {
                setColumnFilters(prev => ({
                  ...prev,
                  [dataIndex]: {
                    ...prev[dataIndex],
                    selectedValue: e.target.value,
                  },
                }));
              }}
              value={columnFilters[dataIndex]?.selectedValue}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {uniqueValues.map((value, index) => (
                <Radio key={index} value={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginTop: 8, marginRight: 8 }}
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              setColumnFilters(prev => ({
                ...prev,
                [dataIndex]: {
                  searchText: '',
                  selectedValue: null,
                },
              }));
              confirm();
            }}
            size="small"
            style={{ width: 90, marginTop: 8 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <span style={{
          color: filtered ? '#fff' : undefined,
          fontSize: filtered ? '16px' : '14px',
          backgroundColor: filtered ? '#1890ff' : undefined, 
          borderRadius: '50%',
          padding: filtered ? '5px' : '0',
          border: filtered ? '2px solid #1890ff' : 'none',
          display: 'inline-flex', // Ensures the span behaves as an inline element with flex properties
          justifyContent: 'center', // Centers the icon horizontally in the span
          alignItems: 'center', // Centers the icon vertically in the span
        }}>
          <FilterOutlined />
        </span>
      ),
      onFilter: (value, record) => onFilter(value, record),
    };
  };

  // Flatten parent data function
  const flattenParentData = (record: any) => {
    const flattenedData: any = {};
    // for (const [key, field] of Object.entries(record.data)) {
    //   if (key !== 'tableData') {
    //     flattenedData[key] =
    //       field && typeof field === 'object' && 'value' in field ? field.value : field;
    //   }
    // }

    if (record.data) {
      for (const [key, field] of Object.entries(record.data)) {
        if (key !== 'tableData') {
          flattenedData[key] =
            field && typeof field === 'object' && 'value' in field ? field.value : field;
        }
      }
    }

    flattenedData._id = record._id;
    flattenedData.originalRecord = record;

    if (moduleData.pageType === 'Transaction') {
      flattenedData.stageId = record.stageId || 'N/A';
    }

    return flattenedData;
  };

  // Flatten line items function
  const flattenLineItems = (record: any, flattenedData: any) => {
    const lineItems: any[] = [];
    const tableSections = moduleData.sections.filter(
      (section: any) => section.type === 'table'
    );

    tableSections.forEach((tableSection: any) => {
      const tableId = tableSection.id;
      const tableRecords = record.data?.tableData?.[tableId] || [];
      tableRecords.forEach((lineItem: any, index: number) => {
        const flattenedLineItem: any = { ...flattenedData };
        const tableDefinition = moduleData.tableData[tableId];
        tableDefinition?.columns?.forEach((col: any) => {
          const value = lineItem[col.dataIndex];
          flattenedLineItem[col.name] = value?.value || value;
        });
        flattenedLineItem.lineItemId = `${record._id}-${tableId}-${index}`;
        lineItems.push(flattenedLineItem);
      });
    });

    return lineItems.length > 0 ? lineItems : [flattenedData];
  };

  // Function to apply both Stage Filters and Column Filters
  const applyFilters = () => {
    let tempRecords;
  
    if (isDetailView) {
      // Flatten all records into line items
      tempRecords = records.flatMap(record =>
        flattenLineItems(record, flattenParentData(record))
      );
    } else {
      tempRecords = [...records];
    }
  
    // Apply Stage Filter
    if (stageFilter) {
      tempRecords = tempRecords.filter(record => record.stageId === stageFilter);
    }
  
    // Apply Column Filters
    Object.keys(columnFilters).forEach(dataIndex => {
      const filter = columnFilters[dataIndex];
      if (filter && filter.selectedValue) {
        tempRecords = tempRecords.filter(record => {
          const recordValue = extractValue(record, dataIndex, moduleData.pageType);
          return recordValue === filter.selectedValue;
        });
      }
    });
  
    return tempRecords;
  };

  const displayRecords: any[] = isDetailView
  ? applyFilters()
  : applyFilters().map(record => flattenParentData(record));

  // // Prepare displayRecords based on view and filters
  // const displayRecords: any[] = isDetailView
  //   ? applyFilters().flatMap(record =>
  //       flattenLineItems(record, flattenParentData(record))
  //     )
  //   : applyFilters().map(record => flattenParentData(record));

  const getColumns = (isDetail: boolean) => {
    if (!moduleData) return [];
  
    const parentFields = moduleData.sections
      .filter((section: any) => section.type === 'section')
      .flatMap((section: any) => section.fields);
  
    // Define the stage column
    const stageColumn = {
      title: 'Stage',
      dataIndex: 'stageId',
      key: 'stageId',
      responsive: ['lg'],
      render: (stageId: any, record: any) => {
        const stage = record.stageId;
        return stage ? stageId : 'N/A';
      },
    };
  
    // Generate parent columns
    const parentColumns = parentFields.map((field: any) => {
      let column: any = {
        title: field.name,
        dataIndex: field.name,
        key: field.id,
        responsive: ['lg'],
        render: (text: any) =>
          field.type === 'ReferenceLinkField' ? text?.label || '' : text || '',
      };
  
      if (
        ['AutoIncrementId', 'Dropdown', 'ReferenceLinkField'].includes(field.type)
      ) {
        column = {
          ...column,
          ...getColumnFilterProps(field.name, field.type),
        };
      }
  
      return column;
    });
  
    let columns;
  
    if (isDetail) {
      const tableSections = moduleData.sections.filter(
        (section: any) => section.type === 'table'
      );
  
      const tableColumns = tableSections.flatMap((tableSection: any) => {
        const tableDefinition = moduleData.tableData[tableSection.id];
        return tableDefinition?.columns.map((col: any) => {
          let column: any = {
            title: col.name,
            dataIndex: col.name,
            key: col.key,
            responsive: ['lg'],
            render: (text: any) =>
              col.type === 'ReferenceLinkField' ? text?.label || '' : text || '',
          };
  
          if (
            ['autoIncrementId', 'dropdown', 'ReferenceLinkField'].includes(col.type)
          ) {
            column = {
              ...column,
              ...getColumnFilterProps(col.name, col.type),
            };
          }
  
          return column;
        });
      });
  
      columns = moduleData.pageType === 'Transaction'
        ? [stageColumn, ...parentColumns, ...tableColumns, getActionColumn()]
        : [...parentColumns, ...tableColumns, getActionColumn()];
    } else {
      columns = moduleData.pageType === 'Transaction'
        ? [stageColumn, ...parentColumns, getActionColumn()]
        : [...parentColumns, getActionColumn()];
    }
  
    // If "Fit" mode is active (isScrollEnabled is false), adjust the column widths
    if (!isScrollEnabled) {
      const totalWidth = window.innerWidth - 50; // Account for some padding
      const columnWidth = Math.floor(totalWidth / columns.length);
  
      // Set the width for each column
      columns = columns.map(col => ({
        ...col,
        width: columnWidth,
      }));
    }
  
    return columns;
  };

  const getActionColumn = () => ({
    title: 'Actions',
    key: 'actions',
    responsive: ['lg'],
    render: (_: any, record: any) => (
      <>
        <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record)}
          danger
          style={{ marginLeft: 8 }}
        />
      </>
    ),
  });

  const handleDelete = (record: any) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const endpoint =
            moduleData.pageType === 'Reference'
              ? 'reference-data'
              : moduleData.pageType === 'Nested'
              ? 'nested-data'
              : 'transaction-data';
          const { tenantId, companyId } = moduleData;
          await axios.delete(`${API_URL}/${endpoint}/${record._id}`, {
            params: { moduleId, tenantId, companyId },
          });
          setRecords(prev => prev.filter(r => r._id !== record._id));
          message.success('Record deleted successfully.');
        } catch (error) {
          message.error('Failed to delete the record.');
        }
      },
    });
  };

  const handleEdit = (record: any) => {
    const fullRecord = record.originalRecord || record;
    // console.log(fullRecord);
    setSelectedRecord(fullRecord);
    setIsModalVisible(true);
  };

  if (!moduleData) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // CSV Export Functions
  function convertToCSV(objArray: any[], columns: any[]) {
    let str = columns.map(column => `"${column.title}"`).join(',') + '\r\n';

    objArray.forEach(obj => {
      let line = columns
        .map(column => {
          let value = obj[column.dataIndex];
          if (value && typeof value === 'object') {
            return `"${value.label || ''}"`;
          }
          return `"${value || ''}"`;
        })
        .join(',');
      str += line + '\r\n';
    });

    return str;
  }

  function resolveComplexFields(records: any[]) {
    return records.map(record => {
      const resolvedRecord = { ...record };
      Object.keys(resolvedRecord).forEach(key => {
        if (typeof resolvedRecord[key] === 'object' && resolvedRecord[key] !== null) {
          if (resolvedRecord[key].hasOwnProperty('label')) {
            resolvedRecord[key] = resolvedRecord[key].label;
          } else if (Array.isArray(resolvedRecord[key])) {
            resolvedRecord[key] = resolvedRecord[key]
              .map((item: any) => item.label)
              .join(', ');
          } else {
            resolvedRecord[key] = JSON.stringify(resolvedRecord[key]);
          }
        }
      });
      return resolvedRecord;
    });
  }

  

  function downloadCSV(records: any[], columns: any[], filename = 'output.csv') {
    const processedRecords = resolveComplexFields(records);
    const csvData = convertToCSV(processedRecords, columns);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  const handleDownloadTemplate = async () => {
    // console.log('trying download')
    if (!moduleData) return;
  
    const { tenantId, companyId } = moduleData;
  
    // console.log('module: ', moduleData)
    try {
      const response = await axios.get(`${API_URL}/import/template`, {
        params: { moduleId, tenantId, companyId },
        responseType: 'blob', // Important for file downloads
      });

      // console.log('response: ', response)
      // console.log('response data: ', response.data)
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      // console.log('url: ', url)
      // console.log('link: ', link)
      link.href = url;
      link.setAttribute('download', `${moduleData.moduleName}_ImportTemplate.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error('Failed to download the import template.');
    }
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
  };

  const handleImportCancel = () => {
    setIsImportModalVisible(false);
  };

  const handleImportSuccess = (importResult) => {
    message.success(`Successfully imported ${importResult.insertedCount} records.`);
    setIsImportModalVisible(false);
    // Refresh records
    fetchModuleData();
  };

  return (
    <div>
      <h1>{moduleData?.moduleName || 'Loading...'}</h1>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={() => setIsScrollEnabled(!isScrollEnabled)}>
          {isScrollEnabled ? '  Fit  ' : 'Expand'}
        </Button>
        <Button onClick={toggleView}>
          View: {isDetailView ? 'Detail' : 'Header'}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setSelectedRecord(null);
            setIsModalVisible(true);
          }}
          style={{ marginLeft: 8 }}
        >
          + New
        </Button>
        <Button
          onClick={() =>
            downloadCSV(displayRecords, getColumns(isDetailView), 'exported_data.csv')
          }
          type="primary"
          style={{ marginLeft: 8 }}
          icon={<ExportOutlined />}
        >
          Export
        </Button>

        <Button
          type="primary"
          onClick={resetAllFilters} // Using the reset function
          style={{ marginLeft: 8 }}
        >
          Reset All Filters
        </Button>
        <Button
          onClick={handleDownloadTemplate}
          style={{ marginLeft: 8 }}
        >
          Download Import Template
        </Button>
        <Button
          onClick={showImportModal}
          style={{ marginLeft: 8 }}
        >
          Import Data
        </Button>
      </div>

      {moduleData.pageType === 'Transaction' && (
        <div style={{ marginBottom: 16 }}>
          <Button
            onClick={() => filterRecordsByStage(null)}
            type={!stageFilter ? 'primary' : 'default'}
          >
            All Stages
          </Button>
          {moduleData.stages?.map((stage: any) => (
            <Button
              key={stage.id}
              onClick={() => filterRecordsByStage(stage.name)}
              type={stageFilter === stage.name ? 'primary' : 'default'}
            >
              {stage.name}
            </Button>
          ))}
        </div>
      )}
      <Table
        columns={getColumns(isDetailView)}
        dataSource={displayRecords}
        rowKey={isDetailView ? 'lineItemId' : '_id'}
        scroll={isScrollEnabled ? { x: 'max-content' } : {}}
        loading={loading} // Ant Design's loading prop
      />

      {isModalVisible && (
        <>
          {moduleData.pageType === 'Reference' && (
            <ReferencePageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
          {moduleData.pageType === 'Nested' && (
            <NestedPageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
          {moduleData.pageType === 'Transaction' && (
            <TransactionPageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              records={records}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
        </>
      )}
      {isImportModalVisible && (
        <Modal
          title="Import Data"
          open={isImportModalVisible}
          onCancel={handleImportCancel}
          footer={null}
        >
          <ImportDataModal
            moduleId={moduleId}
            tenantId={moduleData.tenantId}
            companyId={moduleData.companyId}
            onClose={handleImportCancel}
            onImportSuccess={handleImportSuccess}
          />
        </Modal>
      )}
    </div>
  );
};

export default BasePageRender;