import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Table, Button, Dropdown } from 'antd';
// import { useTenant } from '../../../contexts/TenantContext';
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FieldModals from '../Fields/_FieldModals';
import { reorder } from '../../../utils/reorder'; 

interface FieldTableProps {
  availableFields: string[];
  currentModuleId: string;
  currentModuleName: string;
  initialColumns?: any[];
  onColumnsChange?: (columns: any[]) => void;
  currentFieldNames?: { id: string; name: string }[]; // Existing field names
}

const TableOrganizer = forwardRef((props: FieldTableProps, ref) => {
  const { availableFields, currentModuleId, currentModuleName, initialColumns, onColumnsChange, currentFieldNames } = props;
  const [columns, setColumns] = useState([]);
  const [currentField, setCurrentField] = useState(null);
  const [data] = useState([{ key: 'row-1' }, { key: 'row-2' }, { key: 'row-3' }]); // Placeholder rows (read-only)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fieldDefs, setFieldDefs] = useState([]);

  // console.log('initial: ', initialColumns)
  // const { tenantId, companyId } = useTenant();

  useEffect(() => {
    setFieldDefs(initialColumns || []);
  }, [initialColumns]);

  useImperativeHandle(ref, () => ({
    getTableData: () => {
      return {
        columns: columns.map((col) => ({
          dataIndex: col.dataIndex,
          key: col.key,
          type: col.type,
          name: col.name,
          fieldOptions: col.fieldOptions,
        })),
        data,
      };
    },
    addField,
  }));

  useEffect(() => {
    const reconstructedColumns = (initialColumns || []).map((col) => createColumn(col));
    setColumns(reconstructedColumns);
  }, [initialColumns]);

  // Function to create a column based on a field
  const createColumn = (field) => {
    const dataIndex = field.dataIndex;
    // console.log('field: ', field)
    return {
      ...field,
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button type="link" onClick={() => onFieldClick(dataIndex)} style={{ flex: 1 }}>
            {field.name || field.type}
          </Button>
          {/* Move Buttons */}
          <div className="move-buttons" style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                moveColumnLeft(dataIndex);
              }}
              disabled={getColumnIndex(dataIndex) === 0}
              style={{ marginRight: '5px' }}
            />
            <Button
              type="text"
              icon={<ArrowRightOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                moveColumnRight(dataIndex);
              }}
              disabled={getColumnIndex(dataIndex) === columns.length - 1}
            />
          </div>
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={(e) => onFieldDelete(dataIndex)}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      ),
      render: (text, record) => {
        if (field.type === 'CalculationField') {
          try {
            const formula = field.fieldOptions.formula || '';
            const evaluatedFormula = formula.replace(/\[([^\]]+)\]/g, (_, fieldName) => {
              const value = record[fieldName.trim()];
              return value !== undefined ? value : 0;
            });
            const result = eval(evaluatedFormula);
            return result;
          } catch (error) {
            return 'Error';
          }
        }
        return <span />;
      },
    };
  };

   // Function to get the index of a column
   const getColumnIndex = (dataIndex) => {
    return columns.findIndex((col) => col.dataIndex === dataIndex);
  };

  const moveColumnLeft = (index) => {
    if (index <= 0) return;
    const newFieldDefs = reorder(fieldDefs, index, index - 1);
    setFieldDefs(newFieldDefs);
    onColumnsChange(newFieldDefs);
  };
  
  const moveColumnRight = (index) => {
    if (index >= fieldDefs.length - 1) return;
    const newFieldDefs = reorder(fieldDefs, index, index + 1);
    setFieldDefs(newFieldDefs);
    onColumnsChange(newFieldDefs);
  };

  
  const addField = (fieldType) => {
    const dataIndex = `field_${Date.now()}`;
    const newField = {
      dataIndex,
      key: dataIndex,
      type: fieldType,
      name: fieldType,
      fieldOptions: {
        options: fieldType === 'Dropdown' ? [] : undefined,
        startId: fieldType === 'AutoIncrementId' ? 'A1' : undefined,
        endId: fieldType === 'AutoIncrementId' ? 'A9' : undefined,
        modules: fieldType === 'ReferenceLinkField' ? [] : undefined,
      },
    };
  
  
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns, createColumn(newField)];
      onColumnsChange(updatedColumns);
      return updatedColumns;
    });
  };

  const onFieldClick = (dataIndex) => {
    setColumns((prevColumns) => {
      const field = prevColumns.find((col) => col.dataIndex === dataIndex);
      // console.log('Field clicked: ', field);
      
      if (field) {
        setCurrentField(field);
        setIsModalVisible(true);
      } else {
        console.error(`Field with dataIndex: ${dataIndex} not found!`);
      }
  
      return prevColumns;  // Return the previous state unchanged
    });
  };

  const handleModalOk = (updatedField) => {
    setColumns((prevColumns) => {
      const updatedColumns = prevColumns.map((col) =>
        col.dataIndex === updatedField.dataIndex ? createColumn(updatedField) : col
      );
      onColumnsChange(updatedColumns)
      return updatedColumns;
    });
    setIsModalVisible(false);
    setCurrentField(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCurrentField(null);
  };

  const handleFieldOptionChange = (fieldOptionKey, value) => {
    // console.log('table organizer handle field option change')

    setCurrentField((prevField) => {
      let updatedField;
      if (fieldOptionKey === 'name') {
        updatedField = { ...prevField, name: value };
      } else {
        const updatedFieldOptions = {
          ...prevField.fieldOptions,
          [fieldOptionKey]: value,
        };
        updatedField = { ...prevField, fieldOptions: updatedFieldOptions };
      }

      setColumns((prevColumns) => {
        const updatedColumns = prevColumns.map((col) =>
          col.dataIndex === prevField.dataIndex ? createColumn(updatedField) : col
        );
        return updatedColumns;
      });

      return updatedField;
    });
  };

  

  const menuItems = availableFields.map((fieldType) => ({
    key: fieldType,
    label: fieldType,
    onClick: () => addField(fieldType),
  }));

  const dropdownMenu = {
    items: menuItems,
  };

  const tableColumns = fieldDefs.map((field, index) => {
    const dataIndex = field.dataIndex;
    const totalColumns = fieldDefs.length;
  
    return {
      ...field,
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button type="link" onClick={() => onFieldClick(dataIndex)} style={{ flex: 1 }}>
            {field.name || field.type}
          </Button>
          {/* Move Buttons */}
          <div className="move-buttons" style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                moveColumnLeft(index);
              }}
              disabled={index === 0}
              style={{ marginRight: '5px' }}
            />
            <Button
              type="text"
              icon={<ArrowRightOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                moveColumnRight(index);
              }}
              disabled={index === totalColumns - 1}
            />
          </div>
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onFieldDelete(dataIndex);
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      ),
      render: (text, record) => {
        // Your render logic
      },
    };
  });
  
  // Add the "+" column at the end
  tableColumns.push({
    title: (
      <Dropdown menu={dropdownMenu} trigger={['click']}>
        <Button icon={<PlusOutlined />} type="text" />
      </Dropdown>
    ),
    dataIndex: 'add',
    key: 'add',
    render: () => null,
  });


  const onFieldDelete = (dataIndex) => {
    setColumns((prevColumns) => { 
      const updatedColumns = prevColumns.filter((col) => col.dataIndex !== dataIndex);
      onColumnsChange(updatedColumns);
      return updatedColumns  
    })
  };

  return (
    <div>
      <Table
        columns={tableColumns}
        dataSource={data}
        pagination={false}
        scroll={{ x: true }}
        bordered
      />

      <FieldModals
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        currentField={currentField}
        handleFieldOptionChange={handleFieldOptionChange}
        currentModuleId={currentModuleId}
        currentModuleName={currentModuleName}
        currentFieldNames={currentFieldNames}

      />
    </div>
  );
});

export default TableOrganizer;