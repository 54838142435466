// ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

interface ProtectedRouteProps {
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles }) => {
  const { user, loading } = useUser();

  if (loading) {
    // While loading, don't render anything or render a loading spinner
    return null; // Or <LoadingSpinner />
  }

  if (!user) {
    // User is not logged in
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.some((role) => user.roles.includes(role))) {
    // User does not have the required role
    return <Navigate to="/unauthorized" replace />;
  }

  // User is authorized, render the requested component
  return <Outlet />;
};

export default ProtectedRoute;