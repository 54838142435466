import React, { useState, useEffect } from 'react';
import { create, all, row } from 'mathjs';
import { Parser } from 'expr-eval';


interface CalculationFieldRenderProps {
  field: any;
  rowData: any;
  columns: any[];
  handleFieldChange: Function;
  sectionId: string;
  rowId: string;
}

// Configure mathjs instance
const math = create(all, {
  // Add custom configuration if needed
  number: 'number', // Use "number" to prevent BigNumber by default
  precision: 14, // Default precision
});

const evaluateExpression = (expression: string, rowData: any, columns: any[]) => {
  // console.log('expression: ', expression)
  // console.log('row: ', rowData)
  // console.log('columns: ', columns)
  // Map field names to dataIndex
  const fieldNameToDataIndex: { [key: string]: string } = {};
  columns.forEach((col: any) => {
    fieldNameToDataIndex[col.name] = col.dataIndex;
  });

  // Create a scope object for variable substitution
  const scope: { [key: string]: any } = {};

  // console.log('scope: ', scope)

  // Populate the scope with values from rowData
  columns.forEach((col: any) => {
    const dataIndex = col.dataIndex;
    const value = rowData && rowData[dataIndex];

    if (typeof value === 'object' && value !== null) {
      if ('uuid' in value) {
        scope[col.name] = value.uuid || 0;
      } else if ('value' in value) {
        scope[col.name] = value.value || 0;
      }
    } else {
      scope[col.name] = value || 0; // Default to 0 if undefined
    }
  });

  // Add global constants
  scope.PI = Math.PI;
  scope.E = Math.E;

  // Replace placeholders like [Qty] with variable names
  const parsedExpression = expression.replace(/\[(.*?)\]/g, (_, key) => key.trim());
  // console.log('parsedExpression: ', parsedExpression)

  try {
    const parser = new Parser();
    // console.log('parsed: ', parser)
    const result = parser.evaluate(parsedExpression, scope);
    return result;
  } catch (error) {
    console.error('Error evaluating expression:', error);
    return ''; // Return an empty string on error
  }
};

const CalculationFieldRender: React.FC<CalculationFieldRenderProps> = ({
  field,
  rowData,
  columns,
  handleFieldChange,
}) => {
  const [calculatedValue, setCalculatedValue] = useState<string | number>('');

  useEffect(() => {
    const result = evaluateExpression(field.fieldOptions.formula, rowData, columns || []);
    setCalculatedValue(result);
  
    // Only update if value has changed to prevent infinite loops
    if (rowData && rowData[field.dataIndex] !== result) {
      handleFieldChange(result); // Pass only the result
    }
  }, [field.fieldOptions.formula, rowData, columns]);

  return <div>{calculatedValue}</div>;
};

export default CalculationFieldRender;


// import React, { useState, useEffect } from 'react';

// interface CalculationFieldRenderProps {
//   field: any;
//   rowData: any;
//   columns: any[];
//   handleFieldChange: Function;
//   sectionId: string;
//   rowId: string;
// }

// const evaluateExpression = (expression: string, rowData: any, columns: any[]) => {
//   // Build a mapping from field names to dataIndex
//   const fieldNameToDataIndex: { [key: string]: string } = {};
//   columns.forEach((col: any) => {
//     fieldNameToDataIndex[col.name] = col.dataIndex;
//   });

//   // Replace placeholders in the expression with actual values from rowData
//   const parsedExpression = expression.replace(/\[(.*?)\]/g, (_, key) => {
//     const fieldName = key.trim();
//     const dataIndex = fieldNameToDataIndex[fieldName];
//     const value = rowData && dataIndex ? rowData[dataIndex] : 0;

//     if (typeof value === 'object' && value !== null) {
//       if ('uuid' in value) {
//         return value.uuid || 0;
//       } else if ('value' in value) {
//         return value.value || 0;
//       }
//     } else if (typeof value === 'number' || typeof value === 'string') {
//       return value || 0;
//     }
//     return 0; // Default to 0 if value is undefined
//   });

//   try {
//     const result = eval(parsedExpression);
//     return result;
//   } catch (error) {
//     console.error('Error evaluating expression:', error);
//     return '';
//   }
// };

// const CalculationFieldRender: React.FC<CalculationFieldRenderProps> = ({
//   field,
//   rowData,
//   columns,
//   handleFieldChange,
// }) => {
//   const [calculatedValue, setCalculatedValue] = useState<string | number>('');

//   useEffect(() => {
//     const result = evaluateExpression(field.fieldOptions.formula, rowData, columns || []);
//     setCalculatedValue(result);

//     // Only update if value has changed to prevent infinite loops
//     if (rowData && rowData[field.dataIndex] !== result) {
//       handleFieldChange(result); // Pass only the result
//     }
//   }, [rowData, columns, field.fieldOptions.formula]);

//   return <div>{calculatedValue}</div>;
// };

// export default CalculationFieldRender;