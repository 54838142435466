import React from 'react';
import SectionOrganizer from './SectionOrganizer';

const PageOrganizer = ({ sections, setSections, onFieldUpdate, onFieldDelete, onRenameSection = () => {}, pageType }) => {

  // console.log('calling page organizer')
  
  return (
    <div>
      {sections.map((section, index) => (
        <SectionOrganizer
          key={section.id + section.fields.length}
          section={section}
          onFieldUpdate={onFieldUpdate}
          onFieldDelete={onFieldDelete}
          onRenameSection={onRenameSection}
          pageType={pageType}
        />
      ))}
    </div>
  );
};

export default PageOrganizer;