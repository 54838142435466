import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Input, Button, Select, Table, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const NestedPageRender = ({ moduleData, existingRecord, setRecords, closeModal }) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [referenceData, setReferenceData] = useState({});
  const { tenantId, companyId, nestedStructureType } = moduleData;
  const API_URL = process.env.REACT_APP_API_URL;

  interface ModuleOptionType {
    label: string;
    value: string;
    moduleType: string;
  }

  useEffect(() => {
    if (existingRecord) {
      form.setFieldsValue(existingRecord);
      setTableData(existingRecord.tableData || []);
    }
  }, [existingRecord]);

  // const moduleId = moduleData._id

  const checkForCircularReference = async (selectedBOMId, currentBOMId) => {
    const visited = new Set();
  
    const traverseBOM = async (moduleId) => {
      if (visited.has(moduleId)) return true; // Cycle detected
      visited.add(moduleId);
  
      // Fetch BOM data
      try {
        const response = await axios.get(`${API_URL}/nested-data?moduleId=${moduleId}`);
        const bomRecords = response.data;
  
        for (const bom of bomRecords) {
          const subBOMIds = getSubBOMIdsFromTableData(bom.data.tableData);
          for (const subBOMId of subBOMIds) {
            if (subBOMId === currentBOMId) {
              return true; // Circular reference detected
            }
            const hasCycle = await traverseBOM(subBOMId);
            if (hasCycle) {
              return true;
            }
          }
        }
        return false;
      } catch (error) {
        console.error('Error fetching BOM data:', error);
        return false;
      }
    };
  
    return await traverseBOM(selectedBOMId);
  };
  
  const getSubBOMIdsFromTableData = (tableData) => {
    const subBOMIds = [];
    for (const row of tableData) {
      // Adjust according to your data structure
      for (const key in row) {
        if (key.startsWith('type_')) {
          subBOMIds.push(row[key]);
        }
      }
    }
    return subBOMIds;
  };

  const handleTypeChange = async (
    rowId,
    moduleId,
    dataIndex,
    selectedModuleType
  ) => {
    // console.log('Type changed, fetching records for module:', moduleId);
  
    const isSelfReferencing =
      nestedStructureType === 'self-referencing' || nestedStructureType === 'both';
  
    // For 'Self' references, check for circular references
    if (isSelfReferencing && selectedModuleType === 'Self') {
      const currentModuleId = moduleData._id;
      const hasCircularReference = await checkForCircularReference(
        moduleId,
        currentModuleId
      );
      if (moduleId === currentModuleId || hasCircularReference) {
        message.error(
          "You can't select this module or its sub-modules to avoid circular references."
        );
        return;
      }
    }
  
    // Update table data
    const updatedTableData = tableData.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          [`type_${dataIndex}`]: moduleId,
          [`record_${dataIndex}`]: '',
        };
      }
      return row;
    });
    setTableData(updatedTableData);
  
    // Fetch reference data
    if (!referenceData[moduleId]) {
      try {
        const endpoint =
          selectedModuleType === 'Self' ? 'nested-data' : 'reference-data';
        const response = await axios.get(
          `${API_URL}/${endpoint}?moduleId=${moduleId}`
        );
        const data = response.data.map((item) => ({
          ...item.data,
          _id: item._id,
        }));
        setReferenceData((prevData) => ({
          ...prevData,
          [moduleId]: data,
        }));
      } catch (error) {
        console.error('Error fetching reference data:', error);
      }
    }
  };

  const handleRecordChange = (rowId, value, dataIndex) => {
    // console.log('handling record change');
    const newData = tableData.map((row) => {
      if (row.id === rowId) {
        return { ...row, [`record_${dataIndex}`]: value };
      }
      return row;
    });
    setTableData(newData);
  };

  const handleTableCellChange = (rowId, dataIndex, value) => {
    // console.log('handling table cell change');
  
    if (!tableData || tableData.length === 0) {
      console.error('tableData is empty or undefined!');
      return;
    }
  
    const newData = tableData.map((row) => {
      if (row.id === rowId) {
        // console.log('Row found with matching ID:', row.id);
        return { ...row, [dataIndex]: value };
      }
      return row;
    });
  
    // console.log('New table data after update:', newData);
    setTableData(newData);
  };
  const addTableRow = () => {
    const newRow = { id: `row_${Date.now()}` };
  
    if (moduleData.tableData && moduleData.tableData.columns) {
      moduleData.tableData.columns.forEach((col) => {
        if (col.type === 'ReferenceLinkField') {
          newRow[`type_${col.dataIndex}`] = '';
          newRow[`record_${col.dataIndex}`] = '';
        } else {
          newRow[col.dataIndex] = '';
        }
      });
    }
  
    // console.log('Adding new row:', newRow);
    setTableData((prevTableData) => [...prevTableData, newRow]);
  };

  const removeTableRow = (rowId) => {
    // console.log('removing row')
    const newData = tableData.filter((row) => row.id !== rowId);
    setTableData(newData);
  };

  const transformTableData = async (tableData) => {
    const transformedData = await Promise.all(
      tableData.map(async (row) => {
        const transformedRow = { id: row.id };
  
        for (const key in row) {
          if (key === 'id') continue;
  
          if (key.startsWith('type_')) {
            const moduleId = row[key];
            const dataIndex = key.replace('type_', '');
  
            // Find the column definition
            const col = moduleData.tableData.columns.find(
              (c) => c.dataIndex === dataIndex
            );
  
            if (col && col.type === 'ReferenceLinkField') {
              const selectedModule = col.fieldOptions.modules.find(
                (mod) => mod.moduleId === moduleId
              );
  
              if (selectedModule) {
                transformedRow[`type_${dataIndex}`] = moduleId;
                transformedRow[`type_name_${dataIndex}`] =
                  selectedModule.label || selectedModule.moduleName;
              }
            }
          } else if (key.startsWith('record_')) {
            const recordId = row[key];
            const dataIndex = key.replace('record_', '');
            const moduleId = row[`type_${dataIndex}`];
  
            // Find the module and returnFields
            const col = moduleData.tableData.columns.find(
              (c) => c.dataIndex === dataIndex
            );
  
            if (col && col.type === 'ReferenceLinkField') {
              const selectedModule = col.fieldOptions.modules.find(
                (mod) => mod.moduleId === moduleId
              );
  
              if (selectedModule) {
                const returnFields = selectedModule.returnFields;
  
                // Fetch the record data
                const endpoint =
                  selectedModule.moduleType === 'Item' ? 'reference-data' : 'nested-data';
  
                // Fetch the specific record
                const response = await axios.get(
                  `${API_URL}/${endpoint}/${recordId}`
                );
  
                const recordData = response.data.data;
  
                // Extract the returnFields
                const returnFieldData = {};
                for (const field of returnFields) {
                  returnFieldData[field] = recordData[field];
                }
  
                transformedRow[`record_${dataIndex}`] = recordId;
                transformedRow[`record_data_${dataIndex}`] = returnFieldData;
              }
            }
          } else {
            // Handle other fields
            // Use the field name for human readability
            const col = moduleData.tableData.columns.find(
              (c) => c.dataIndex === key
            );
            const fieldName = col?.fieldOptions?.name || key;
            transformedRow[fieldName] = row[key];
          }
        }
  
        return transformedRow;
      })
    );
  
    return transformedData;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
  
      // Transform the tableData to include human-readable data
      const transformedTableData = await transformTableData(tableData);
  
      const record = {
        ...values,
        tableData: transformedTableData,
      };
  
      // console.log('Submitting record:', record);
  
      // Extract tenantId and companyId from moduleData
      const { tenantId, companyId } = moduleData;
  
      if (existingRecord) {
        await axios.put(
          `${API_URL}/nested-data/${existingRecord._id}`,
          {
            data: record,
            moduleId: moduleData._id,
            tenantId: tenantId,
            companyId: companyId,
          }
        );
      } else {
        await axios.post(`${API_URL}/nested-data`, {
          data: record,
          moduleId: moduleData._id,
          tenantId: tenantId,
          companyId: companyId,
        });
      }
  
      // Fetch updated records after saving
      const response = await axios.get(
        `${API_URL}/nested-data?moduleId=${moduleData._id}&tenantId=${tenantId}&companyId=${companyId}`
      );
      const updatedRecords = response.data.map((item) => ({
        ...item.data,
        _id: item._id,
      }));
      setRecords(updatedRecords);
  
      message.success('Record saved successfully');
      closeModal();
    } catch (error) {
      console.error('Error saving record:', error);
      message.error('Failed to save record');
    }
  };

  const tableColumns = useMemo(() => {
    if (moduleData.tableData && moduleData.tableData.columns) {
      return moduleData.tableData.columns
        .map((col) => {
          if (col.type === 'ReferenceLinkField') {
            const fieldOptions: ModuleOptionType[] = col.fieldOptions.modules.map(
              (mod) => ({
                label: mod.label || mod.moduleName,
                value: mod.moduleId,
                moduleType: mod.moduleType,
              })
            );
            return [
              {
                title: 'Type',
                dataIndex: `type_${col.dataIndex}`,
                key: `type_${col.dataIndex}`,
                render: (text, record) => (
                  <Select
                    value={record[`type_${col.dataIndex}`]}
                    onChange={(value, option) => {
                      const selectedOption = option as ModuleOptionType;
                      const selectedModuleType = selectedOption.moduleType;
                      handleTypeChange(
                        record.id,
                        value,
                        col.dataIndex,
                        selectedModuleType
                      );
                    }}
                    options={fieldOptions}
                  >
                    {col.fieldOptions.modules.map((mod) => (
                      <Option
                        key={mod.moduleId}
                        value={mod.moduleId}
                        moduleType={mod.moduleType}
                      >
                        {mod.label || mod.moduleName}
                      </Option>
                    ))}
                  </Select>
                ),
              },
              {
                title: 'Record',
                dataIndex: `record_${col.dataIndex}`,
                key: `record_${col.dataIndex}`,
                render: (text, record) => {
                  const moduleId = record[`type_${col.dataIndex}`];
                  const refData = referenceData[moduleId] || [];
                  // console.log('Record refData: ', refData);
  
                  return (
                    <Select
                      value={record[`record_${col.dataIndex}`]}
                      onChange={(value) => handleRecordChange(record.id, value, col.dataIndex)}
                      placeholder="Select Record"
                      style={{ width: '100%' }}
                    >
                      {refData.length === 0 && <Option disabled>No Records Found</Option>}
                      {refData.map((item) => (
                        <Option key={item._id} value={item._id}>
                          {item['Part Description'] || item.name}
                        </Option>
                      ))}
                    </Select>
                  );
                },
              },
            ];
          } else {
            return [
              {
                title: col.fieldOptions?.name || col.type,
                dataIndex: col.dataIndex,
                key: col.dataIndex,
                render: (text, record) => (
                  <Input
                    value={record[col.dataIndex]}
                    onChange={(e) =>
                      handleTableCellChange(
                        record.id,
                        col.dataIndex,
                        e.target.value
                      )
                    }
                  />
                ),
              },
            ];
          }
        })
        .flat();
    }
    return [];
  }, [moduleData, referenceData, tableData]);

  return (
    <Modal open={true} onCancel={closeModal} onOk={handleSubmit} width={800}>
      <Form form={form} layout="vertical">
        {moduleData.sections
          .find((section) => section.title === 'Headers')
          ?.fields.map((field) => (
            <Form.Item key={field.id} label={field.name || field.type} name={field.name}>
              <Input placeholder={field.name} />
            </Form.Item>
          ))}

        <Button type="dashed" onClick={addTableRow} style={{ marginBottom: 16 }}>
          Add Row
        </Button>
        <Table
          columns={[
            ...tableColumns,
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record) => (
                <Button type="link" onClick={() => removeTableRow(record.id)}>
                  Delete
                </Button>
              ),
            },
          ]}
          dataSource={tableData}
          pagination={false}
          rowKey={(record) => record.id}
        />
      </Form>
    </Modal>
  );
};

export default NestedPageRender;