import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface LineNumberConfigProps {
  value?: string;
  onChange?: (value: string) => void;
  fieldOptions: any;
}

const LineNumberConfig: React.FC<LineNumberConfigProps> = ({ value = '1, 2, 3, ...', onChange }) => {
    
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (newValue: string) => {
    setSelectedOption(newValue);
    if (onChange) onChange(newValue); // Notify parent component about the selection
  };

  return (
    <Select value={selectedOption} onChange={handleChange} style={{ width: '100%' }}>
      <Option value="1, 2, 3, ...">1, 2, 3, ...</Option>
      <Option value="10, 20, 30, ...">10, 20, 30, ...</Option>
      <Option value="100, 200, 300, ...">100, 200, 300, ...</Option>
      <Option value="001, 002, 003, ...">001, 002, 003, ...</Option>
    </Select>
  );
};

export default LineNumberConfig;