import React from 'react';
import { Select, Form } from 'antd';

interface ReferenceLinkFieldRenderProps {
  field: any;
  form?: any;
  referenceData: any;
  handleFieldChange: Function;
  renderType: 'table' | 'section';
  value?: any;
  rowId?: string;
  sectionId?: string;
  dataIndex?: string;
  required?: boolean
}

const ReferenceLinkFieldRender: React.FC<ReferenceLinkFieldRenderProps> = ({
  field,
  form,
  referenceData,
  handleFieldChange,
  renderType,
  value,
  required
}) => {
  if (!field || !referenceData) {
    console.warn("Missing field or referenceData in ReferenceLinkFieldRender.", field);
    return null;
  }

  const moduleOptions = field.fieldOptions?.modules || [];
  const moduleId = moduleOptions.length === 1 ? moduleOptions[0].moduleId : null;
  const refData = moduleId ? referenceData[moduleId] || [] : [];
  const selectFields = moduleOptions[0]?.selectFields || [];
  const returnFields = moduleOptions[0]?.returnFields || [];

  if (!moduleId || !refData) {
    console.warn(`Module or reference data missing for moduleId: ${moduleId}`);
    return null;
  }

  // Display value using selectFields
  const getDisplayValue = (item: any) => {
    return selectFields.map((field) => item[field] || '').join(' - ');
  };

  // Handle selection change in Select component
  const handleSelectChange = (selectedId: any) => {
    const selectedItem = refData.find((item: any) => item._id === selectedId);

    if (!selectedItem) {
      console.error('Selected item not found in reference data');
      return;
    }

    const selectedData = returnFields.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = selectedItem[fieldName] || null;
      return acc;
    }, {});

    selectedData.uuid = selectedId;
    selectedData.label = getDisplayValue(selectedItem);

    if (renderType === 'table') {
      handleFieldChange(selectedData);
    } else {
      form.setFieldsValue({ [field.name]: selectedData });
    }
  };

  // Selected value handling
  // Ensure selectedValue matches the value prop of the Option components (item._id)
  let selectedValue;
  if (value) {
    if (typeof value === 'object') {
      selectedValue = value.uuid || value._id;
    } else {
      selectedValue = value;
    }
  } else {
    selectedValue = undefined;
  }

  const selectComponent = (
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      placeholder="Select a record"
      style={{ width: '100%' }}
      optionFilterProp="children"
      showSearch
    >
      {refData.length === 0 ? (
        <Select.Option disabled>No Records Found</Select.Option>
      ) : (
        refData.map((item: any) => (
          <Select.Option key={item._id} value={item._id}>
            {getDisplayValue(item)}
          </Select.Option>
        ))
      )}
    </Select>
  );

  return renderType === 'table' ? (
    selectComponent
  ) : (
    <Form.Item name={field.name} label={field.name}>
      {selectComponent}
    </Form.Item>
  );
};

export default ReferenceLinkFieldRender;