import React from 'react';
import { Input } from 'antd';

interface ParagraphTextFieldProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const ParagraphTextField: React.FC<ParagraphTextFieldProps> = ({
  id,
  name,
  value,
  onChange,
}) => {
  return (
    <Input.TextArea
      id={id}
      name={name}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      placeholder="Enter paragraph text"
      // charLimit={100}
      style={{ width: '100%' }}
    />
  );
};

export const ParagraphTextFieldConfig: React.FC<{
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>Character Limit:</label>
        <Input
          type="number"
          min={1}
          value={fieldOptions.charLimit}
          placeholder='What is the character limit for this field? 100, 1000 are most common'
          onChange={(e) =>
            setFieldOptions({ ...fieldOptions, charLimit: parseInt(e.target.value) })
          }
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default ParagraphTextField;

