// ProcessComponentLibrary.tsx

import React from 'react';
import { Card, List } from 'antd';

interface ProcessItem {
  id: string;
  name: string;
}

interface LibraryProps {
  items: ProcessItem[];
  onDragStart: (item: ProcessItem) => void;
}

const Library: React.FC<LibraryProps> = ({ items, onDragStart }) => (
  <Card title="Library" style={{ marginBottom: '20px' }}>
    <List
      dataSource={items}
      renderItem={(item: ProcessItem) => (
        <List.Item>
          <div
            draggable
            onDragStart={() => onDragStart(item)}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '5px',
              cursor: 'move',
              background: '#f0f0f0',
            }}
          >
            {item.name}
          </div>
        </List.Item>
      )}
    />
  </Card>
);

// Define the actions, triggers, and conditions
const actions: ProcessItem[] = [
  { id: 'showModal', name: 'Show Modal' },
  { id: 'glInteraction', name: 'GL Interaction' }, // New action
];

const triggers: ProcessItem[] = [
  { id: 'clickButton', name: 'Click Button' },
  { id: 'whenStageIsMoved', name: 'When Stage is Moved' }, // New trigger
];

const conditions: ProcessItem[] = [{ id: 'textFieldEqualTo', name: 'Text Field Equal To' }];

// Individual libraries
export const ActionsLibrary: React.FC<{ onDragStart: (item: ProcessItem) => void }> = ({
  onDragStart,
}) => <Library items={actions} onDragStart={onDragStart} />;

export const TriggersLibrary: React.FC<{ onDragStart: (item: ProcessItem) => void }> = ({
  onDragStart,
}) => <Library items={triggers} onDragStart={onDragStart} />;

export const ConditionsLibrary: React.FC<{ onDragStart: (item: ProcessItem) => void }> = ({
  onDragStart,
}) => <Library items={conditions} onDragStart={onDragStart} />;