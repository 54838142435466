// SubFieldPalette.tsx
import React from 'react';
import { Button } from 'antd';

interface SubFieldPaletteProps {
  onAddSubField: (fieldType: string) => void;
  availableFields: string[];
}

const SubFieldPalette: React.FC<SubFieldPaletteProps> = ({ onAddSubField, availableFields }) => {
  return (
    <div>
      <h4>Add SubField</h4>
      {availableFields.map((fieldType) => (
        <Button key={fieldType} onClick={() => onAddSubField(fieldType)} style={{ margin: '5px' }}>
          {fieldType}
        </Button>
      ))}
    </div>
  );
};

export default SubFieldPalette;