import React from 'react';
import AutoIncrementID from './AutoIncrementIdRender';
import ReferenceLinkFieldRender from './ReferenceLinkFieldRender';
import TextFieldRender from './TextFieldRender';
import DateFieldRender from './DateFieldRender';
import NumberFieldRender from './NumberFieldRender';
import LineNumberRender from './LineNumberRender';
import CalculationFieldRender from './CalculationFieldRender';
import DropdownRender from './DropdownRender';
import AutoIncrementIDRender from './AutoIncrementIdRender';
import AggregateField from 'pages/Builder/Fields/AggregateField';
import AggregatedFieldDisplay from './AggregateFieldRender';
import AggregateFieldRender from './AggregateFieldRender';

export const renderField = (
  field: any,
  sectionId: string,
  form: any,
  referenceData: any,
  handleFieldChange: Function,
  moduleData: any,
  tableData: any,
  rowData?: any,
  columns?: any[],
  isEditing?: boolean,
  tenantID?: number,
  companyID?: number,
  moduleID?: string,
  itemId?: string, // Accept itemId as an optional parameter
  required?: boolean
) => {
  if (!field || !field.type) {
    console.error('Field is undefined or does not have a type:', field);
    return null;
  }

  const requiredFieldTypes = ['TextField', 'Dropdown', 'NumberField', 'ReferenceLinkField', 'PhoneNumber', 'EmailField']

  const key = field.id || field.name;

  switch (field.type) {
    case 'TextField':
      return (
        <TextFieldRender
          key={key}
          field={field}
          handleFieldChange={handleFieldChange}
          value={rowData ? rowData[field.dataIndex] : undefined}
          renderType={field.renderType}
          required={required}
        />
      );
    case 'DateField':
      return (
        <DateFieldRender
          key={key}
          field={field}
          form={form}
          handleFieldChange={rowData ? handleFieldChange : undefined}
          value={rowData ? rowData[field.dataIndex] : form.getFieldValue(field.name)}
          renderType={field.renderType || 'section'}
          required={required}
        />
      );
    case 'NumberField':
      return (
        <NumberFieldRender
          key={key}
          field={field}
          handleFieldChange={handleFieldChange}
          value={rowData ? rowData[field.dataIndex] : undefined}
          renderType={field.renderType}
          required={required}
        />
      );
    case 'Dropdown':
      return (
        <DropdownRender
          key={key}
          fieldId={field.name}
          name={field.name}
          fieldOptions={field.fieldOptions}
          required={required}
          // value={rowData ? rowData[field.dataIndex] : undefined} // Ensure this is a string
          onChange={(value: string) => handleFieldChange(value)} // Simplified handler
        />
      );
    case 'ReferenceLinkField':
      return (
        <ReferenceLinkFieldRender
          key={key}
          field={field}
          form={form}
          referenceData={referenceData}
          handleFieldChange={(value: any) => handleFieldChange(value)}
          renderType={field.renderType || 'section'}
          value={rowData ? rowData[field.dataIndex] : form.getFieldValue(field.name)}
          required={required}
        />
      );
    case 'AutoIncrementId':
      return (
        <AutoIncrementIDRender
          key={key}
          field={field}
          form={form}
          isEditing={isEditing || false}
        />
      );
    case 'LineNumber':
      return (
        <LineNumberRender
          key={key}
          field={field}
          rowIndex={field.rowIndex}
          handleFieldChange={handleFieldChange}
        />
      );
    case 'CalculationField':
      return (
        <CalculationFieldRender
          key={key}
          field={field}
          rowData={rowData}
          columns={columns}
          handleFieldChange={handleFieldChange}
          sectionId={sectionId}
          rowId={rowData.id}
        />
      );
    case 'AggregateField':
      // const itemId = rowData ? rowData['uuid'] : ''; // Use a specific field to fetch itemId
      return (
        <AggregateFieldRender
          key={key}
          tenantId={tenantID}
          companyId={companyID}
          moduleId={moduleID}
          fieldId={field.id}
          itemId={itemId}
          name={field.name}
          // aggModuleId={aggModuleId}
        />
      );
    default:
      return null;
  }
};