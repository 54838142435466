import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { CredentialResponse } from '@react-oauth/google';
import  '../styles/loginPage.css'
const API_URL = process.env.REACT_APP_API_URL

const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await fetch(`${API_URL}/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, firstName }),
      });
      const data = await res.json();
      if (res.ok) {
        localStorage.setItem('token', data.access_token); // Save token
        navigate('/quickbuild-home'); // Redirect to Quickbuild home
      } else {
        setError(data.message || 'Error signing up');
      }
    } catch (err) {
      console.error('Signup error:', err);
      setError('Something went wrong');
    }
  };
  
  const handleGoogleSuccess = async (credentialResponse: CredentialResponse) => {
    try {
      const token = credentialResponse.credential;
      if (token) {
        // Send the token to your backend
        const res = await fetch(`${API_URL}/auth/google`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });
        const data = await res.json();
        if (res.ok) {
          localStorage.setItem('token', data.access_token); // Save JWT token
          navigate('/quickbuild-home'); // Redirect to Quickbuild home
        } else {
          setError(data.message || 'Google authentication failed');
        }
      }
    } catch (err) {
      console.error('Google login error:', err);
      setError('Google authentication failed');
    }
  };

  const handleGoogleFailure = () => {
    setError('Google authentication was unsuccessful. Try again later.');
  };

  return (
    <div className='login-container'> 
      <img
        src={require('../images/quickbuild-main.png')}
        alt="QuickBuild Logo"
        className="login-logo"
      />
      <h1 className='login-title'>Sign Up</h1>
      <form onSubmit={handleSignUp} className='login-form'>
      <div className='form-group'>
          <label>First Name</label>
          <input
            type="string"
            value={firstName}
            className='form-input'
            onChange={(e) => {
              setFirstName(e.target.value);
              if (error) setError('');
            }}
            required
          />
        </div>
        <div className='form-group'>
          <label>Email</label>
          <input
            type="email"
            value={email}
            className='form-input'
            onChange={(e) => {
              setEmail(e.target.value);
              if (error) setError('');
            }}
            required
          />
        </div>
        <div className='form-group'>
          <label>Password</label>
          <input
            type="password"
            value={password}
            className='form-input'
            onChange={(e) => {
              setPassword(e.target.value);
              if (error) setError('');
            }}
            required
          />
        </div>
        <button
          type="submit"
          className='signup-button'
        >
          Sign Up
        </button>

        {/* Display error message if there is an error */}
        {error && (
          <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
        )}
      </form>

      <p>
        Already have an account?{' '}
        <Link to="/login" style={{ color: '#007BFF' }}>
          Login
        </Link>
      </p>
    {/* OR Sign in/up with Google */}
    <p>OR</p>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
      />
    </GoogleOAuthProvider>
    <p></p>
    <p>By signing up, you agree to our extremely long winded <a href="https://www.quickbuildnow.com/terms-of-service" target="_blank" rel="noopener noreferrer"> Terms of Service</a>
    </p>
  </div>
  );
};

export default SignUpPage;