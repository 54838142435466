import React, { useEffect } from 'react';

interface LineNumberRenderProps {
  field: any;
  rowIndex: number;  // The index of the current row
  handleFieldChange: Function;
}

const getLineNumber = (format: string, rowIndex: number): string => {
  switch (format) {
    case '1, 2, 3, ...':
      return `${rowIndex + 1}`;
    case '10, 20, 30, ...':
      return `${(rowIndex + 1) * 10}`;
    case '100, 200, 300, ...':
      return `${(rowIndex + 1) * 100}`;
    case '001, 002, 003, ...': {
      const nextValue = rowIndex + 1;
      return nextValue.toString().padStart(3, '0'); // Pads with zeros to 3 digits
    }
    default:
      return `${rowIndex + 1}`; // Default case
  }
};

const LineNumberRender: React.FC<LineNumberRenderProps> = ({ field, rowIndex, handleFieldChange }) => {
  const lineNumberFormat = field?.fieldOptions?.lineNumberFormat || '1, 2, 3, ...';
  const lineNumber = getLineNumber(lineNumberFormat, rowIndex);

  useEffect(() => {
    // Update the line number in the data
    handleFieldChange(lineNumber);
  }, [lineNumber]);

  return (
    <div>
      {lineNumber}
    </div>
  );
};

export default LineNumberRender;