// ProcessBuilder.tsx

import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import { ActionsLibrary, TriggersLibrary, ConditionsLibrary } from './ProcessComponentLibrary';
// import axios from 'axios';
import GLActionsConfigurator from './GLActionsConfigurator'; // Adjust path accordingly

const { Option } = Select;

interface ProcessBuilderProps {
  onSaveFunction: (newFunction: any) => void;
  existingFunction?: any;
  stages?: any[]; // Receive stages as a prop
  fields?: any[]; // Receive fields as a prop
}

// interface GLAction {
//   account: string;
//   field: string;
//   type: 'Debit' | 'Credit';
// }

// interface GLActionsConfiguratorProps {
//   glActions: GLAction[];
//   onChange: (glActions: GLAction[]) => void;
//   fields?: any[]; // Add fields as a prop
// }

const ProcessBuilder: React.FC<ProcessBuilderProps> = ({ onSaveFunction, existingFunction, stages = [], fields = [] }) => {
  const [draggedItem, setDraggedItem] = useState<any | null>(null);
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [customData, setCustomData] = useState<any>({});
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null);
  const [flowName, setFlowName] = useState('');
  const [processFlow, setProcessFlow] = useState<any[]>(existingFunction?.steps || []);

  useEffect(() => {
    if (existingFunction) {
      setFlowName(existingFunction.name);
      setProcessFlow(existingFunction.steps || []);
    }
  }, [existingFunction]);

  const handleDragStart = (item: any) => {
    setDraggedItem(item);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (draggedItem) {
      setProcessFlow((prevFlow) => {
        const updatedFlow = [...prevFlow, { ...draggedItem, customData: {} }];
        setCurrentStepIndex(updatedFlow.length - 1);
        return updatedFlow;
      });
      setIsCustomModalVisible(true); // Show modal for customization
      setDraggedItem(null);
    }
  };

  const handleSaveCustomization = () => {
    const updatedFlow = [...processFlow];
    updatedFlow[currentStepIndex] = {
      ...updatedFlow[currentStepIndex],
      customData,
    };
    setProcessFlow(updatedFlow);
    setIsCustomModalVisible(false);
    setCustomData({});
  };

  const handleSaveFlow = () => {
    if (processFlow.length === 0) {
      alert('No process flow to save');
      return;
    }

    // Create the function schema with steps
    const newFunction = {
      functionId: existingFunction?.functionId || Date.now(), // Use existing functionId if editing
      name: flowName || 'Unnamed Function', // Function name
      steps: processFlow.map((step, index) => {
        let stepType = 'action';
        if (step.id.startsWith('clickButton') || step.id.startsWith('whenStageIsMoved')) {
          stepType = 'trigger';
        } else if (step.id.startsWith('textFieldEqualTo')) {
          stepType = 'condition';
        }

        return {
          stepId: index + 1, // Unique ID for each step
          type: stepType, // Assign the correct type: 'trigger', 'condition', or 'action'
          typeDetails: {
            triggerType: step.customData?.triggerType || 'N/A',
            conditionField: step.customData?.fieldName || 'N/A',
            conditionValue: step.customData?.valueToMatch || 'N/A',
            actionType: step.customData?.actionType || 'N/A',
            buttonName: step.customData?.buttonName || 'N/A',
            modalMessage: step.customData?.modalMessage || 'N/A',
            glActions: step.customData?.glActions || [], // For GL interactions
            fromStage: step.customData?.fromStage || 'N/A', // For 'Stage moved from'
            toStage: step.customData?.toStage || 'N/A', // For 'Stage moved to'
            skipStageProcessing: step.customData?.skipStageProcessing || false, // For skip stage processing option
          },
        };
      }),
    };

    onSaveFunction(newFunction); // Call the save function to pass the newFunction object
  };

  function renderCustomizationModalContent() {
    const currentStep = processFlow[currentStepIndex];
    if (!currentStep) return null;

    if (currentStep.id === 'glInteraction') {
      return (
        <>
          <h4>Configure GL Actions</h4>
          <GLActionsConfigurator
            glActions={customData.glActions || []}
            onChange={(glActions) => setCustomData({ ...customData, glActions })}
            fields={fields} // Pass fields here
          />
        </>
      );
    }

    if (currentStep.id === 'clickButton') {
      return (
        <Input
          placeholder="Enter button name"
          value={customData.buttonName}
          onChange={(e) =>
            setCustomData({
              ...customData,
              buttonName: e.target.value,
              triggerType: 'button',
            })
          }
        />
      );
    }

    if (currentStep.id === 'whenStageIsMoved') {
      return (
        <>
          <Select
            placeholder="From Stage"
            value={customData.fromStage}
            onChange={(value) =>
              setCustomData({
                ...customData,
                fromStage: value,
                triggerType: 'stageMoved',
              })
            }
            style={{ width: '100%', marginBottom: '10px' }}
          >
            {stages.map((stage, index) => (
              <Option key={index} value={stage}>
                {stage}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="To Stage"
            value={customData.toStage}
            onChange={(value) =>
              setCustomData({
                ...customData,
                toStage: value,
                triggerType: 'stageMoved',
              })
            }
            style={{ width: '100%', marginBottom: '10px' }}
          >
            {stages.map((stage, index) => (
              <Option key={index} value={stage}>
                {stage}
              </Option>
            ))}
          </Select>
          <h4>Process even if stage is skipped?</h4>
          <Input
            type="checkbox"
            checked={customData.skipStageProcessing}
            onChange={(e) =>
              setCustomData({
                ...customData,
                skipStageProcessing: e.target.checked,
              })
            }
          />
        </>
      );
    }

    if (currentStep.id === 'textFieldEqualTo') {
      return (
        <>
          <Input
            placeholder="Enter field name"
            value={customData.fieldName}
            onChange={(e) => setCustomData({ ...customData, fieldName: e.target.value })}
          />
          <Input
            placeholder="Enter value to match"
            value={customData.valueToMatch}
            onChange={(e) => setCustomData({ ...customData, valueToMatch: e.target.value })}
          />
        </>
      );
    }

    if (currentStep.id === 'showModal') {
      return (
        <Input
          placeholder="Enter modal message"
          value={customData.modalMessage}
          onChange={(e) =>
            setCustomData({
              ...customData,
              modalMessage: e.target.value,
              actionType: 'showMessage',
            })
          }
        />
      );
    }

    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        value={flowName}
        onChange={(e) => setFlowName(e.target.value)} // Capture the flow name here
        placeholder="Enter Flow Name"
        style={{ marginBottom: '10px' }}
      />

      <Button
        type="primary"
        onClick={handleSaveFlow}
        style={{ marginLeft: '20px', alignSelf: 'flex-start' }}
      >
        Save Flow
      </Button>

      <div style={{ display: 'flex', marginTop: '20px' }}>
        {/* Left Side: Libraries */}
        <div style={{ width: '300px', paddingRight: '20px' }}>
          <h3>Triggers</h3>
          <TriggersLibrary onDragStart={handleDragStart} />

          <h3>Conditions</h3>
          <ConditionsLibrary onDragStart={handleDragStart} />

          <h3>Actions</h3>
          <ActionsLibrary onDragStart={handleDragStart} />
        </div>

        {/* Right Side: Process Flow */}
        <div
          style={{
            flexGrow: 1,
            border: '1px solid #ccc',
            minHeight: '300px',
            padding: '20px',
            background: '#f9f9f9',
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <h3>Process Flow</h3>
          {processFlow.length > 0 ? (
            processFlow.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: '10px',
                  background: '#e0e0e0',
                  marginBottom: '10px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                {item.name} {item.customData ? `(${JSON.stringify(item.customData)})` : ''}
                <Button
                  type="link"
                  onClick={() => {
                    setCurrentStepIndex(index);
                    setCustomData(item.customData || {});
                    setIsCustomModalVisible(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type="link"
                  danger
                  onClick={() => setProcessFlow((prevFlow) => prevFlow.filter((_, i) => i !== index))}
                >
                  Delete
                </Button>
              </div>
            ))
          ) : (
            <p>Drag triggers, conditions, and actions here</p>
          )}
        </div>

        {/* Modal for customizing the step */}
        <Modal
          title="Customize Step"
          open={isCustomModalVisible}
          onCancel={() => {
            setIsCustomModalVisible(false);
            // Remove the last step if customization is cancelled
            setProcessFlow((prevFlow) => prevFlow.slice(0, -1));
          }}
          onOk={handleSaveCustomization}
        >
          {renderCustomizationModalContent()}
        </Modal>
      </div>
    </div>
  );
};

export default ProcessBuilder;