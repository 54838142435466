import React, { useEffect, useState } from 'react';
import { useTenant } from '../../contexts/TenantContext'
import { Select, Spin, Alert, Button, Modal, Form, Input, Tree, Layout } from 'antd';
import axios from 'axios';

const { Option } = Select;
const { Content } = Layout;


const ChartOfAccountsPage: React.FC = () => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState<any>(null);
  const [form] = Form.useForm();  // Initialize Ant Design form
  const {tenantId, companyId } = useTenant();

  const API_URL = process.env.REACT_APP_API_URL;
  // Fetch chart of accounts based on companyId
  useEffect(() => {
    const fetchAccounts = async () => {
      if (!companyId) return;

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/chart-of-accounts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { companyId },
        });

        const formattedData = formatAccountsToTreeData(response.data);
        // console.log('Formatted Tree Data: ', formattedData);
        setTreeData(formattedData);
        // setAccounts(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching accounts:', err);
        setError('Failed to fetch chart of accounts.');
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [companyId]);

  // Format accounts to tree data
  const formatAccountsToTreeData = (accounts: any[]) => {
    const map = new Map();
    const tree: any[] = [];

    // console.log('Raw Accounts data: ', accounts);

    accounts.forEach((account) => {
      map.set(account.accountId, {
        ...account,
        title: `${account.accountNumber}: ${account.accountName} (Balance: ${account.balance})`,
        key: account.accountId,
        children: [],
      });
    });

    accounts.forEach((account) => {
      const node = map.get(account.accountId);
      if (account.parentAccountId) {
        const parent = map.get(account.parentAccountId);
        if (parent) {
          parent.children.push(node);
        }
      } else {
        tree.push(node); // Top-level nodes
      }
    });

    return tree; // Return the formatted tree structure
  };

  // const handleEditAccount = (node) => {
  //   // const { accountNumber, accountName, accountType, balance, parentAccountId } = node
  //   // console.log('Editing account:', node)
  //   setAccountToEdit(node);  // Populate form with selected account data
  //   // console.log('Step 2')
  //   form.setFieldsValue({
  //     accountNumber: node.accountNumber,
  //     accountName: node.accountName,
  //     accountType: node.accountType,
  //     balance: Number(node.balance),
  //     parentAccountId: Number(node.parentAccountId),
  //   });
  //   console.log('Step 3')
  //   setIsModalVisible(true);
  // };

  const handleSaveAccount = async () => {
    try {
      const values = await form.validateFields();
      // console.log('Form Values:', values);
  
      // Ensure balance and parentAccountId are numbers
      values.balance = Number(values.balance);
      if (values.parentAccountId) {
        values.parentAccountId = Number(values.parentAccountId);
      }
  
      // Include companyId and tenantId
      values.companyId = Number(companyId);
      values.tenantId = Number(tenantId);
  
  
      if (accountToEdit) {
        const payload = { 
          ...values, 
        };
  
        // Call the API to update the account
        console.log('Editing account with payload:', payload);
        
      } else {
        // Call API to create a new account (if creating)
        // console.log('Creating new account', values);
        // Implement POST request to create account

      }
  
      setIsModalVisible(false);  // Close modal after save
      form.resetFields();  // Reset form
    } catch (err) {
      console.error('Form validation error or API error:', err);
      if (err.response) {
        console.error('Backend error: ', err.response.data)
      }
    }
  };


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '20px' }}>
        <h1>Chart of Accounts</h1>
        {loading ? (
          <Spin tip="Loading..." style={{ display: 'block', textAlign: 'center' }}/>
        ) : error ? (
          <Alert message="Error" description={error} type="error" showIcon />
        ) : (
          <>
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              Create New Account
            </Button>
            <Tree
              treeData={treeData}
              onSelect={(selectedKeys, { node }) => {
                setAccountToEdit(node);
                form.setFieldsValue(node);
                setIsModalVisible(true);
              }}
            />
            <Modal
              title={accountToEdit ? 'Edit Account' : 'Create New Account'}
              open={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              onOk={handleSaveAccount}
            >
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  rules={[{ required: true, message: 'Please enter the account number' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Account Name"
                  name="accountName"
                  rules={[{ required: true, message: 'Please enter the account name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Account Type"
                  name="accountType"
                  rules={[{ required: true, message: 'Please select the account type' }]}
                >
                  <Select>
                    <Option value="Category">Category</Option>
                    <Option value="Subcategory">Subcategory</Option>
                    <Option value="Posting">Posting</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Balance"
                  name="balance"
                  rules={[{ required: true, message: 'Please enter the balance' }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </Content>
    </Layout>
  );
};

export default ChartOfAccountsPage;