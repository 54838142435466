import React, { useState, useEffect } from 'react';
import { useTenant } from '../../contexts/TenantContext';
import { Button, Table, Modal, Input, notification, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const BuilderHomePage = () => {
  const { tenantId, companyId } = useTenant(); 
  const [modules, setModules] = useState([]);  // Ensure modules is always an array
  const navigate = useNavigate(); // Import and use useNavigate correctly
  const [selectedModuleType, setSelectedModuleType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');
  const [moduleToCopy, setModuleToCopy] = useState(null); // The module we're copying
  const API_URL = process.env.REACT_APP_API_URL;
  const { user } = useUser();

  const userPermissions = (() => {
    if (user?.roles.includes('quickbuildadmin')) {
      return { canViewFunctions: true };
    }
    return { canViewFunctions: false };
  })();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(
          `${API_URL}/quickbuild-prod/page-modules/modules?tenantId=${tenantId}&companyId=${companyId}`)
        const data = await response.json();
          // console.log('response data: ', data)
        // Ensure data is an array before setting it
        if (Array.isArray(data)) {
          setModules(data);
        } else {
          setModules([]);  // Fallback to empty array if data isn't an array
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
        setModules([]);  // Handle the error case by setting modules to empty array
      }
    };
  
    if (tenantId && companyId) {
      fetchModules();
    }
  }, [tenantId, companyId]);

  const columns = [
    {
      title: 'Module Name',
      dataIndex: 'moduleName',
      key: 'moduleName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Page Type',
      dataIndex: 'pageType',
      key: 'pageType',
      render: (text, record) => {
        // You can decide how to map the "pageType" based on your module structure.
        return record.pageType || 'Unknown';  // Directly accessing the top-level field pageType
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
          <>
          <button onClick={() => handleEdit(record._id)}>Edit</button>
          <Button type="link" onClick={() => handleDelete(record._id)}>Delete</Button>
          <Button type="link" onClick={() => handleCopyClick(record)}>Copy</Button> {/* Trigger the Copy modal */}
          <Button type="link" onClick={() => handlePublish(record._id)}>Publish</Button>
        </>
      )
    }
  ];


const handleEdit = async (moduleId) => {
  try {
    // console.log('trying edit')
    const response = await fetch(`${API_URL}/quickbuild-prod/page-modules/${moduleId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log('Module data:', data);
      
      // Navigate to -page-builder based on page Type and pass the fetched module data
      const pageType  = data.pageType.toLowerCase();
      const url = "/builder/" + pageType + "-page-builder"

      navigate(url, { state: { module: data } });
      // console.log(data)
    } else {
      console.error('Failed to fetch module.');
    }
  } catch (error) {
    console.error('Error fetching module:', error);
  }
};

const handleDelete = async (moduleId) => {
  const confirmed = window.confirm("Are you sure you want to delete this module?");
  if (!confirmed) return;

  try {
    const response = await fetch(`${API_URL}/quickbuild-prod/page-modules/${moduleId}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      setModules(modules.filter(module => module._id !== moduleId));  // Remove the module from the state
      console.log("Module deleted successfully");
    } else {
      console.error("Failed to delete module.");
    }
  } catch (error) {
    console.error("Error deleting module:", error);
  }
};

const handleCopyClick = (module) => {
  setModuleToCopy(module); // Set the module to copy
  setNewModuleName(''); // Reset the module name input
  setIsCopyModalVisible(true); // Open the modal
};

// Handle copy module logic when the user confirms the modal
const handleCopyModule = async () => {
  if (!newModuleName.trim()) {
    alert("Please enter a new module name.");
    return;
  }


  try {
    const response = await fetch(`${API_URL}/quickbuild-prod/page-modules/copy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        // tenantId,
        // companyId,
        moduleId: moduleToCopy._id, // Pass the full module to copy
        newModuleName, // Pass the new module name
      }),
    });

    // console.log(response.body)

    if (response.ok) {
      const newModule = await response.json();
      // console.log('response ok')
      setModules([...modules, newModule]); // Add the copied module to the module list
      setIsCopyModalVisible(false); // Close the modal
      console.log("Module copied successfully");
    } else {
      console.error("Failed to copy module.");
    }
  } catch (error) {
    console.error("Error copying module:", error);
  }
};

const handlePublish = async (moduleId) => {

  try {
    //Fetch current count
    const response = await fetch(`${API_URL}/quickbuild-prod/page-modules/modules?tenantId=${tenantId}&companyId=${companyId}&status=published`);
    const publishedModules = await response.json();

    const PUBLISHED_LIMIT = 30 //process.env.REACT_APP_FREE_TRIAL_PAGE_LIMIT; // Set the limit


    if (publishedModules.length >= PUBLISHED_LIMIT) {
      notification.warning({
        message: 'Publish Limit Reached',
        description: `Free Trial has a limit of ${PUBLISHED_LIMIT} published pages. Please upgrade to publish more.`,
        placement: 'top',
      });
      return; // Exit the function if the limit is reached
    }

    const publishresponse = await fetch(`${API_URL}/quickbuild-prod/page-modules/publish/${moduleId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: 'published' }),
    });


    if (publishresponse.ok) {
      const updatedModule = await publishresponse.json();
      setModules(modules.map(module => module._id === moduleId ? updatedModule : module));  // Update the module's status

      console.log("Module published successfully");
      notification.success({
        message: 'Page Published',
        description: 'Refresh the page to view in side menu',
        placement: 'top',
        duration: 3
      })

    } else {
      console.error("Failed to publish module.");
    }
  } catch (error) {
    console.error("Error publishing module:", error);
  }
};


// Open the modal when the user clicks "New"
const handleNewModuleClick = () => {
  setIsModalVisible(true); // Show the modal
};

// Handle modal option selection
const handleModalOk = () => {
  if (selectedModuleType === 'Reference') {
    navigate('/builder/reference-page-builder'); // Navigate if Reference is selected
  }
  if (selectedModuleType === 'Transaction') {
    navigate('/builder/transaction-page-builder'); // Navigate if Reference is selected
  }
  if (selectedModuleType === 'Nested') {
    navigate('/builder/nested-page-builder'); // Navigate if Reference is selected
  }
  // Add logic for Transaction and Nested here if needed
  setIsModalVisible(false); // Close the modal after selection
};

const handleModalCancel = () => {
  setIsModalVisible(false); // Just close the modal on cancel
};

  return (
    <div>
      <h1>Page Library</h1>
      <Table dataSource={modules} columns={columns} rowKey="_id" />
      <Button type="primary" onClick={handleNewModuleClick}>
        + New
      </Button>

      {/* Modal to ask for module type */}
      <Modal
        title="What kind of page experience do you want?"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Button
          type={selectedModuleType === 'Reference' ? 'primary' : 'default'}
          onClick={() => setSelectedModuleType('Reference')}
          style={{ marginBottom: '10px', width: '100%' }}
        >
          Reference
          (Customers, Vendors, Item)
        </Button>
        <Button
          type={selectedModuleType === 'Transaction' ? 'primary' : 'default'}
          onClick={() => setSelectedModuleType('Transaction')}
          style={{ marginBottom: '10px', width: '100%' }}
        >
          Transaction
          (Sales, Purchasing, Production)
        </Button>
        {userPermissions.canViewFunctions && (
        <Button
          type={selectedModuleType === 'Nested' ? 'primary' : 'default'}
          onClick={() => setSelectedModuleType('Nested')}
          style={{ marginBottom: '10px', width: '100%' }}
        >
          Nested
          (BOM's, Routings)
        </Button>
        )}
      </Modal>

      {/* Copy Module Modal */}
            <Modal
        title="Copy Module"
        open={isCopyModalVisible}
        onOk={handleCopyModule}  // Handle copying logic
        onCancel={() => setIsCopyModalVisible(false)}  // Close the modal
      >
        <Input
          value={newModuleName}
          onChange={(e) => setNewModuleName(e.target.value)}
          placeholder="Enter new module name"
        />
      </Modal>
    </div>
  );
};

export default BuilderHomePage;