// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import QBHome from './pages/QBHome';
import ChartOfAccountsPage from './pages/Accounting/ChartOfAccounts';
import AccountForm from './pages/Accounting/AccountForm';
import TenantLayout from './layouts/TenantLayout';
// import AccountingSettings from './pages/Accounting/AccountingSettings';
import GeneralLedgerPage from './pages/Accounting/GeneralLedgerPage';
import ReferencePageBuilder from './pages/Builder/ReferencePage/ReferencePageBuilder';
import BuilderHomePage from './pages/Builder/BuilderHome';
import TransactionPageBuilder from './pages/Builder/TransactionPage/TransactionPageBuilder';
import NestedPageBuilder from './pages/Builder/NestedPage/NestedPageBuilder';
import BasePageRender from './pages/Render/BasePageRender';
import ProtectedRoute from './components/ProjectedRoute'
import Unauthorized from './pages/Unauthorized'; // Create this page
// import { AuthProvider } from './contexts/AuthProviderContext'



const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />

        {/* Unauthorized Access Route */}
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Protected Routes wrapped in TenantLayout */}
        <Route element={<TenantLayout />}>
          {/* QuickBuildAdmin-only Routes */}
          <Route element={<ProtectedRoute allowedRoles={['quickbuildadmin', 'freetrial', 'builder']} />}>
            <Route path="/builder" element={<BuilderHomePage />} />
            <Route path="/builder/reference-page-builder" element={<ReferencePageBuilder />} />
            <Route path="/builder/transaction-page-builder" element={<TransactionPageBuilder />} />
            <Route path="/builder/nested-page-builder" element={<NestedPageBuilder />} />
          </Route>

          {/* User and QuickBuildAdmin Routes */}
          <Route element={<ProtectedRoute allowedRoles={['quickbuildadmin', 'user', 'freetrial']} />}>
            <Route path="/quickbuild-home" element={<QBHome />} />
            <Route path="/chart-of-accounts" element={<ChartOfAccountsPage />} />
            <Route path="/create-account" element={<AccountForm />} />
            <Route path="/general-ledger" element={<GeneralLedgerPage />} />
            <Route path="/module/:moduleId/:moduleType" element={<BasePageRender />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;