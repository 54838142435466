import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface DateFieldConfigProps {
  value?: string;
  onChange?: (value: string) => void;
  fieldOptions: any;
}

const DateFieldConfig: React.FC<DateFieldConfigProps> = ({ value = 'YYYY-MM-DD', onChange }) => {
  const [selectedOption,  setSelectedOption] = useState(null);
  
  const handleChange = (newValue: string) => {
    setSelectedOption(newValue)
    if (onChange) onChange(newValue);
  };

  return (
    <Select value={selectedOption} onChange={handleChange} style={{ width: '100%' }}>
      <Option value="YYYY-MM-DD">YYYY-MM-DD</Option>
      <Option value="MM-DD-YYYY">MM-DD-YYYY</Option>
      <Option value="DD-MM-YYYY">DD-MM-YYYY</Option>
    </Select>
  );
};

export default DateFieldConfig;