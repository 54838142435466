import React, { useState, useEffect } from 'react';
import { Input, Checkbox, List, Select } from 'antd';
import axios from 'axios';

const { Option } = Select;
const API_URL = process.env.REACT_APP_API_URL;

export const ReferenceLinkFieldConfig = ({
  fieldOptions,
  setFieldOptions,
  tenantId,
  companyId,
  currentModuleId,
  currentModuleName,
}) => {
  const [activeModules, setActiveModules] = useState([]);
  const [loadingModules, setLoadingModules] = useState(true);
  const [modulesError, setModulesError] = useState(null);
  
  // Set initial values based on fieldOptions
  const [selectedModuleIds, setSelectedModuleIds] = useState(
    fieldOptions.modules?.map((mod) => mod.moduleId) || []
  );
  const [selectedModules, setSelectedModules] = useState(fieldOptions.modules || []);

  // Fetch active modules from the backend
  useEffect(() => {
    const fetchActiveModules = async () => {
      const statuses = ['published', 'draft'].join(','); // fetch published and draft modules
      try {
        const response = await fetch(
          `${API_URL}/quickbuild-prod/page-modules/modules?tenantId=${tenantId}&companyId=${companyId}&status=${statuses}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch modules');
        }
        const data = await response.json();
  
        let filteredModules = data.filter((module) => module.pageType === 'Reference');
  
        if (currentModuleId && currentModuleName) {
          const currentModule = {
            _id: currentModuleId,
            moduleName: currentModuleName,
            pageType: 'Nested',
          };
          const isCurrentModuleIncluded = filteredModules.some(
            (mod) => mod._id === currentModuleId
          );
          if (!isCurrentModuleIncluded) {
            filteredModules = [...filteredModules, currentModule];
          }
        }
  
        setActiveModules(filteredModules);
        setLoadingModules(false);
      } catch (error) {
        console.error('Error fetching active modules:', error);
        setModulesError(error);
        setLoadingModules(false);
      }
    };

    fetchActiveModules();
  }, [tenantId, companyId, currentModuleId, currentModuleName]);

  useEffect(() => {
    if (fieldOptions.modules) {
      setSelectedModules(fieldOptions.modules);
      setSelectedModuleIds(fieldOptions.modules.map((mod) => mod.moduleId));
    }
  }, [fieldOptions.modules]);

  const onModuleSelectionChange = async (newSelectedModuleIds) => {
    setSelectedModuleIds(newSelectedModuleIds);

    const modulesWithTypes = await Promise.all(
      newSelectedModuleIds.map(async (moduleId) => {
        try {
          const response = await axios.get(
            `${API_URL}/quickbuild-prod/page-modules/${moduleId}`
          );
          const moduleData = response.data;

          return {
            moduleId: moduleData._id,
            moduleName: moduleData.moduleName,
            fields: moduleData.sections.flatMap((section) => section.fields),
            label: moduleData.moduleName,
            selectFields: [],
            returnFields: [],
          };
        } catch (error) {
          console.error('Error fetching module data:', error);
          return null;
        }
      })
    );

    const validModules = modulesWithTypes.filter((mod) => mod !== null);
    setSelectedModules(validModules);
    setFieldOptions((prevFieldOptions) => ({
      ...prevFieldOptions,
      modules: validModules,
    }));
  };

  const handleModuleConfigChange = (moduleId, key, value) => {
    setSelectedModules((prevSelectedModules) => {
      const updatedModules = prevSelectedModules.map((mod) =>
        mod.moduleId === moduleId ? { ...mod, [key]: value } : mod
      );

      setFieldOptions((prevFieldOptions) => ({
        ...prevFieldOptions,
        modules: updatedModules,
      }));

      return updatedModules;
    });
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>Select Reference Modules:</label>
        {loadingModules ? (
          <p>Loading modules...</p>
        ) : modulesError ? (
          <p>Error loading modules.</p>
        ) : (
          <Checkbox.Group
            style={{ width: '100%' }}
            value={selectedModuleIds}
            onChange={onModuleSelectionChange}
          >
            <List
              dataSource={activeModules}
              renderItem={(module) => (
                <List.Item>
                  <Checkbox value={module._id}>{module.moduleName}</Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        )}
      </div>

      {selectedModules.map((mod) => (
        <div
          key={mod.moduleId}
          style={{
            marginBottom: '20px',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <h4>{mod.moduleName} Configuration</h4>
          <div style={{ marginBottom: '10px' }}>
            <label>Type Label:</label>
            <Input
              value={mod.label}
              onChange={(e) => handleModuleConfigChange(mod.moduleId, 'label', e.target.value)}
              placeholder="Type Label"
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Select Fields:</label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={mod.selectFields}
              onChange={(values) => handleModuleConfigChange(mod.moduleId, 'selectFields', values)}
              placeholder="Select fields"
            >
              {mod.fields &&
                mod.fields.map((field) => (
                  <Option key={field.id} value={field.name}>
                    {field.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Return Fields:</label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={mod.returnFields}
              onChange={(values) => handleModuleConfigChange(mod.moduleId, 'returnFields', values)}
              placeholder="Return fields"
            >
              {mod.fields &&
                mod.fields.map((field) => (
                  <Option key={field.id} value={field.name}>
                    {field.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReferenceLinkFieldConfig;