import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

interface AutoIncrementIdProps {
  id?: string;
  startId?: string;
  endId?: string;
  onChange?: (start: string, end: string) => void;
}

const AutoIncrementId: React.FC<AutoIncrementIdProps> = ({
  id,
  startId = '',
  endId = '',
  onChange,
}) => {
  const [startValue, setStartValue] = useState<string>(startId);
  const [endValue, setEndValue] = useState<string>(endId);

  // Utility to pad numbers with leading zeros
  const padWithZeros = (numberStr: string, length: number) => {
    return numberStr.padStart(length, '0');
  };

  // Extract prefix and numeric parts from an ID
  const extractPrefixAndNumber = (id: string) => {
    const match = id.match(/^([a-zA-Z]*)(\d+)$/);
    if (match) {
      const prefix = match[1] || '';
      const number = match[2] || '';
      return { prefix, number };
    }
    return { prefix: '', number: '' };
  };

  // Update the end value when start value changes
  const updateEndValue = (start: string) => {
    const { prefix, number } = extractPrefixAndNumber(start);
    if (number) {
      const numberLength = number.length;
      const maxNumber = '9'.repeat(numberLength); // E.g., 999 for A100
      const newEnd = `${prefix}${maxNumber}`;
      setEndValue(newEnd);
      if (onChange) onChange(start, newEnd);
    }
  };

  // Update the start value when end value changes
  const updateStartValue = (end: string) => {
    const { prefix, number } = extractPrefixAndNumber(end);
    if (number) {
      const numberLength = number.length;
      const minNumber = padWithZeros('1', numberLength); // E.g., A00100 for A99999
      const newStart = `${prefix}${minNumber}`;
      setStartValue(newStart);
      if (onChange) onChange(newStart, end);
    }
  };

  // Handle start input change
  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStart = e.target.value;
    setStartValue(newStart);
    updateEndValue(newStart); // Update the end value whenever the start value changes
  };

  // Handle end input change
  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEnd = e.target.value;
    setEndValue(newEnd);
    updateStartValue(newEnd); // Update the start value whenever the end value changes
  };

  // Sync end value with start value on load
  useEffect(() => {
    if (startId) {
      updateEndValue(startId);
    }
  }, [startId]);

  // Sync start value with end value on load
  useEffect(() => {
    if (endId) {
      updateStartValue(endId);
    }
  }, [endId]);

  return (
    <div>
      <Input
        id={`${id}-start`}
        value={startValue}
        onChange={handleStartChange}
        placeholder="Start ID"
        style={{ marginBottom: '8px' }}
      />
      <Input
        id={`${id}-end`}
        value={endValue}
        onChange={handleEndChange}
        placeholder="End ID"
      />
    </div>
  );
};

export default AutoIncrementId;


export const AutoIncrementIdConfig: React.FC<{
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
  const [startValue, setStartValue] = useState<string>(fieldOptions.start || '');
  const [endValue, setEndValue] = useState<string>(fieldOptions.end || '');
  
  // Handle start input change
  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStart = e.target.value;
    setStartValue(newStart);
    updateEndValue(newStart); // Update the end value whenever the start value changes
  };

  // Handle end input change
  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEnd = e.target.value;
    setEndValue(newEnd);
    updateStartValue(newEnd); // Update the start value whenever the end value changes
  };

  // Update the end value based on the start value
  const updateEndValue = (start: string) => {
    const { prefix, number } = extractPrefixAndNumber(start);
    if (number) {
      const numberLength = number.length;
      const maxNumber = '9'.repeat(numberLength); // E.g., 999 for A100
      const newEnd = `${prefix}${maxNumber}`;
      setEndValue(newEnd);
      setFieldOptions({ ...fieldOptions, start, end: newEnd });
    }
  };

  // Update the start value based on the end value
  const updateStartValue = (end: string) => {
    const { prefix, number } = extractPrefixAndNumber(end);
    
    if (number) {
      const startNumber = extractPrefixAndNumber(startValue).number;
      const numberLength = number.length;
      if (numberLength > startNumber.length) {
        const minNumber = padWithZeros(startNumber, numberLength); // Keep existing start number
        const newStart = `${prefix}${minNumber}`;
        setStartValue(newStart);
        setFieldOptions({ ...fieldOptions, start: newStart, end });
    }
  }
  };

  // Utility to pad numbers with leading zeros
  const padWithZeros = (numberStr: string, length: number) => {
    return numberStr.padStart(length, '0');
  };

  // Extract prefix and numeric parts from an ID
  const extractPrefixAndNumber = (id: string) => {
    const match = id.match(/^([a-zA-Z]*)(\d+)$/);
    if (match) {
      const prefix = match[1] || '';
      const number = match[2] || '';
      return { prefix, number };
    }
    return { prefix: '', number: '' };
  };

  useEffect(() => {
    setFieldOptions((prevOptions: any) => ({
      ...prevOptions,
      start: startValue,
      end: endValue,
    }));
  }, [startValue, endValue]);

  // Initialize the end value based on the start value on first load
  useEffect(() => {
    if (fieldOptions.start && !startValue) {
      updateEndValue(fieldOptions.start); // Sync end value initially
    }
  }, [fieldOptions.start]);

  // Initialize the start value based on the end value on first load
  useEffect(() => {
    if (fieldOptions.end && !endValue) {
      updateStartValue(fieldOptions.end); // Sync start value initially
    }
  }, [fieldOptions.end]);

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>Start Value:</label>
        <Input
          value={startValue}
          onChange={handleStartChange}
          placeholder="Enter Start Value (e.g., A1000)"
          style={{ width: '100%' }}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>End Value:</label>
        <Input
          value={endValue}
          onChange={handleEndChange}
          placeholder="Enter End Value (e.g., A9999)"
          style={{ width: '100%' }}
        />
      </div>
      <p>
        This should be numeric, either with or without a prefix. 
        Ex. A100, 100, but not 100A or
      </p>
    </div>
  );
};