// accountAPIService.ts
import axios from 'axios';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;
const getToken = () => {
  return localStorage.getItem('token');
}

const axiosInstance = axios.create({
  baseURL: API_URL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    // console.log('Axios Interceptor - Token:', token); // Confirm token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json'; // Set Content-Type
      // console.log('if worked')
    }
    // console.log(config.headers)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const createAccount = async (accountData) => {
  try {
    const response = await axiosInstance.post('/accounts', accountData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAccountsByCompany = async (companyId) => {
  try {
    // console.log('getAccounts started')
    const response = await axiosInstance.get(`/accounts/company/${companyId}`);
    // console.log('Response: ', response.data);
    return response.data;
  } catch (error) {
    // console.log('caught error')
    throw error;
  }
};

// Export the Axios instance for other services if needed
export default axiosInstance;