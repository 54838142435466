/**
 * Reorders an array by moving an item from one index to another.
 * @param {Array} list - The original array.
 * @param {number} startIndex - The index of the item to move.
 * @param {number} endIndex - The index to move the item to.
 * @returns {Array} - The reordered array.
 */
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };