import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select } from 'antd';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useTenant } from '../contexts/TenantContext';
import { useUser } from '../contexts/UserContext'; // Ensure you have access to UserContext


const QBHome: React.FC = () => {
  // const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { tenantId, setTenantId, companyId, setCompanyId } = useTenant();  // Use tenant context
  const { user, loading: userLoading } = useUser(); // Access the logged-in user
  const API_URL = process.env.REACT_APP_API_URL;


  // Fetch user data if defaultTenantId is not present in UserContext
  const fetchUserData = async (email: string, token: string): Promise<number> => {
    try {
      const response = await axios.get(`${API_URL}/users/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedUser = response.data;

      if (!fetchedUser || !fetchedUser.defaultTenantId) {
        throw new Error('Default Tenant ID not found for the user.');
      }

      return fetchedUser.defaultTenantId;
    } catch (err: any) {
      console.error('Error fetching user data:', err);
      throw new Error(err.response?.data?.message || 'Failed to fetch user data.');
    }
  };


  // Fetch tenant and company data based on tenantId
  const fetchTenantAndCompany = async (tenantId: number, token: string) => {
    try {
      // Fetch tenant details
      const tenantResponse = await axios.get(`${API_URL}/tenants/${tenantId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedTenantId = tenantResponse.data.id;
      setTenantId(fetchedTenantId);
      localStorage.setItem('tenantId', fetchedTenantId)

      // Fetch companies associated with the tenant
      const companiesResponse = await axios.get(`${API_URL}/companies/by-tenant/${fetchedTenantId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


      const companies = companiesResponse.data;
      if (companies && companies.length > 0) {
        setCompanyId(companies[0].id); // Select the first company as default
        const newcompanyId = companies[0].id;
        localStorage.setItem('companyId', newcompanyId)
      } else {
        throw new Error('No companies found for the tenant.');
      }



      setLoading(false);
    } catch (err: any) {
      console.error('Error fetching tenant and company data:', err);
      setError(err.message || 'Failed to fetch tenant and company data.');
      setLoading(false);
    }
  };

  // Initialize tenant and company data
  const initializeTenantAndCompany = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      let tenantId: number | undefined = user?.defaultTenantId;

      // If defaultTenantId is not present in UserContext, fetch it via API
      if (!tenantId) {
        if (!user?.email) {
          throw new Error('User email not found. Please log in again.');
        }
        tenantId = await fetchUserData(user.email, token);
      }


      // Proceed to fetch tenant and company data
      await fetchTenantAndCompany(tenantId, token);
      
    } catch (err: any) {
      console.error('Initialization error:', err);
      setError(err.message || 'Failed to initialize tenant and company data.');
      setLoading(false);
    }
  };



  useEffect(() => {
    // Wait until UserContext loading is complete
    if (!userLoading) {
      initializeTenantAndCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]); // Depend on userLoading to ensure user data is ready

  if (loading || userLoading) {
    return <div>Loading tenant and company information...</div>;
  }

  return (
    <div>
      <h1>
        Welcome to QuickBuild!
      </h1>
      <p>This page is intentionally blank. We'll eventually add something cool here. </p>
      <p>If you just signed up, you now have access to a handful of default pages: Items, Customers, Vendors, Sales, Purchases</p>
      <p>But that's just the beginning. </p>
      <p>The power of Quickbuild is easy customization. Here's everything we can do (now) to customize your experience: </p>
      <ul>
        <li>Create new pages</li>
        <li>Create new fields - text, paragraph, phone numbers, dates, etc.</li>
        <li>Configure transaction stages - quote to order to invoice, or quote to order to ship to invoice</li>
        <li>Configure calculations - inventory decreases at x stage vs y stage</li>
      </ul>
      <p>As soon as it is stabilized, we'll open up the configurator for you to create your own functionality</p>
      <p>We're still in beta mode so please forgive any bugs</p>
      <p>We don't have a lot of the server side optimized, so if you import data, don't import millions of rows!</p>
      <p>Much love and appreciation,</p>
      <p>Nathan Cunningham, QuickBuild</p>
    </div>
  );
};

export default QBHome;