import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, Table } from 'antd';
import FieldPalette from '../Fields/_FieldPalette';
import ProcessBuilder from '../Process/ProcessBuilder'
import FieldTable from '../PageOrganizer/TableOrganizer';
import PageOrganizer from '../PageOrganizer/PageOrganizer';
import { useTenant } from '../../../contexts/TenantContext';
import { useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';


interface NestedPageBuilderProps {
  module?: any;  // Optional module prop
}

const NestedPageBuilder: React.FC<NestedPageBuilderProps> = () => {
  const location = useLocation();
  const module = location.state?.module; // Retrieve the passed module from location state
  const [pageTitle, setPageTitle] = useState('New Page');
  const [moduleName, setModuleName] = useState(pageTitle);
  const [version, setVersion] = useState('1.0.0');
  const [status, setStatus] = useState('draft');
  const [functions, setFunctions] = useState([]);
  const [isProcessModalVisible, setIsProcessModalVisible] = useState(false);
  const { tenantId, companyId } = useTenant();
  const [functionToEdit, setFunctionToEdit] = useState(null);  // Track which function is being edited
  const [sections, setSections] = useState([
    { id: 'section-1', title: 'Headers', fields: [] },
    { id: 'section-2', title: 'Table', fields: [] },
  ]);
  const [selectedSection, setSelectedSection] = useState('section-1');
  const [initialTableColumns, setInitialTableColumns] = useState([]);

  // Reference to FieldTable component to get its state when saving
  const fieldTableRef = useRef<any>();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (module) {
      setPageTitle(module.moduleName || 'Editable Page Title');
      setModuleName(module.moduleName || 'Module Name');
      setFunctions(module.functions || []);
      setVersion(module.version || '1.0.0');
      setStatus(module.status || 'draft');
      setSections(module.sections || []);
      setInitialTableColumns(module.tableData?.columns || []);
  
      // **Add this line to update selectedSection to the first section's ID**
      setSelectedSection(sections[0]?.id || '');
    }
  }, [module]);

  

  const onAddField = (fieldType) => {
    // Only add fields to the Headers section
    const updatedSections = sections.map(section => {
      if (section.id === 'section-1') {
        const newField = {
          id: `field-${Date.now()}`,
          type: fieldType,
          name: `${fieldType}_${Date.now()}`,
        };
        return { ...section, fields: [...section.fields, newField] };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldUpdate = (sectionId, fieldId, updatedField) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.map(field =>
          field.id === fieldId ? { ...field, ...updatedField } : field
        );
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldDelete = (sectionId, fieldId) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter(field => field.id !== fieldId);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onSaveFunction = (newFunction) => {
    setFunctions((prevFunctions) => [...prevFunctions, newFunction]);  // Append the new function to the array
    setIsProcessModalVisible(false);  // Close the modal
  };

  const handleDeleteFunction = (index) => {
    const updatedFunctions = functions.filter((_, funcIndex) => funcIndex !== index);
    setFunctions(updatedFunctions); // Update the state after deleting the function
  };

  const handleEditFunction = (index) => {
    const funcToEdit = functions[index];
    setFunctionToEdit(funcToEdit);  // Set the function to edit
    setIsProcessModalVisible(true); // Open the modal
  };

  const saveSchema = async () => {
    // Get table data
    const tableData = fieldTableRef.current?.getTableData() || {};

    const schema = {
      pageTitle: moduleName,
      moduleName,
      version,
      status,
      sections,
      tableData, // Include table data
      tenantId,
      companyId,
      pageType: 'Nested',
      functions: functions.map((func) => ({
        functionId: func.functionId,
        name: func.name,
        steps: func.steps.map((step) => ({
          stepId: step.stepId,
          type: step.type,
          typeDetails: {
            typeId: step.typeDetails.typeId || 'N/A',
            triggerType: step.typeDetails.triggerType || 'N/A',
            conditionField: step.typeDetails.conditionField || 'N/A',
            conditionValue: step.typeDetails.conditionValue || 'N/A',
            actionType: step.typeDetails.actionType || 'N/A',
            buttonName: step.typeDetails.buttonName || 'N/A',
            modalMessage: step.typeDetails.modalMessage || 'N/A',
          },
        })),
      })),
    };

    console.log('table: ', schema.tableData)
    try {
      const url = module?._id
        ? `${API_URL}/quickbuild-prod/page-modules/${module._id}`
        : `${API_URL}/quickbuild-prod/page-modules/save`;

      const method = module?._id ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(schema),
      });

      const result = await response.json();
      console.log('result: ', result)

      if (response.ok) {
        console.log('Schema saved successfully!');
      } else {
        console.error('Failed to save schema.');
      }
    } catch (error) {
      console.error('Error saving schema:', error);
    }
  };

  const availableFields = [
    'TextField',
    'ParagraphTextField',
    'AutoIncrementId',
    'PhoneNumber',
    'EmailField',
    'Dropdown',
    'ReferenceLinkField',
  ];

  // Separate available fields for the table columns
  const tableAvailableFields = [
    // For now, keep the same as availableFields
    ...availableFields,
  ];

  const functionColumns = [
    {
      title: 'Function ID',
      dataIndex: 'functionId',  // Display the functionId
      key: 'functionId',
    },
    {
      title: 'Function Name',
      dataIndex: 'name',  // Display the function name
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => handleEditFunction(index)}>Edit</Button>
          <Button type="link" onClick={() => handleDeleteFunction(index)}>Delete</Button>
        </>
      ),
    },
  ];

  const handleSectionSelect = (sectionId) => {
    setSelectedSection(sectionId);
  };

  return (
    <div>
      <h1>Nested Page Builder</h1>

      <Input
        value={pageTitle}
        onChange={(e) => {
          setPageTitle(e.target.value);
          setModuleName(e.target.value); // Keep moduleName in sync with pageTitle
        }}
        placeholder="Editable Page Title"
        style={{ marginBottom: '10px', width: '50%' }}
      />

      {/* Headers Section */}
        <>
          <FieldPalette
            onAddField={(fieldType) => onAddField(fieldType)}
            availableFields={availableFields}
          />

          <DragDropContext onDragEnd={ondragend}>
          <PageOrganizer
            sections={sections.filter(section => section.id === 'section-1')}
            setSections={setSections}
            onFieldUpdate={onFieldUpdate}
            onFieldDelete={onFieldDelete}
            pageType='Nested'
          />
          </DragDropContext>
        </>

      {/* Table Section */}
        <FieldTable
          ref={fieldTableRef}
          availableFields={tableAvailableFields}
          currentModuleId={module?._id || 'new-module'}
          currentModuleName={module?.moduleName || moduleName}
          initialColumns={initialTableColumns}
        />

      <Button type="primary" onClick={saveSchema} style={{ marginTop: '10px' }}>
        Save Schema
      </Button>

      <Button onClick={() => setIsProcessModalVisible(true)} style={{ marginLeft: '10px' }}>
        Add Function
      </Button>

      <div>
        <h3>Functions</h3>
        <Table dataSource={functions} columns={functionColumns} rowKey="functionId" />
      </div>

      {/* Modal for adding new functions using ProcessBuilder */}
      <Modal
        title="Process Builder"
        open={isProcessModalVisible}
        onCancel={() => setIsProcessModalVisible(false)}
        footer={null}  // Custom footer is not needed because ProcessBuilder will handle saving
      >
        <ProcessBuilder
          existingFunction={functionToEdit}  // Pass the existing function
          onSaveFunction={(newFunction) => {
            onSaveFunction(newFunction);  // Save the function
            setIsProcessModalVisible(false);  // Close the modal
          }}
        />
      </Modal>
    </div>
  );
};

export default NestedPageBuilder;