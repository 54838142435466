import React from 'react';
import { Input } from 'antd';

interface EmailFieldProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const EmailField: React.FC<EmailFieldProps> = ({ id, name, value, onChange }) => {
  return (
    <Input
      type="email"
      id={id}
      name={name}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      placeholder="Enter email address"
      style={{ width: '100%' }}
    />
  );
};

export const EmailFieldConfig: React.FC<{
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
  return (
    <div>
      {/* No additional configuration for Email Field */}
      <p>No additional configuration required for Email Field.</p>
    </div>
  );
};

export default EmailField;