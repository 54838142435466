// FieldPalette.tsx

import React from 'react';
import { Button } from 'antd';

const FieldPalette = ({ onAddField, availableFields }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      {availableFields.map((fieldType) => (
        <Button
          key={fieldType}
          onClick={() => onAddField(fieldType)}
          style={{ marginRight: '8px', marginBottom: '8px' }}
        >
          {fieldType}
        </Button>
      ))}
    </div>
  );
};

export default FieldPalette;