import React, { useEffect, useState } from 'react';
import { Select, Table, Input, Button, InputNumber, DatePicker } from 'antd';
import axios from 'axios';
import { useTenant } from '../../contexts/TenantContext'
import moment from 'moment'

const { Option } = Select;
const API_URL = process.env.REACT_APP_API_URL;

const JournalEntryForm: React.FC = () => {
  const { tenantId, companyId } = useTenant();
  const [accounts, setAccounts] = useState([]); // State to store accounts
  const [journalLines, setJournalLines] = useState([{ key: 0, accountId: null, description: '', debit: 0, credit: 0, documentNumber: '' , postingDate: moment()}]);
  // const [postingDate, setPostingDate] = useState(moment());  // Default to today


// const handleDateChange = (index, date) => {
//     const updatedLines = [...journalLines];
  
//     // Check if the selected date is valid
//     if (date && date.isValid()) {
//       updatedLines[index].postingDate = date.format('YYYY-MM-DD'); // Use formatted date
//     } else {
//       updatedLines[index].postingDate = null; // Handle invalid date
//     }
  
//     setJournalLines(updatedLines); // Update the state
//   };
    
// const handleFieldChange = (index, field, value) => {
//     const newLines = [...journalLines];
//         if (field === 'postingDate') {
//             newLines[index][field] = value;
//         } else {
//           newLines[index][field] = value;
//         }
//         setJournalLines(newLines);
//       };
        
  // Fetch accounts when tenantId or companyId changes
  useEffect(() => {
    const fetchAccounts = async () => {
      if (!tenantId || !companyId) return;

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/chart-of-accounts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { companyId },  // Ensure you pass companyId to filter accounts
        });
        setAccounts(response.data);  // Populate accounts
      } catch (err) {
        console.error('Error fetching accounts:', err);
      }
    };

    fetchAccounts();
  }, [tenantId, companyId]);

  // Function to add a new line
const addJournalLine = () => {
    setJournalLines([...journalLines, { key: journalLines.length, accountId: null, description: '', debit: 0, credit: 0, documentNumber: '' , postingDate: moment()}]);
  };

  // Function to handle changes in a specific line (account, description, etc.)
const handleLineChange = (index, field, value) => {
    const newLines = [...journalLines];
    newLines[index][field] = value; // Store the Moment object directly
    setJournalLines(newLines);
  };

  // Function to remove a line
const removeJournalLine = (index) => {
    const updatedLines = journalLines.filter((_, i) => i !== index);
    setJournalLines(updatedLines);
  };

const columns = [
    {
      title: 'Account',
      dataIndex: 'accountId',
      render: (_, record, index) => (
        <Select
          placeholder="Select Account"
          style={{ width: '100%' }}
          value={record.accountId || undefined}
          onChange={(value) => handleLineChange(index, 'accountId', value)}
        >
          {accounts.map((account) => (
            <Option key={account.accountId} value={account.accountId}>
              {account.accountNumber}: {account.accountName}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, record, index) => (
        <Input
          placeholder="Description"
          value={record.description}
          onChange={(e) => handleLineChange(index, 'description', e.target.value)}
        />
      ),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      render: (_, record, index) => (
        <InputNumber
          placeholder="Debit"
          value={record.debit}
          onChange={(value) => handleLineChange(index, 'debit', value)}
        />
      ),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      render: (_, record, index) => (
        <InputNumber
          placeholder="Credit"
          value={record.credit}
          onChange={(value) => handleLineChange(index, 'credit', value)}
        />
      ),
    },
    {
      title: 'Document Number',
      dataIndex: 'documentNumber',
      render: (_, record, index) => (
        <Input
          placeholder="Document Number"
          value={record.documentNumber}
          onChange={(e) => handleLineChange(index, 'documentNumber', e.target.value)}
        />
      ),
    },
    {
        title: 'Posting Date',  // Column for posting date
        dataIndex: 'postingDate',
        key: 'postingDate',
        render: (_, record, index) => (
          <DatePicker
          value={record.postingDate || null} // Use the Moment object directly
          onChange={(date) => handleLineChange(index, 'postingDate', date)}
            format="YYYY-MM-DD"
          />
        ),  
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, __, index) => (
        <Button type="link" danger onClick={() => removeJournalLine(index)}>
          Remove
        </Button>
      ),
    },
  ];

  
  const calculateTotal = (field) => {
    return journalLines.reduce((acc, line) => acc + (line[field] || 0), 0);
  };

  interface JournalLine {
    accountId: number;
    description: string;
    debit: number;
    credit: number;
    documentNumber: string;
    postingDate: moment.Moment;
  }
  
  interface JournalEntryGroup {
    postingDate: string;
    documentNumber: string;
    lines: JournalLine[];
    totalDebit?: number;
    totalCredit?: number;
  }
  
  const handlePostEntry = async () => {
    try {
      // Validate the journal entry lines before posting
      // console.log('trying');
      if (!journalLines || journalLines.length === 0) {
        console.error('No journal lines to post.');
        return;
      }
  
      // Ensure overall debits and credits balance
      const totalDebit = calculateTotal('debit');
      const totalCredit = calculateTotal('credit');
  
      if (totalDebit !== totalCredit) {
        console.error('Overall debits and credits do not balance.');
        return;
      }
  
      // Define the groups object with the proper type
      const groups: Record<string, JournalEntryGroup> = {};
  
      journalLines.forEach((line) => {
        const postingDate = line.postingDate ? line.postingDate.format('YYYY-MM-DD') : '';
        const documentNumber = line.documentNumber || '';
        const key = `${postingDate}-${documentNumber}`;
        if (!groups[key]) {
          groups[key] = {
            postingDate,
            documentNumber,
            lines: [],
          };
        }
        groups[key].lines.push(line);
      });
  
      // Validate each group
      let allGroupsBalanced = true;
      const unbalancedGroups = [];
  
      for (const key in groups) {
        const group = groups[key];
        const { lines } = group;
        const groupTotalDebit = lines.reduce((sum, line) => sum + (line.debit || 0), 0);
        const groupTotalCredit = lines.reduce((sum, line) => sum + (line.credit || 0), 0);
        if (groupTotalDebit !== groupTotalCredit) {
          allGroupsBalanced = false;
          unbalancedGroups.push(key);
        } else {
          group.totalDebit = groupTotalDebit;
          group.totalCredit = groupTotalCredit;
        }
      }
  
      if (!allGroupsBalanced) {
        console.error(
          `Debits and credits do not balance for the following posting date and document number combinations: ${unbalancedGroups.join(
            ', '
          )}`
        );
        return;
      }
  
      const token = localStorage.getItem('token');
  
      // Create an array to hold promises
      const journalEntryPromises = Object.values(groups).map(async (group) => {
        const { postingDate, documentNumber, lines, totalDebit, totalCredit } = group;
  
        // Prepare the journal entry data for this group
        const journalEntryData = {
          tenantId,
          companyId,
          postingDate,
          documentNumber,
          totalDebit,
          totalCredit,
          status: 'posted',
          lines: lines.map((line) => ({
            accountId: line.accountId,
            debit: line.debit,
            credit: line.credit,
            description: line.description || '',
            documentNumber: line.documentNumber,
            postingDate: line.postingDate ? line.postingDate.format('YYYY-MM-DD') : null,
            tenantId,
            companyId,
            status: 'posted',
          })),
        };
        
        console.log('Sending Journal Entry:', journalEntryData);
  
        // Send the journal entry data to the backend
        const response = await axios.post(
          `${API_URL}/journal-entries`,
          journalEntryData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        console.log('Journal entry posted successfully:', response.data);
        return response.data;
      });
  
      // Wait for all journal entries to be posted
      const results = await Promise.all(journalEntryPromises);
  
      console.log('All journal entries posted successfully:', results);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  return (
    <div>
      <h1>Journal Entry</h1>

      <Table
        columns={columns}
        dataSource={journalLines}
        pagination={false}
        rowKey="key"
      />

      <Button type="primary" onClick={addJournalLine}>
        Add Line
      </Button>

      <Button type="primary" style={{ marginTop: '10px' }} onClick={handlePostEntry}>
        Post Entry
        </Button>
    </div>
  );
};

export default JournalEntryForm;