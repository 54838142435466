import React, { useEffect, useState } from 'react';
import { Select, Spin, Alert, Menu, Layout, Button } from 'antd';
import { useTenant } from '../contexts/TenantContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
// import { PieChartOutlined } from '@ant-design/icons'
import { AuditOutlined , HomeOutlined} from '@ant-design/icons'; 
import SideMenuRender from './SideMenuRender';
import { useUser } from '../contexts/UserContext';  // Assuming this is the correct path
import '../styles/profileSelector.css'

const API_URL = process.env.REACT_APP_API_URL;
const { Option } = Select;
const { Sider } = Layout;

const ProfileSelector: React.FC = () => {
  const { tenantId, setTenantId, companyId, setCompanyId } = useTenant();
  const [tenants, setTenants] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loadingTenants, setLoadingTenants] = useState(true);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser();
  const { logout: userLogout } = useUser();




  const navigate = useNavigate();
  const location = useLocation();  // To highlight the selected menu item
  // const [collapsed, setCollapsed] = useState(false);  // State to manage menu collapse


  useEffect(() => {
    // Check if tenantId exists in localStorage when the component mounts
    const storedTenantId = localStorage.getItem('tenantId');
    if (storedTenantId) {
      setTenantId(parseInt(storedTenantId, 10)); // Parse the stored tenantId
    }
    const storedCompanyId = localStorage.getItem('companyId');
    if (storedCompanyId) {
      setCompanyId(parseInt(storedCompanyId, 10)); // Parse the stored companyId
    }
  }, [setTenantId, setCompanyId]);
  

  // Fetch companies when tenantId changes
  useEffect(() => {
    if (!tenantId) return;

    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/companies/by-tenant/${tenantId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanies(response.data);
        setLoadingCompanies(false);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Failed to fetch companies.');
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, [tenantId]);

  // Handle tenant change
  const handleTenantChange = (selectedTenantId: number) => {
    setTenantId(selectedTenantId);
    localStorage.setItem('tenantId', selectedTenantId.toString()); // Store tenantId in localStorage
    setCompanyId(null); // Reset company selection when tenant changes
};

  // Handle company change
  const handleCompanyChange = (selectedCompanyId: number) => {
    setCompanyId(selectedCompanyId);
    localStorage.setItem('companyId', selectedCompanyId.toString()); // Store companyId in localStorage
  };

  let menuItems = [];
  if (user?.roles.includes('quickbuildadmin')) {
    menuItems = [
      // All admin specific items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/quickbuild-home') },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder') },
    ];
  }

  else if (user?.roles.includes('builder')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/quickbuild-home') },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder') },
    ];
  }
  
  else if (user?.roles.includes('freetrial')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/quickbuild-home') },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder') },
    ];
  }

  else  { //if (user?.roles.includes('user')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/quickbuild-home') },
    ];
  }

  const handleLogout = () => {
    // Clear authentication data
    userLogout();
    localStorage.removeItem('companyId')
    localStorage.removeItem('tenantId')
    localStorage.removeItem('authToken');
    // Redirect to login page
    window.location.href = '/login';
  };

  const isAdmin = user?.roles.includes('quickbuildadmin');

  useEffect(() => {
    // If user is admin, fetch all tenants
    if (isAdmin) {
      const fetchTenants = async () => {
        setLoadingTenants(true);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Authentication token not found. Please log in again.');
          }

          const response = await axios.get(`${API_URL}/tenants`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          setTenants(response.data);
          setLoadingTenants(false);
        } catch (err) {
          console.error('Error fetching tenants:', err);
          setError('Failed to fetch tenants.');
          setLoadingTenants(false);
        }
      };
      fetchTenants();
    } else {
      // For non-admin users, set tenantId to defaultTenantId from UserContext
      if (user?.defaultTenantId) {
        setTenantId(user.defaultTenantId);
      }
    }
  }, [isAdmin, setTenantId, user]);
  

  return (
    <Layout className="profile-content" style={{ minHeight: '100vh' }}>
      <Sider className='scrollable-slider' width={200}>
        <div className="profile-selector" style={{ padding: '10px' }}>
          {/* Conditionally render tenant and company selectors for admins */}
          {user?.roles.includes('quickbuildadmin') && (
            <>
              {loadingTenants ? (
                <Spin tip="Loading tenants..." />
              ) : error ? (
                <Alert message="Error" description={error} type="error" showIcon />
              ) : (
                <>
                  <Select
                    placeholder="Select a Tenant"
                    style={{ width: '100%', marginBottom: 10 }}
                    value={tenantId || undefined}
                    onChange={handleTenantChange}
                  >
                    {tenants.map((tenant) => (
                      <Option key={tenant.id} value={tenant.id}>
                        {String(tenantId) && ": " && tenant.tenant_name}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    placeholder="Select a Company"
                    style={{ width: '100%', marginBottom: 10 }}
                    value={companyId || undefined}
                    onChange={handleCompanyChange}
                    loading={loadingCompanies}
                    disabled={!tenantId || loadingCompanies}
                  >
                    {companies.map((company) => (
                      <Option key={company.id} value={company.id}>
                        {company.companyName}
                      </Option>
                    ))}
                  </Select>
                </>
              )}
            </>
          )}
        </div>

        <Menu
          mode="inline"
          theme="dark"  // Optional: Dark theme for the side menu
          selectedKeys={[location.pathname]}
          items={menuItems}
        />
        <SideMenuRender tenantId={tenantId} companyId={companyId}/> {/* Pass tenantId and companyId */}
        <Button className="logout-btn" onClick={handleLogout} style={{ marginTop: '20px', width: '90%', marginLeft: '5%' }}>
          Logout
        </Button>
      </Sider>
    </Layout>
  );
};

export default ProfileSelector;