import React, { useEffect, useState } from 'react';
import { Select, Button } from 'antd';
import axios from 'axios';

const { Option } = Select;

interface GLAction {
  account: string;
  field: string;
  type: 'Debit' | 'Credit';
}

interface GLActionsConfiguratorProps {
  glActions: GLAction[];
  onChange: (glActions: GLAction[]) => void;
  fields: any[];
}

const GLActionsConfigurator: React.FC<GLActionsConfiguratorProps> = ({ glActions, onChange, fields }) => {
  const [actions, setActions] = useState<GLAction[]>(glActions || []);
  const [accounts, setAccounts] = useState<any[]>([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(`${API_URL}/accounts`); 
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
    fetchAccounts();
  }, []);

  useEffect(() => {
    onChange(actions);
  }, [actions, onChange]);

  const addAction = () => {
    setActions([...actions, { account: '', field: '', type: 'Debit' }]);
  };

  const updateAction = (index: number, key: string, value: any) => {
    const updatedActions = [...actions];
    updatedActions[index] = { ...updatedActions[index], [key]: value };
    setActions(updatedActions);
  };

  return (
    <div>
      {actions.map((action, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <Select
            placeholder="Select Account"
            value={action.account}
            onChange={(value) => updateAction(index, 'account', value)}
            style={{ width: '100%', marginBottom: '5px' }}
          >
            {accounts.map((account) => (
              <Option key={account.id} value={account.name}>
                {account.name}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select Field"
            value={action.field}
            onChange={(value) => updateAction(index, 'field', value)}
            style={{ width: '100%', marginBottom: '5px' }}
          >
            {fields
              .filter((field) => ['NumberField', 'CalculationField'].includes(field.type))
              .map((field) => (
                <Option key={field.id} value={field.name}>
                  {field.name}
                </Option>
              ))}
          </Select>
          <Select
            value={action.type}
            onChange={(value) => updateAction(index, 'type', value)}
            style={{ width: '100%', marginBottom: '5px' }}
          >
            <Option value="Debit">Debit</Option>
            <Option value="Credit">Credit</Option>
          </Select>
          <Button
            type="link"
            danger
            onClick={() => setActions(actions.filter((_, idx) => idx !== index))}
          >
            Remove
          </Button>
          <hr />
        </div>
      ))}
      <Button onClick={addAction}>Add GL Action</Button>
    </div>
  );
};

export default GLActionsConfigurator;