import React, { useState } from 'react';
import { Select } from 'antd';

interface DropdownConfigProps {
  fieldOptions: any;
  setFieldOptions: (options: any) => void;
}

export const DropdownConfig: React.FC<DropdownConfigProps> = ({
  fieldOptions,
  setFieldOptions,
}) => {
  const [inputValue, setInputValue] = useState('');

  // Handle changes to the Select component's value
  const handleChange = (values: string[]) => {
    setFieldOptions({ ...fieldOptions, options: values });
  };

  // Track the current input value
  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  // Handle key presses within the Select component
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.includes(';')) {
      e.preventDefault(); // Prevent the default 'Enter' behavior

      // Split the input value by semicolons and clean up each entry
      const values = inputValue
        .split(';')
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      // Merge the new values with existing options, ensuring uniqueness
      const newOptions = Array.from(
        new Set([...(fieldOptions.options || []), ...values])
      );

      // Update the field options with the new list
      setFieldOptions({ ...fieldOptions, options: newOptions });

      // Clear the input value to reset the input field
      setInputValue('');
    }
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <label>Options:</label>
      <Select
        mode="tags" // Enables tag mode for creating new options
        style={{ width: '100%' }}
        placeholder="Type an option and press enter, or paste multiple options separated by semicolons"
        value={fieldOptions.options || []}
        onChange={handleChange}
        onSearch={handleSearch}
        onKeyDown={handleKeyDown} // Handle key presses
      />
    </div>
  );
};

export default DropdownConfig;