// ImportDataModal.tsx
import React, { useState } from 'react';
import { Upload, Button, Progress, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ImportDataModal = ({ moduleId, tenantId, companyId, onClose, onImportSuccess }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
  
    const handleUpload = async () => {
      if (fileList.length === 0) {
        message.error('Please select a file to upload.');
        return;
      }
  
      setUploading(true);
  
      const formData = new FormData();
      formData.append('file', fileList[0]);
      
      try {
        const response = await axios.post(
          `${API_URL}/import/data`,
          formData,
          {
            params: { moduleId, tenantId, companyId },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          }
        );
  
        const importResult = response.data;
        if (importResult.success) {
          message.success(`Imported ${importResult.insertedCount} records successfully.`);
          onImportSuccess(importResult);
        } else {
          message.error('Import completed with errors.');
          // You can display importResult.errors if needed
          message.error(importResult.errors.join('\n'));
        }
      } catch (error) {
        message.error('Failed to import data.');
      } finally {
        setUploading(false);
        setFileList([]);
        setProgress(0);
      }
    };
  
    const props = {
      onRemove: (file) => {
        setFileList([]);
      },
      beforeUpload: (file) => {
        setFileList([file]);
        return false; // Prevent automatic upload
      },
      fileList,
    };
  
    return (
      <div>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        {progress > 0 && <Progress percent={progress} />}
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Import'}
        </Button>
        <Button onClick={onClose} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </div>
    );
  };
  
  export default ImportDataModal;