// StageBuilder.tsx

import React, { useState } from 'react';
import { Button, Input, Checkbox, Table, message } from 'antd';

interface Field {
  id: string;
  name: string;
}

interface Stage {
  id: string;
  name: string;
  required: boolean;
  allowPartial: boolean;
  fields: { fieldId: string; isCarryover: boolean }[];
}

interface StageBuilderProps {
  existingStages?: Stage[];
  allFields: Field[];
  onSaveStages: (stages: Stage[]) => void;
}

const StageBuilder: React.FC<StageBuilderProps> = ({ existingStages = [], allFields, onSaveStages }) => {
  // Ensure all existing stages have a fields array
  const [stages, setStages] = useState<Stage[]>(
    existingStages.map((stage) => ({
      ...stage,
      fields: stage.fields || [],
    }))
  );
  const [newStageName, setNewStageName] = useState('');

  const addStage = () => {
    if (newStageName.trim() === '') {
      message.error('Stage name cannot be empty.');
      return;
    }

    const newStage: Stage = {
      id: `stage-${Date.now()}`,
      name: newStageName.trim(),
      required: false,
      allowPartial: false,
      fields: [], // Initialize fields array
    };
    setStages([...stages, newStage]);
    setNewStageName('');
  };

  const updateStage = (index: number, key: keyof Stage, value: any) => {
    const updatedStages = [...stages];
    updatedStages[index] = { ...updatedStages[index], [key]: value };
    setStages(updatedStages);
  };

  const handleFieldCarryoverChange = (stageIndex: number, selectedFieldIds: string[]) => {
    const updatedStages = [...stages];
    updatedStages[stageIndex].fields = allFields
      .filter((field) => selectedFieldIds.includes(field.id))
      .map((field) => ({
        fieldId: field.id,
        isCarryover: true,
      }));
    setStages(updatedStages);
  };

  const removeStage = (index: number) => {
    const updatedStages = stages.filter((_, i) => i !== index);
    setStages(updatedStages);
  };

  const saveStages = () => {
    onSaveStages(stages);
    message.success('Stages saved successfully!');
  };

  const columns = [
    {
      title: 'Stage Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Stage, index: number) => (
        <Input
          value={text}
          onChange={(e) => updateStage(index, 'name', e.target.value)}
          placeholder="Enter Stage Name"
        />
      ),
    },
    {
      title: 'Carryover Fields',
      key: 'carryover',
      render: (_: any, record: Stage, index: number) => (
        <Checkbox.Group
          options={allFields
            .filter((field) => field.id && field.name) // Ensure valid fields
            .map((field) => ({ label: field.name, value: field.id }))}
          value={record.fields
            ? record.fields.map((f) => f.fieldId)
            : []} // Use all fieldIds in the stage
          onChange={(selectedFieldIds) => handleFieldCarryoverChange(index, selectedFieldIds as string[])}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, __: any, index: number) => (
        <Button type="link" onClick={() => removeStage(index)}>Delete</Button>
      ),
    },
  ];

  return (
    <div>
      <Input
        placeholder="New Stage Name"
        value={newStageName}
        onChange={(e) => setNewStageName(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Button onClick={addStage} style={{ marginBottom: '20px' }}>
        Add Stage
      </Button>

      <Table dataSource={stages} columns={columns} rowKey="id" pagination={false} />

      <Button type="primary" onClick={saveStages} style={{ marginTop: '20px' }}>
        Save Stages
      </Button>
    </div>
  );
};

export default StageBuilder;