import React, { useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CredentialResponse } from '@react-oauth/google';
import { useUser } from '../contexts/UserContext'; 
import  '../styles/loginPage.css'

const API_URL = process.env.REACT_APP_API_URL;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login: loginUser } = useUser();

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        const res = await fetch(`${API_URL}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });
        const data = await res.json();
        if (res.ok) {
          localStorage.setItem('token', data.access_token.access_token);
          loginUser(data.access_token.access_token); // Update the user context
          navigate('/quickbuild-home');
        } else {
          setError(data.message || 'Invalid email or password');
        }
      } catch (err) {
        console.error('Login error:', err);
        setError('Something went wrong');
      }
    };
  

    const handleGoogleSuccess = async (credentialResponse: CredentialResponse) => {
      try {
        const token = credentialResponse.credential;
        if (token) {
          const res = await fetch(`${API_URL}/auth/google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token }),
          });
          const data = await res.json();
          if (res.ok) {
            localStorage.setItem('token', data.access_token); // Save JWT token
            loginUser(data.access_token); // Update the user context
            navigate('/quickbuild-home'); // Redirect to Quickbuild home
          } else {
            setError(data.message || 'Google authentication failed');
          }
        }
      } catch (err) {
        console.error('Google login error:', err);
        setError('Google authentication failed');
      }
    };

    const handleGoogleFailure = () => {
      setError('Google authentication was unsuccessful. Try again later.');
    };

  return (
    <div className='login-container'> 
      <img
        src={require('../images/quickbuild-main.png')}
        alt="QuickBuild Logo"
        className="login-logo"
      />
      <h1 className='login-title'>Login to QuickBuild</h1>

      {/* Email and Password Form */}
      <form onSubmit={handleSubmit} className='login-form'>
        <div className='form-group'>
          <label>Email</label>
          <input
            type="email"
            value={email}
            className='form-input'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <label>Password</label>
          <input
            type="password"
            value={password}
            className="form-input"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        
        <button type="submit" className='login-button'> 
           {/* style={{
          backgroundColor: '#007BFF',
          color: '#fff',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}> */}
          Login
        </button>
              {/* Display error message if there is an error */}
      {error && (
        <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
      )}
      </form>

      {/* Add this below your form */}
      <p>
        Don't have an account?{' '}
        <Link to="/signup" style={{ color: '#007BFF' }}>
          Sign Up
        </Link>
      </p>

          {/* OR Sign in/up with Google */}
    <p>OR</p>
    <GoogleOAuthProvider clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
      />
    </GoogleOAuthProvider>
  </div>
  );
};

export default LoginPage;