import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Select, Modal, Table, notification } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import FieldPalette from '../Fields/_FieldPalette';
import PageOrganizer from '../PageOrganizer/PageOrganizer';
import ProcessBuilder from '../Process/ProcessBuilder';
import { useTenant } from '../../../contexts/TenantContext';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext'
// import OneToManyField from '../Fields/OneToManyField';
import '../../../styles/refPage.css'

const { Option } = Select;

interface ReferencePageBuilderProps {
  module?: any;  // Optional module prop
}

const ReferencePageBuilder: React.FC<ReferencePageBuilderProps> = () => {
  const location = useLocation();
  const [module, setModule] = useState(location.state?.module || null);  // Store module in local state
  const [sections, setSections] = useState([{ id: `section-${Date.now()}`, title: 'Section 1', type: 'section', fields: [] }]);
  const [selectedSection, setSelectedSection] = useState(sections[0]?.id || '');
  const [functions, setFunctions] = useState([]);
  const [pageTitle, setPageTitle] = useState('New Page');
  const [moduleName, setModuleName] = useState(pageTitle);
  const [version, setVersion] = useState('1.0.0');
  const [status, setStatus] = useState('draft');
  const [isProcessModalVisible, setIsProcessModalVisible] = useState(false);
  const { tenantId, companyId } = useTenant();
  // const [processFlow, setProcessFlow] = useState<any[]>([]); // State to store the process flow
  const [functionToEdit, setFunctionToEdit] = useState(null);  // Track which function is being edited
  const API_URL = process.env.REACT_APP_API_URL;
  const { user } = useUser();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);


  // const canViewFunctions = true
  const userPermissions = (() => {
    if (user?.roles.includes('quickbuildadmin')) {
      return { canViewFunctions: true };
    }
    return { canViewFunctions: false };
  })();


  const initialSectionsRef = useRef([]);


  useEffect(() => {
    if (module) {
      setPageTitle(module.moduleName || 'Editable Page Title');
      setModuleName(module.moduleName || 'Module Name');
      setFunctions(module.functions || []);
      setVersion(module.version || '1.0.0');
      setStatus(module.status || 'draft');
  
      // Map the OneToManyFields into the sections
      const updatedSections = (module.sections || []).map(section => {
        const updatedFields = section.fields.map(field => {
          if (field.type === 'OneToManyField') {
            // Find the matching OneToManyField by ID
            const matchingOneToManyField = (module.oneToManyFields || []).find(
              oneToManyField => oneToManyField.id === field.id
            );
            
            // If found, assign the subFields to the OneToManyField
            if (matchingOneToManyField) {
              return {
                ...field,
                subFields: matchingOneToManyField.subFields || [] // Initialize subFields if found
              };
            }
          }
          return field; // For other field types, just return as is
        });
  
        return {
          ...section,
          fields: updatedFields
        };
      });
  
      setSections(updatedSections); // Finally set the updated sections
      setSelectedSection(updatedSections[0]?.id || '');
      initialSectionsRef.current = updatedSections;
      setHasUnsavedChanges(false)
    }
  }, [module]);

   // Function to check for changes
   const hasChanges = () => {
    // Compare moduleName
    // if (moduleName !== initialModuleNameRef.current) return true;

    // // Compare version
    // if (version !== initialVersionRef.current) return true;

    // // Compare status
    // if (status !== initialStatusRef.current) return true;

    // // Compare functions
    // if (functions.length !== initialFunctionsRef.current.length) return true;
    // for (let i = 0; i < functions.length; i++) {
    //   if (JSON.stringify(functions[i]) !== JSON.stringify(initialFunctionsRef.current[i])) return true;
    // }

    // Compare sections
    if (sections.length !== initialSectionsRef.current.length) return true;
    for (let i = 0; i < sections.length; i++) {
      const currentSection = sections[i];
      const initialSection = initialSectionsRef.current[i];

      if (
        currentSection.id !== initialSection.id ||
        currentSection.title !== initialSection.title ||
        currentSection.type !== initialSection.type ||
        currentSection.fields.length !== initialSection.fields.length
      ) {
        return true;
      }

      for (let j = 0; j < currentSection.fields.length; j++) {
        if (
          JSON.stringify(currentSection.fields[j]) !==
          JSON.stringify(initialSection.fields[j])
        ) {
          return true;
        }
      }
    }

    return false;
  };
  
  // console.log(functions)
  const onAddField = (fieldType, sectionId) => {
    // console.log(`Adding field type: ${fieldType} to section: ${sectionId}`);
    const updatedSections = sections.map(section => {
      // console.log('section.id:',  section.id)
      if (section.id === sectionId) {
        // console.log('section matches')
        // Define newField as a base field
        let newField: { id: string; type: string; name: string; subFields?: any[] } = { 
          id: `field-${Date.now()}`, 
          type: fieldType, 
          name: `${fieldType}_${Date.now()}` 
        };

        // console.log('new: ', newField)
  
        // Add subFields if it's a OneToManyField
        if (fieldType === 'OneToManyField') {
          newField = { ...newField, subFields: [] }; // Initialize sub-fields array
          // console.log('new field in OTM: ', newField)
        }
  
        return { ...section, fields: [...section.fields, newField] };
      }
      return section;
    });

    // console.log('Updated sections:', updatedSections);
    setSections(updatedSections);
  };

  const onFieldUpdate = (sectionId, fieldId, updatedField) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        if (fieldId === 'all') {
          // Replace the entire fields array
          return { ...section, fields: updatedField };
        } else {
          // Update a single field
          const updatedFields = section.fields.map((field) =>
            field.id === fieldId ? { ...field, ...updatedField } : field
          );
          return { ...section, fields: updatedFields };
        }
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldDelete = (sectionId, fieldId) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter(field => field.id !== fieldId);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onSaveFunction = (newFunction) => {
    setFunctions((prevFunctions) => [...prevFunctions, newFunction]);  // Append the new function to the array
    setIsProcessModalVisible(false);  // Close the modal
  };

  const handleDeleteFunction = (index) => {
    const updatedFunctions = functions.filter((_, funcIndex) => funcIndex !== index);
    setFunctions(updatedFunctions); // Update the state after deleting the function
  };

  const handleEditFunction = (index) => {
    const funcToEdit = functions[index];
    setFunctionToEdit(funcToEdit);  // Set the function to edit
    setIsProcessModalVisible(true); // Open the modal
  };

  const processOneToManyFields = (sections) => {
    return sections.flatMap((section) =>
      section.fields
        .filter((field) => field.type === 'OneToManyField')
        .map((field) => ({
          id: field.id,
          name: field.name,
          subFields: field.subFields,
        }))
    );
  };

  const processFunctions = (functions) => {
    return functions.map((func) => ({
      functionId: func.functionId,
      name: func.name,
      steps: func.steps.map((step) => ({
        stepId: step.stepId,
        type: step.type,
        typeDetails: step.typeDetails,
      })),
    }));
  };

  const processAutoIncrementFields = async (sections, tenantId, companyId, module) => {
    const autoIncrementFields = [];
    sections.forEach((section) => {
      if (section.type === 'section') { // Only FormSection has 'fields'
        section.fields.forEach((field) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      }
    });
  
    if (autoIncrementFields.length > 0) {
      for (const field of autoIncrementFields) {
        const { start, end } = field.fieldOptions || {};
        const match = start.match(/^([A-Za-z]*)(\d+)$/);
        const prefix = match ? match[1] : '';
  
        try {
          const response = await fetch(`${API_URL}/auto-increment/update-auto-id`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              tenantID: tenantId,
              companyID: companyId,
              moduleID: module?._id || 'new-module',
              fieldId: field.id,
              start,
              end,
              prefix,
            }),
          });
  
          if (response.ok) {
            console.log('')
          } else {
            console.error('Failed to update AutoIncrementId');
          }
        } catch (error) {
          console.error('Error updating AutoIncrementId:', error);
        }
      }
    }
  };

  const saveSchema = async () => {
    // Prepare the schema
    const schema = {
      pageTitle: moduleName,
      moduleName,
      version,
      status,
      sections,
      tenantId,
      companyId,
      pageType: 'Reference',
      oneToManyFields: processOneToManyFields(sections),
      functions: processFunctions(functions),
    };

    // console.log('schema: ', schema)
    // console.log('json schema: ', JSON.stringify(schema))
  
    // Handle AutoIncrement fields (if applicable)
    await processAutoIncrementFields(sections, tenantId, companyId, module);
  
    try {
      const url = module?._id
        ? `${API_URL}/quickbuild-prod/page-modules/${module._id}`
        : `${API_URL}/quickbuild-prod/page-modules/save`;
  
      const method = module?._id ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(schema),
      });
      // console.log('response: ', response)

      const result = await response.json();
      if (response.ok) {
        // console.log('Schema saved successfully!', result);
        const fetchUrl = `${API_URL}/quickbuild-prod/page-modules/${result._id || module?._id}`;
        const fetchResponse = await fetch(fetchUrl);
        const updatedModule = await fetchResponse.json();
        setModule(updatedModule)
        notification.success({
          message: 'Schema saved',
          description: 'Click the "Builder" link to view in library',
          placement: 'top',
          duration: 3
        })
        setHasUnsavedChanges(false)

      } else {
        console.error('Failed to save schema:', result);
      }
    } catch (error) {
      console.error('Error saving schema:', error);
    }
  };

  let availableFields = ['TextField', 'ParagraphTextField', 'AutoIncrementId', 'PhoneNumber', 
    'EmailField', 'Dropdown', 'NumberField', 'ReferenceLinkField'];

  if (userPermissions.canViewFunctions) {
    availableFields.push('AggregateField', 'OneToManyField')
  }

  const handleSectionSelect = (sectionId) => {
    setSelectedSection(sectionId);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
  
    // If no destination, return
    if (!destination) return;
  
    // If source and destination are the same, return
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
  
    // Find source and destination sections
    const sourceSectionIndex = sections.findIndex(section => section.id === source.droppableId);
    const destinationSectionIndex = sections.findIndex(section => section.id === destination.droppableId);
  
    if (sourceSectionIndex === -1 || destinationSectionIndex === -1) return;
  
    const sourceSection = sections[sourceSectionIndex];
    const destinationSection = sections[destinationSectionIndex];
  
    const sourceFields = Array.from(sourceSection.fields);
    const [movedField] = sourceFields.splice(source.index, 1);  // Remove field from source
  
    // If dragging within the same section
    if (sourceSection === destinationSection) {
      sourceFields.splice(destination.index, 0, movedField);  // Re-insert the field into the correct position
      const updatedSection = { ...sourceSection, fields: sourceFields };
      const updatedSections = [...sections];
      updatedSections[sourceSectionIndex] = updatedSection;
  
      setSections(updatedSections);
    } else {
      // If dragging between different sections
      const destinationFields = Array.from(destinationSection.fields);
      destinationFields.splice(destination.index, 0, movedField);  // Add field to destination
  
      const updatedSections = [...sections];
      updatedSections[sourceSectionIndex] = { ...sourceSection, fields: sourceFields };
      updatedSections[destinationSectionIndex] = { ...destinationSection, fields: destinationFields };
  
      setSections(updatedSections);
    }
  };

  const addSection = () => {
    const newSection = {
      id: `section-${Date.now()}`,
      title: `Section ${sections.length + 1}`,
      type: 'section',
      fields: [],
    };
    setSections([...sections, newSection]);
    setSelectedSection(newSection.id); // Automatically select the new section
  };

  const functionColumns = [
    {
      title: 'Function ID',
      dataIndex: 'functionId',  // Display the functionId
      key: 'functionId',
    },
    {
      title: 'Function Name',
      dataIndex: 'name',  // Display the function name
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => handleEditFunction(index)}>Edit</Button>
          <Button type="link" onClick={() => handleDeleteFunction(index)}>Delete</Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (hasChanges()) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [sections, functions, moduleName, version, status]);


  return (
    <div>
      <h1>Reference Page Builder</h1>

      {hasUnsavedChanges && (
      <div className='unsaved-changes-banner'>
        <strong>Unsaved Changes:</strong> Don't forget to click "Save Schema" or your changes will be lost
      </div>
      )}

      <Input
        value={pageTitle}
        onChange={(e) => {
          setPageTitle(e.target.value);
          setModuleName(e.target.value); // Keep moduleName in sync with pageTitle
        }}
        placeholder="Editable Page Title"
        style={{ marginBottom: '10px', width: '50%' }}
      />

      <Select 
        value={selectedSection} 
        onChange={handleSectionSelect} 
        className={`section-selector ${sections.length > 1 ? 'multiple-sections' : ''}`}
        style={{ marginBottom: '20px', width: '200px' }}>
          {sections.map(section => (
            <Option key={section.id} value={section.id}>
              {section.title}
            </Option>
          ))}
      </Select>

          {/* Add Section Button */}
    <Button type="primary" onClick={addSection} style={{ marginBottom: '10px' }}>
      Add Section
    </Button>

      <FieldPalette
          onAddField={fieldType => onAddField(fieldType, selectedSection)}
          availableFields={availableFields}
        />

      <DragDropContext onDragEnd={onDragEnd}>
        <PageOrganizer
          sections={sections}
          setSections={setSections}
          onFieldUpdate={onFieldUpdate}
          onFieldDelete={onFieldDelete}
          pageType={'Reference'}
          // onRenameSection={onRenameSection}
        />
      </DragDropContext>

      <Button type="primary" onClick={saveSchema} style={{ marginTop: '10px' }}>
        Save Schema
      </Button>

      {userPermissions.canViewFunctions && (
        <div>
        <Button onClick={() => setIsProcessModalVisible(true)} style={{ marginLeft: '10px' }}>
          Add Function
        </Button>
        
        <h3>Functions</h3>
        <Table dataSource={functions} columns={functionColumns} rowKey="id" />

        {/* Modal for adding new functions using ProcessBuilder */}
        <Modal
          title="Process Builder"
          open={isProcessModalVisible}
          onCancel={() => setIsProcessModalVisible(false)}
          footer={null}  // Custom footer is not needed because ProcessBuilder will handle saving
        >
        <ProcessBuilder
          existingFunction={functionToEdit}  // Pass the existing functions
          onSaveFunction={(newFunction) => {
            onSaveFunction(newFunction);  // Save the function
            setIsProcessModalVisible(false);  // Close the modal
          }}
        />
        </Modal>
        </div>
      )}


    </div>
  );
};

export default ReferencePageBuilder;