import React, { useState } from 'react';
import { Input, Button, Row, Col, Checkbox } from 'antd';
// import { Droppable, Draggable } from 'react-beautiful-dnd';
import FieldModals from '../Fields/_FieldModals';
// import { useTenant } from '../../../contexts/TenantContext';
import '../../../styles/sectionOrganizer.css'
import {reorder} from '../../../utils/reorder'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const SectionOrganizer = ({ section, onFieldUpdate, onFieldDelete, onRenameSection, pageType }) => {
  // const { tenantId, companyId } = useTenant();
  const [selectedField, setSelectedField] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [sectionTitle, setSectionTitle] = useState(section.title);

  const handleTitleChange = (e) => {
    setSectionTitle(e.target.value);
  };

  const saveTitleChange = () => {
    onRenameSection(section.id, sectionTitle);
    setIsEditingTitle(false);
  };

  const handleFieldClick = (field) => {
    setSelectedField(field);
    setIsModalVisible(true);
  };

  const handleFieldOptionChange = (field, fieldOptionKey, value) => {
    const updatedField = {
      ...field,
      fieldOptions: { ...(field.fieldOptions || {}), [fieldOptionKey]: value },
    };
  
    // Update the field in the sections
    onFieldUpdate(section.id, updatedField.id, updatedField);
  
    // Update selectedField if necessary
    if (selectedField && selectedField.id === field.id) {
      setSelectedField(updatedField);
    }
  };

  const handleRequiredChange = (field, isChecked) => {
    handleFieldOptionChange(field, 'required', isChecked);
  };

  const requiredFieldTypes = ['TextField', 'Dropdown', 'NumberField', 'ReferenceLinkField', 'PhoneNumber', 'EmailField']

  // Reordering functions
  const moveFieldUp = (index) => {
    if (index === 0) return; // Already at the top
    const newFields = reorder(section.fields, index, index - 1);
    onFieldUpdate(section.id, 'all', newFields); // 'all' indicates updating the entire fields array
  };

  const moveFieldDown = (index) => {
    if (index === section.fields.length - 1) return; // Already at the bottom
    const newFields = reorder(section.fields, index, index + 1);
    onFieldUpdate(section.id, 'all', newFields); // 'all' indicates updating the entire fields array
  };

  return (
    <div className='section-container'>
      {/* <Droppable droppableId={section.id} type="FIELDS"> */}
        {/* {(provided, snapshot) => ( */}
          <div
            // ref={provided.innerRef}
            // {...provided.droppableProps}
            style={{
              padding: '10px',
              marginBottom: '10px',
              minHeight: '100px',
            }}
          >
            {/* Section Title: Editable */}
            {isEditingTitle ? (
              <Input
                value={sectionTitle}
                onChange={handleTitleChange}
                onBlur={saveTitleChange}
                onPressEnter={saveTitleChange}
                autoFocus
                className='section-title'
              />
            ) : (
              <h3
                onClick={() => setIsEditingTitle(true)}
                style={{ cursor: 'pointer' }}
              >
                {sectionTitle}
              </h3>
            )}
            {/* Render Fields */}
            <Row gutter={[16, 16]} >
              {section.fields.map((field, index) => (
                    <Col
                      key={field.id}
                      span={10}
                      style={{
                        border: '1px solid gray',
                        borderRadius: '8px',
                        padding: '10px',
                        marginRight: '50px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleFieldClick(field)} // Make field clickable
                    >
                      <div className='required-checkbox'>{field.name || field.type}</div>
                      {pageType === 'Reference' && requiredFieldTypes.includes(field.type) && (
                        <Checkbox
                          checked={field.fieldOptions?.required || false}
                          onClick={(e) => e.stopPropagation()} // Prevent the modal from opening
                          onChange={(e) => handleRequiredChange(field, e.target.checked)}
                        >
                          Required
                        </Checkbox>
                      )}
                      <Button
                        type="text"
                        className='field-delete-btn'
                        onClick={(e) => {
                          e.stopPropagation();
                          onFieldDelete(section.id, field.id);
                        }}
                      >
                        Delete
                      </Button>
                      <div className="move-buttons" style={{ position: 'absolute', top: '5px', right: '5px' }}>
                      {/* Move Buttons */}
                      <Button
                        type="text"
                        icon={<ArrowUpOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          moveFieldUp(index);
                        }}
                        disabled={index === 0}
                        style={{ marginRight: '5px' }}
                      />
                      <Button
                        type="text"
                        icon={<ArrowDownOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          moveFieldDown(index);
                        }}
                        disabled={index === section.fields.length - 1}
                      />
                    </div>
                    </Col>
              ))}
            </Row>
          </div>

      <FieldModals
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={(updatedField) => {
          setIsModalVisible(false);
          setSelectedField(null);
          onFieldUpdate(section.id, updatedField.id, updatedField);
        }}
        currentField={selectedField}
        handleFieldOptionChange={(fieldOptionKey, value) =>
          handleFieldOptionChange(selectedField, fieldOptionKey, value)
        }
        />
    </div>
  );
};

export default SectionOrganizer;