import React from 'react';
import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';

const DateFieldRender = ({ field, form, handleFieldChange, value, renderType, required }) => {
  const dateFormat = field.fieldOptions?.dateFormat || 'YYYY-MM-DD';
  // console.log('format: ', dateFormat)
  // console.log('input: ', field, handleFieldChange, value, renderType)

  // Ensure the value is either a valid dayjs object or null
  const dateValue = value
  ? dayjs.isDayjs(value)
    ? value
      : dayjs(value, dateFormat).isValid()
    ? dayjs(value, dateFormat)
      : null
  : null;

  // console.log('date val: ', dateValue)

  const datePickerComponent = (
    <DatePicker
      style={{ width: '100%' }}
      onChange={(date) => handleFieldChange(date ? date.format(dateFormat) : null)}
    //   onChange={(date) => handleFieldChange(date ? date.toISOString() : null)}
      value={dateValue || null}
      format={dateFormat}
      required={required}
    />
  );

  if (renderType === 'table') {
    // For table render, pass value as dayjs or null for DatePicker
    return (
      <DatePicker
        style={{ width: '100%' }}
        onChange={(date) => handleFieldChange(date ? date.format(dateFormat) : null)}
        value={dateValue || null} // Explicitly pass null for empty value
        format={dateFormat}
        required={required}
      />
    );
  }

  return (
    <Form.Item name={field.name} label={field.name}>
      {datePickerComponent}
    </Form.Item>
  );
};

export default DateFieldRender;