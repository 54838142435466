import React, { useEffect, useState } from 'react';
import { List, Typography } from 'antd'; // Import Ant Design components
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;

const API_URL = process.env.REACT_APP_API_URL;

const SideMenuRender = ({ tenantId, companyId }) => {
  const [publishedModules, setPublishedModules] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPublishedModules = async () => {
      if (!tenantId || !companyId) {
        return;
      }

      try {
        const response = await fetch(`${API_URL}/quickbuild-prod/page-modules/published?tenantId=${tenantId}&companyId=${companyId}`);
        const data = await response.json();
        if (Array.isArray(data)) {
          setPublishedModules(data); // Ensure it only sets if data is an array
        } else {
          setPublishedModules([]); // Handle unexpected data format
        }
      } catch (error) {
        console.error('Error fetching published modules:', error);
        setPublishedModules([]); // Fallback to an empty array on error
      }
    };

    fetchPublishedModules();
  }, [tenantId, companyId]); // Re-fetch whenever tenantId or companyId changes

  // Handle clicking a module to navigate to its URL based on type
  const handleModuleClick = (module) => {
      navigate(`/module/${module._id}/${module.pageType.toLowerCase()}`);  // Pass the moduleType in the URL
      };
  

  return (
      <div>
        <h2 style={{ color: 'white', paddingLeft: '16px' }}>Pages</h2>
        {publishedModules.length === 0 ? (
          <p style={{ color: 'white', paddingLeft: '16px' }}>No published modules available.</p>
        ) : (
          <List
            dataSource={publishedModules}
            renderItem={(module) => (
              <List.Item
                style={{ paddingLeft: '16px', cursor: 'pointer' }}
                onClick={() => handleModuleClick(module)}
              >
                <Text style={{ color: 'white' }}>{module.moduleName}</Text>
              </List.Item>
            )}
          />
        )}
      </div>
    );
  };

export default SideMenuRender;